import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import { FaTimes, FaSave } from "react-icons/fa";

export interface ConfirmationOptions {
    title: string;
    description?: string;
    description2?: string;
    description3?: string;
    okButtonText: string;
    cancelButtonText: string;
    content?: any
}

interface ConfirmationDialogProps extends ConfirmationOptions {
    open: boolean;
    onOk?: (payload: any) => void;
    onCancel: () => void;
    payload?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = props => {
    const {
        title,
        description,
        description2,
        description3,
        okButtonText,
        cancelButtonText,
        open,
        onCancel,
        onOk,
        payload,
        content
    } = props

    const handleCancel = (event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        onCancel();
    }

    return (
        <Dialog open={open} onClose={handleCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {description && <DialogContentText>{description}</DialogContentText>}
                {description2 && <DialogContentText>{description2}</DialogContentText>}
                {description3 && < DialogContentText > {description3}</DialogContentText>}
                {content && <>{content}</>}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaTimes />
                    </span>
                    <span className="btn-wrapper--label">{cancelButtonText}</span>
                </Button>

                {onOk && <Button onClick={() => onOk(payload)} variant="contained" className="btn-primary p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                    <span className="btn-wrapper--label">{okButtonText}</span>
                </Button>}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog