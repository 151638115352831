import React, { CSSProperties, MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import {
    LoadingButton as MaterialUIButton,
    LoadingButtonProps as MaterialUIButtonProps
} from '@mui/lab';
import { Tooltip } from '@mui/material';

type LoadingButtonStyle = MaterialUIButtonProps['style'];

type LoadingButtonProps = {
    onClick?: MouseEventHandler<HTMLButtonElement>;
    type?: 'primary' | 'secondary' | 'danger' | 'success' | 'default';
    label?: string;
    style?: LoadingButtonStyle;
    labelStyle?: CSSProperties;
    width?: number;
    height?: number;
    disabled?: boolean;
    fullWidth?: boolean;
    fullHeight?: boolean;
    loading?: boolean;
    icon?: React.ReactNode;
    disabledToolTip?: string;
    tooltip?: string;
}

function LoadingButton({
    onClick,
    type = 'primary',
    label,
    width,
    height,
    style,
    labelStyle,
    disabled,
    loading,
    fullWidth = true,
    fullHeight = true,
    icon,
    disabledToolTip,
    tooltip,
}: LoadingButtonProps) {
    const { settings } = useSelector((state: any) => state.settings);

    return <Tooltip title={disabled ? disabledToolTip : tooltip} arrow placement="top">
        <MaterialUIButton
            disabled={disabled}
            loading={loading}
            onClick={onClick}
            variant="contained"
            className={type === 'default' ? `btn-white p-3 text-capitalize bordered` : `btn-${type} p-3 text-white text-capitalize`}
            style={{
                ...(type === 'default' ? { border: `1px ${settings?.Styles?.Primary} solid` } : {}),
                ...(type === 'secondary' ? { background: loading ? '#fff' : '#2B2B2B' } : {}),
                width: width ?? (fullWidth ? '100%' : undefined),
                height: height ?? (fullHeight ? '100%' : undefined),
                ...(style ?? {}),
                pointerEvents: 'all',
            }}
        >
            {icon}
            {label && <span style={labelStyle} className="btn-wrapper--label">{label}</span>}
        </MaterialUIButton>
    </Tooltip>
}

export default LoadingButton;