import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { handleSubmit, registerResetState } from "./store/RegisterCard";
import useStyles from "./RegisterCardStyles";
import ns from '@/helpers/NotificationService';
import {
    Button,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    List,
    ListItem,
    Alert
} from "@mui/material";
import { emailRegex, phoneRegex } from "@/helpers/Regex";
import PrivacyPolicyModal from "@/components/Modal/PrivacyPolicyModal";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { isValidEmail, isValidPhoneNumber } from "../../helpers/Utils";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { passwordStrongRegex, passwordValidationError } from "@/helpers/Regex"
import { handleIndividualProfileCreate, clearApiResponses } from '../../pages/BiddingProfile/store/IndividualProfile';
import PasswordChecklist from "react-password-checklist"
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import useHistory from "../../helpers/hooks/useHistory";

interface Props {
    handleIndividualProfileCreate_d: (formFields: any) => void,
    handleSubmit_d: (email: string, phoneNumber: string, logoUrl: string, password: string, firstName: string, lastName: string) => any,
    registerResetState_d: () => void,
    clearApiResponses_d: () => void,
    success: boolean,
    error: boolean,
    errorText: string,
    individualProfileSuccess: boolean,
    individualProfileError: boolean,
    individualProfileErrorText: string,
    settings: any,
    loading: boolean,
    isLogin: (isLogin: boolean) => void;
    identityUserId: any
}

const RegisterCard: React.FC<Props> = (props) => {
    const {
        handleIndividualProfileCreate_d,
        handleSubmit_d,
        registerResetState_d,
        clearApiResponses_d,
        error,
        errorText,
        success,
        individualProfileSuccess,
        settings,
        loading,
        isLogin,
        identityUserId
    } = props;

    const history = useHistory();

    const intialValuesErrors = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        tandcRead: "",
    };

    const intialValues = {
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        confirmEmail: "",
        password: "",
        confirmPassword: "",
        tandcRead: false,
        address: {
            addressId: '',
            addressLine1: '',
            addressLine2: '',
            country: '',
            state: '',
            city: '',
            zip: '',
            longitude: 0,
            latitude: 0
        },
        identityUserId: ''
    };

    const { classes } = useStyles();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [formFields, setFormFields] = useState(intialValues);
    const [formErrors, setFormErrors] = useState(intialValuesErrors);
    const [imgSrc, setImgSrc] = React.useState<any>(undefined);
    const [privacyPolicyModal, setPrivacyPolicyModal] = useState(false);

    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
    const handleToggleConfirmPassword = () => setShowConfirmPassword(showConfirmPassword => !showConfirmPassword);
    const handleLoginClick = () => {
        isLogin(true);
    };

    const handleChange = (e: { target: { id: any; value: string } }) => {
        setFormFields({ ...formFields, [e.target.id]: e.target.value });
    };

    const onTermsSelect = (e: any) => {
        setFormFields({ ...formFields, ["tandcRead"]: e.target.checked });
    };

    const onSubmit = () => {
        const onSubmitErrors = validate(formFields);
        setFormErrors(onSubmitErrors);

        if (
            onSubmitErrors.email.length === 0 &&
            onSubmitErrors.confirmEmail.length === 0 &&
            onSubmitErrors.phoneNumber.length === 0 &&
            onSubmitErrors.password.length === 0 &&
            onSubmitErrors.confirmPassword.length === 0 &&
            onSubmitErrors.firstName.length === 0 &&
            onSubmitErrors.lastName.length === 0
        ) {
            showProgressSpinner({ description: "Registering Account..." });
            handleSubmit_d(
                formFields.email,
                formFields.phoneNumber,
                settings.VendorLogoUrl,
                formFields.password,
                formFields.firstName,
                formFields.lastName
            );
        }
    };

    React.useEffect(() => {
        setImgSrc(settings.VendorLogoUrl);
    }, [settings]);

    useEffect(() => {
        if (success) {
            showProgressSpinner({ description: "Creating Profile..." });
            let newFormFields = { ...formFields, identityUserId }
            setFormFields(newFormFields);
            handleIndividualProfileCreate_d(newFormFields);
        }
    }, [success]);

    useEffect(() => {
        hideProgressSpinner();

        if (individualProfileSuccess) {
            clearApiResponses_d();
            redirect();
        }

        registerResetState_d();
    }, [individualProfileSuccess]);

    useEffect(() => {
        hideProgressSpinner();

        if (error) {
            ns.error('', errorText);
        }
        registerResetState_d();
    }, [error]);

    const isPasswordValid = (password: any, passwordConfirmation: any) => {
        if (!password || !passwordConfirmation) return false;
        return password === passwordConfirmation;
    };

    const passRegexValidation = (value: any) => {
        const re = passwordStrongRegex;
        return re.test(value);
    };

    const isEmailValid = (email: any, emailConfirmation: any) => {
        if (!email || !emailConfirmation) return false;
        return email === emailConfirmation;
    };

    const validate = (values) => {
        let errors = {
            firstName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            confirmEmail: "",
            password: [] as any,
            confirmPassword: "",
            tandcRead: "",
        };

        if (!values.email) {
            errors.email = "Email field cannot be blank";
        } else if (!isValidEmail(values.email.replace(" ", ""))) {
            errors.email = "Please enter a valid email address.";
        } else {
            if (!isEmailValid(values.email.replace(" ", ""), values.confirmEmail.replace(" ", ""))) {
                errors.confirmEmail = "Emails do not match";
            }
        }

        if (!values.firstName) {
            errors.firstName = "Name field cannot be blank";
        }

        if (!values.lastName) {
            errors.lastName = "Last Name field cannot be blank";
        }

        if (!values.phoneNumber) {
            errors.phoneNumber = "Phone Number field cannot be blank";
        } else if (!isValidPhoneNumber(values.phoneNumber)) {
            errors.phoneNumber = "Please enter a valid phone number.";
        }

        errors.password = [];
        if (!values.password) {
            errors.password.push("Password field cannot be blank");
        } else if (!passRegexValidation(values.password)) {
            passwordValidationError.forEach((item) => {
                errors.password.push(item);
            });
        } else {
            if (!isPasswordValid(values.password, values.confirmPassword)) {
                errors.confirmPassword = "Passwords do not match";
            }
        }

        if (!values.tandcRead) {
            errors.tandcRead =
                "Terms and conditions must be accepted in order to continue";
        }

        return errors;
    };

    const redirect = () => {
        let path = "/registerConfirmation";
        history.push(path);
    };

    const navigateBack = () => {
        let path = "/home";
        history.push(path);
    };

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            handlePrivacyPolicyModalOpen();
        }
    };

    const handlesChange = (e: any) => {
        const props = e.target.id.split(".");

        if (props[0] === "phoneNumber") {
            if (/[a-z]/.test(e.target.value)) {
                e.preventDefault();
            } else {
                setFormFields({ ...formFields, [props[0]]: e.target.value });
            }
        } else {
            setFormFields({ ...formFields, [props[0]]: e.target.value });
        }
    };

    const handlePrivacyPolicyModalOpen = () => {
        const onSubmitErrors = validate(formFields);
        setFormErrors(onSubmitErrors);

        if (
            onSubmitErrors.email.length === 0 &&
            onSubmitErrors.confirmEmail.length === 0 &&
            onSubmitErrors.phoneNumber.length === 0 &&
            onSubmitErrors.password.length === 0 &&
            onSubmitErrors.confirmPassword.length === 0 &&
            onSubmitErrors.firstName.length === 0 &&
            onSubmitErrors.lastName.length === 0
        ) {
            setPrivacyPolicyModal(!privacyPolicyModal);
        }

    };

    const ChromeInstallationOnClick = () => {
        window.open('https://www.google.com/chrome/?brand=WHAR&gclid=EAIaIQobChMI766o_7y99QIV7oBQBh11nQYnEAAYASAAEgLscPD_BwE&gclsrc=aw.ds');
    }

    const EdgeInstallationOnClick = () => {
        window.open('https://www.microsoft.com/en-us/edge');
    }

    const FireFoxInstallationOnClick = () => {
        window.open('https://www.mozilla.org/en-US/firefox/new/');
    }

    return (<>
        {!loading && <>
            <PrivacyPolicyModal
                onSubmit={() => onSubmit()}
                isDialogOpened={privacyPolicyModal}
                handleCloseDialog={() => setPrivacyPolicyModal(false)}
                isViewOnlyMode={false}
            />

            <Grid container spacing={2} direction="row" justifyContent="flex-start">
                <Grid item md={4} className="bg-primary p-4">
                    <h4 className=" text-white text-center" style={{ paddingTop: '220px' }}>Already have an Account?</h4>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            variant="contained" className="btn-primary p-3 text-white text-capitalize border-top border-left border-right border-bottom"
                            type="submit"
                            onClick={handleLoginClick}>
                            Log In
                        </Button>
                    </div>
                </Grid>
                <Grid item md={8} className="p-4">
                    <div className={classes.paper} style={{ marginLeft: "29" }}>
                        <img className={classes.imgLogo + ' mb-4 center'} src={imgSrc} />
                        <h4 className=" text-black text-center">New User? Sign Up</h4>

                        <TextField
                            onKeyPress={onKeyPress}
                            margin="normal"
                            id="firstName"
                            label="Name"
                            value={formFields.firstName}
                            onChange={handlesChange}
                            autoFocus={true}
                            type="text"
                        />
                        {formErrors.firstName && (
                            <Alert severity="error">{formErrors.firstName}</Alert>
                        )}

                        <TextField
                            onKeyPress={onKeyPress}
                            margin="normal"
                            id="lastName"
                            label="Last Name"
                            value={formFields.lastName}
                            onChange={handlesChange}
                            type="text"
                        />
                        {formErrors.lastName && (
                            <Alert severity="error">{formErrors.lastName}</Alert>
                        )}

                        <TextField
                            onKeyPress={onKeyPress}
                            margin="normal"
                            id="phoneNumber"
                            label="Phone Number"
                            value={formFields.phoneNumber}
                            onChange={handlesChange}
                            onKeyDown={(e) =>
                                (e.keyCode === 69 ||
                                    e.keyCode === 109 ||
                                    e.keyCode === 107 ||
                                    e.keyCode === 110 ||
                                    e.keyCode === 190 ||
                                    e.keyCode === 188 ||
                                    e.keyCode === 189 ||
                                    e.keyCode === 187) &&
                                e.preventDefault()
                            }
                        />
                        {formErrors.phoneNumber && (
                            <Alert severity="error">{formErrors.phoneNumber}</Alert>
                        )}

                        <TextField
                            onKeyPress={onKeyPress}
                            margin="normal"
                            id="email"
                            label="Email"
                            value={formFields.email}
                            onChange={handleChange}
                            onKeyDown={(e) =>
                                (e.key === " ") && e.preventDefault()
                            }
                            autoComplete="off"
                        />
                        {formErrors.email && (
                            <Alert severity="error">{formErrors.email}</Alert>
                        )}

                        <TextField
                            onKeyPress={onKeyPress}
                            margin="dense"
                            variant="outlined"
                            id="confirmEmail"
                            label="Confirm Email"
                            value={formFields.confirmEmail}
                            onChange={handleChange}
                            autoComplete="off"
                        />
                        {formErrors.confirmEmail && (
                            <Alert severity="error">{formErrors.confirmEmail}</Alert>
                        )}

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel htmlFor="password">Password</InputLabel>
                                    <OutlinedInput
                                        value={formFields.password}
                                        onChange={e => { handleChange(e) }}
                                        onKeyPress={onKeyPress}
                                        fullWidth
                                        margin="dense"
                                        required
                                        id="password"
                                        type={showPassword ? "text" : "password"}
                                        label="Password"
                                        placeholder="Password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleTogglePassword}
                                                >
                                                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            spacing={0}>
                            <Grid item xs={12}>
                                <FormControl fullWidth margin="dense" variant="outlined">
                                    <InputLabel htmlFor="password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        value={formFields.confirmPassword}
                                        onChange={e => { handleChange(e) }}
                                        onKeyPress={onKeyPress}
                                        fullWidth
                                        margin="dense"
                                        required
                                        id="confirmPassword"
                                        label='Confirm Password'
                                        type={showConfirmPassword ? "text" : "password"}
                                        placeholder="Confirm Password"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleToggleConfirmPassword}
                                                >
                                                    {showConfirmPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <PasswordChecklist
                            className="text-black font-size-sm mt-1"
                            rules={["minLength", "capital", "lowercase", "number", "specialChar", "match"]}
                            minLength={6}
                            value={formFields.password}
                            valueAgain={formFields.confirmPassword}
                            messages={{
                                minLength: "Min 6 Characters",
                                capital: "One Uppercase Letter",
                                lowercase: "One Lowercase Letter",
                                number: "One Number",
                                specialChar: "One Special Character",
                                match: "Passwords Must Match",
                            }}
                            iconComponents={{
                                ValidIcon: <FaCheckCircle className="text-success mr-2" size={22} />,
                                InvalidIcon: <MdCancel className="text-danger mr-2" size={22} />,
                            }}
                        />

                        <Button
                            variant="contained" className="btn-primary p-3 mt-4 mb-2 text-white text-capitalize"
                            fullWidth
                            type="submit"
                            onClick={handlePrivacyPolicyModalOpen}>
                            Sign Up
                        </Button>
                    </div>
                </Grid>
            </Grid>
        </>}
    </>)
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleIndividualProfileCreate_d: (formFields: any) => dispatch(handleIndividualProfileCreate(formFields)),
        handleSubmit_d: (email: string, phoneNumber: string, logoUrl: string, password: string, firstName: string, lastName: string) =>
            dispatch(handleSubmit(email, phoneNumber, logoUrl, password, firstName, lastName)),
        registerResetState_d: () => dispatch(registerResetState()),
        clearApiResponses_d: () => dispatch(clearApiResponses()),
    };
};

const mapStateToProps = (state: any) => ({
    success: state.registerCard.success,
    error: state.registerCard.error,
    errorText: state.registerCard.errorText,
    identityUserId: state.registerCard.identityUserId,
    settings: state.settings.settings,
    loading: state.progressSpinner.loading,
    individualProfileError: state.individualProfile.error,
    individualProfileErrorText: state.individualProfile.errorText,
    individualProfileSuccess: state.individualProfile.success,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCard);
