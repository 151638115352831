import { makeStyles } from "tss-react/mui";

export const useViewModeToggleStyles = makeStyles()((theme) => ({
  viewModeToggle: {
    paddingLeft: 8,
  },

  viewModeToggleControlsContainer: {
    display: "flex",
    gap: 4,
  },

  viewModeToggleButtonNotActive: {
    color: theme.palette.grey[700],
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
