import React from 'react';
import { connect } from "react-redux";
import useStyles from './MobileNotAvailableStyles';
import {
    Container,
} from '@mui/material';

interface Props {
    settings: any,
}


const MobileNotAvailable: React.FC<Props> = props => {

    const { classes } = useStyles()

    const {
        settings,
    } = props;

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper} style={{ marginLeft: "29" }}>
                <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
                <h3 className="text-black text-center mb-4">Mobile applications coming soon!</h3>
            </div>

            <h5 className="mb-3">Our mobile applications are on their way!</h5>
            <h5 className="mb-3">Please check back in a few days, we are currently publishing our apps and they will be available ASAP.</h5>
            <h5 className="mb-3">We apologise for any inconvenience caused.</h5>
        </Container>
    )

}


const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps)(MobileNotAvailable);
