import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import {
    Button,
    Card,
    TextField,
    CircularProgress,
    Checkbox,
    Tooltip,
    Alert
} from '@mui/material'
import { handleSendEnquiry } from '@/components/Enquiry/store/enquiry';
import ns from '@/helpers/NotificationService';
import { isValidEmail, isValidPhoneNumber } from "@/helpers/Utils";
import PrivacyPolicyModal from '../Modal/PrivacyPolicyModal';

interface Props {
    handleSendEnquiry_d: (toEmailAddress: string, auctionId: string, lotId: string, name: string, email: string, phoneNumber: string, message: string, accpetedTerms: boolean, optInForMarketing: boolean, onCompletedCallback?: (result: any) => void) => void,
    auctionId: any,
    lotId: any,
    contactEmail: string,
    settings: any
}

const enquiryFieldsInitialState = {
    name: '',
    email: '',
    phoneNumber: '',
    message: '',
    optInForMarketing: false,
    acceptedTerms: false
}

const Enquiry: React.FC<Props> = (props) => {
    const {
        handleSendEnquiry_d,
        auctionId,
        lotId,
        contactEmail,
        settings,
    } = props;

    const [enquiryFields, setEnquiryFields] = React.useState<any>(enquiryFieldsInitialState);
    const [isBusySending, setIsBusySending] = React.useState<boolean>(false);
    const [formFieldsValidated, setFormFieldsValidated] = React.useState<boolean>(true);

    const [privacyPolicyModalState, setPrivacyPolicyModalState] = React.useState<any>({ open: false, url: undefined });

    const OnSubmit = () => {
        if (validate()) {
            setFormFieldsValidated(true);
            setIsBusySending(true);

            handleSendEnquiry_d(contactEmail, auctionId, lotId, enquiryFields.name, enquiryFields.email, enquiryFields.phoneNumber, enquiryFields.message, enquiryFields.acceptedTerms, enquiryFields.optInForMarketing, (res) => {
                if (res.success) {
                    ns.success('', "Email sent successfully");
                    setEnquiryFields(enquiryFieldsInitialState);
                }
                else {
                    ns.error("", "Email failed to send")
                }
                setIsBusySending(false);
            });
        } else {
            setFormFieldsValidated(false);
        }
    }

    const validate = (): boolean => {
        let fieldsValid = true;
        if (!enquiryFields.name ||
            !enquiryFields.email ||
            !enquiryFields.phoneNumber ||
            !enquiryFields.message) {
            fieldsValid = false;
        }

        let emailValid = true;
        if (!isValidEmail(enquiryFields.email.replace(" ", ""))) {
            emailValid = false;
        }

        let numberValid = true;
        if (!isValidPhoneNumber(enquiryFields.phoneNumber)) {
            numberValid = false;
        }

        return numberValid && fieldsValid && emailValid;
    }

    const onChange = (e: any) => {
        if (e.target.id === 'acceptedTerms' || e.target.id === 'optInForMarketing')
            setEnquiryFields({ ...enquiryFields, [e.target.id]: e.target.checked })
        else setEnquiryFields({ ...enquiryFields, [e.target.id]: e.target.value })
    }

    const handlePrivacyLinkClicked = () => {
        setPrivacyPolicyModalState({ open: true, url: settings?.ClientPrivacyPolicy ? 'ClientPrivacyPolicy' : undefined });
    }

    const handleTermsOfUseClicked = () => {
        setPrivacyPolicyModalState({ open: true, url: 'ClientTermsOfUse', title: 'Terms Of Use' });
    }

    return (
        <Card className="pt-5 pb-5 pr-4 pl-4">
            <h3 className="text-center text-black">Send Enquiry</h3>
            <h4 className="text-center font-weight text-black mb-1" >Ask us any questions and we will get back to you</h4>
            <TextField onChange={onChange} id="name" margin="dense" fullWidth variant="outlined" className="mb-3" label="Name" value={enquiryFields.name} required />
            <TextField onChange={onChange} id="email" margin="dense" fullWidth variant="outlined" className="mb-3" label="Email" value={enquiryFields.email} required onKeyDown={(e) =>
                (e.key === " ") && e.preventDefault()
            } />
            <TextField onChange={onChange} id="phoneNumber" margin="dense" fullWidth variant="outlined" className="mb-3" label="Phone Number" value={enquiryFields.phoneNumber} required type='number' onKeyDown={(e) =>
                (e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()
            } />
            <TextField onChange={onChange} id="message" margin="dense" fullWidth variant="outlined" className="mb-3" label="Message" value={enquiryFields.message} required multiline rows={4} inputProps={{ maxLength: 500 }} />
            <div className="align-box-row">
                <Checkbox className="align-self-center mr-2 text-primary" onChange={onChange} id='acceptedTerms' name='acceptedTerms' checked={enquiryFields.acceptedTerms} />
                <div>
                    <h6 className="text-black-75 mt-1 mb-0">
                        I have read and agree to the <span onClick={handlePrivacyLinkClicked} style={{ cursor: 'pointer', color: settings.Styles.Primary, marginTop: '-15px' }}>Privacy Policy</span>{settings?.ClientTermsOfUse && <> and <span onClick={handleTermsOfUseClicked} style={{ cursor: 'pointer', color: settings.Styles.Primary, marginTop: '-15px' }}>Terms Of Use</span></>}
                    </h6>
                </div>
            </div>
            <div className="align-box-row mb-2">
                <Checkbox className="align-self-center mr-2 text-primary" onChange={onChange} id='optInForMarketing' name='optInForMarketing' checked={enquiryFields.optInForMarketing} />
                <div>
                    <h6 className="text-black-75 mt-1 mb-0">
                        Send me news, events and exclusive offers (Optional)
                    </h6>
                </div>
            </div>

            {
                !isBusySending ?
                    (
                        <div>
                            <Tooltip title={enquiryFields.acceptedTerms ? '' : 'Please read and accept the Privacy Policy and Terms Of Use to continue'} PopperProps={{ style: { zIndex: 0 } }}>
                                <span>
                                    <Button disabled={!enquiryFields.acceptedTerms} onClick={OnSubmit} variant="contained" className="btn-primary mt-2 mb-2 p-3 text-white text-capitalize" fullWidth>Submit</Button>
                                </span>
                            </Tooltip>
                        </div>
                    )
                    :
                    (
                        <div className='d-flex flex-direction-column align-items-center justify-content-center'>
                            <CircularProgress
                                className="text-center center"
                                style={{
                                    margin: "auto",
                                    color: settings?.Styles?.OverrideLoadingSpinnerColor
                                        ? settings?.Styles?.OverrideLoadingSpinnerColor
                                        : settings?.Styles?.Primary,
                                }}
                                size={40} 
                            />
                        </div>
                    )
            }
            {
                !formFieldsValidated && (
                    <Alert severity="error">Please check that all fields are filled in and your email and phone number are valid</Alert>
                )
            }
            {privacyPolicyModalState.open && <PrivacyPolicyModal
                onSubmit={() => null}
                isDialogOpened={privacyPolicyModalState.open}
                handleCloseDialog={() => setPrivacyPolicyModalState({ open: false, url: undefined })}
                isViewOnlyMode={true}
                overrideUrl={privacyPolicyModalState.url}
                overrideTitle={privacyPolicyModalState.title}
            />}
        </Card>
    )

}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSendEnquiry_d: (toEmailAddress: string, auctionId: string, lotId: string, name: string, email: string, phoneNumber: string, message: string, acceptedTerms: boolean, optInForMarketing: boolean, onCompletedCallback?: (result: any) => void) => dispatch(handleSendEnquiry(toEmailAddress, auctionId, lotId, name, email, phoneNumber, message, acceptedTerms, optInForMarketing, onCompletedCallback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Enquiry);