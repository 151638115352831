import { store } from '../index';
import { showLoading, hideLoading, ProgressSpinnerExtraProperties } from '@/components/ProgressSpinner/store/ProgressSpinner';

export const showProgressSpinner = (props?: ProgressSpinnerExtraProperties) => {
    store.dispatch(showLoading(props) as any)
}

export const hideProgressSpinner = (delay = 500, overrideKeepOpen = false) => {
    if (delay > 0) {
        setTimeout(() => {
            store.dispatch(hideLoading(overrideKeepOpen) as any);
        }, delay);
    }
    else {
        store.dispatch(hideLoading(overrideKeepOpen) as any);
    }
}