import requestClient from "@/api/RequestClient";
import { apiPaths } from "@/api/ApiPaths";
import { formatDateTime } from "../../../helpers/Utils";

//ACTION TYPES
export const TOGGLE_NOTIFICATION_PANEL = 'TOGGLE_NOTIFICATION_PANEL';
export const UPDATE_NOTIFICATION_STATE = 'UPDATE_NOTIFICATION_STATE';
const CLEAR = 'CLEAR_NOTIFICATIONS';
const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
const UPDATE_UNREAD_COUNT = 'UPDATE_COUNT';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
const UPDATE_READ = 'UPDATE_READ';

//STATE INTERFACE
export interface NotificationPanelState {
    open: boolean,
    unreadNotificationCount: number,
    generalUnreadCount: number,
    list: any[],
    activeLotList: any[],
    pagination: any,
    loading: boolean,
    lastRefreshed: string | undefined,
    lastRefreshedDate: number | undefined,
    lastMessageReceived: number | undefined,
    newMessages: boolean
}

//INITIAL STATE DECLARATION
const initialState: NotificationPanelState = {
    open: false,
    unreadNotificationCount: 0,
    generalUnreadCount: 0,
    list: [],
    activeLotList: [],
    pagination: { currentPage: 1, pageSize: 10 },
    loading: false,
    lastRefreshed: undefined,
    lastRefreshedDate: undefined,
    lastMessageReceived: undefined,
    newMessages: false
}

//REDUCER
export default (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_PAGINATION: return { ...state, pagination: { ...state.pagination, ...action.pagination } };

        case UPDATE_NOTIFICATION_STATE:
            {
                let tempUpdates = action.updates;
                if (tempUpdates.withReplacement === false && tempUpdates.list) {
                    let tempList = [...(state.list || []), ...(tempUpdates.list || [])];
                    tempUpdates.list = tempList;
                }
                return { ...state, ...tempUpdates };
            }
            break;
        case TOGGLE_NOTIFICATION_PANEL: return { ...state, open: action.status };

        case UPDATE_UNREAD_COUNT:
            let now = Date.now();
            return {
                ...state,
                unreadNotificationCount: state.unreadNotificationCount + 1,
                generalUnreadCount: action.isLotNotification ? state.generalUnreadCount : state.generalUnreadCount + 1,
                lastMessageReceived: now,
                newMessages: state.lastRefreshedDate ? state.lastRefreshedDate < now : false
            };

        case REMOVE_NOTIFICATION:
            {
                //Handle tempList
                let tempList = [...(state.list || [])];
                let index = tempList.findIndex(x => x.id === action.notificationId);
                if (index > -1) {
                    tempList.splice(index, 1);
                }

                //Handle activeList
                let tempActiveList = (state.activeLotList || []).map(item => {
                    let replacementItem = { ...item };

                    let tempNotifications = [...(replacementItem.notifications || [])];
                    let index = tempNotifications.findIndex(x => x.id === action.notificationId);

                    if (index > -1) {
                        tempNotifications.splice(index, 1);
                        replacementItem.notifications = tempNotifications;

                        if (replacementItem.notifications.length === 0) return null;
                    }

                    return replacementItem;
                });

                return { ...state, list: tempList, activeLotList: tempActiveList.filter(x => x), pagination: { ...state.pagination, total: state.pagination.total - 1 } };
            }
            break;
        case UPDATE_READ:
            {
                let isGeneralNotification = true;

                //Handle tempList
                let tempList = [...(state.list || [])];
                let index = tempList.findIndex(x => x.id === action.notificationId);
                if (index > -1) {
                    let tempItem = { ...tempList[index] };
                    tempItem.isRead = true;
                    tempList.splice(index, 1, tempItem);

                    if (tempItem?.jsonData?.lotId)
                        isGeneralNotification = false;
                }

                //Handle activeList
                let tempActiveList = (state.activeLotList || []).map(item => {
                    let replacementItem = { ...item };

                    let tempNotifications = [...(replacementItem.notifications || [])];
                    let index = tempNotifications.findIndex(x => x.id === action.notificationId);

                    if (index > -1) {
                        let tempItem = { ...tempNotifications[index] };
                        tempItem.isRead = true;
                        tempNotifications.splice(index, 1, tempItem);
                        replacementItem.notifications = tempNotifications;

                        isGeneralNotification = false;
                    }

                    return replacementItem;
                });

                return {
                    ...state, list: tempList, activeLotList: tempActiveList,
                    unreadNotificationCount: state.unreadNotificationCount - 1,
                    generalUnreadCount: isGeneralNotification ? state.generalUnreadCount - 1 : state.generalUnreadCount
                };
            }
            break;
        case CLEAR: return { ...initialState };

        default: return state;
    }
};


//ACTIONS
export const increaseUnreadNotificationCount = (isLotNotification: boolean) => {

    return { type: UPDATE_UNREAD_COUNT, isLotNotification };
}

export const togglePanel = (status) => {
    return { type: TOGGLE_NOTIFICATION_PANEL, status };
}

export const updatePagination = (pagination) => {
    return { type: UPDATE_PAGINATION, pagination };
}

export const updateNotificationState = (updates) => {
    return { type: UPDATE_NOTIFICATION_STATE, updates };
}

export const removeNotification = (notificationId) => {
    return { type: REMOVE_NOTIFICATION, notificationId };
}

export const updateRead = (notificationId) => {
    return { type: UPDATE_READ, notificationId };
}

export const clearNotificationPanelState = () => {
    return { type: CLEAR }
}

export const retrieveNotificationCount = (profileId: string, includeRead: boolean, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.notification.getNotificationCountByProfileId, null, { profileId, includeRead })
            .then(function (res) {
                if (res.success) {
                    if (!includeRead) {
                        dispatch(updateNotificationState({ unreadNotificationCount: res.response.notificationCount }));
                    }
                    else {
                        dispatch(updatePagination({ total: res.response.notificationCount }));
                    }
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}


export const refreshNotificationsList = (profileId: string, pagination: any, includeRead: boolean, onCompletedCallback: (response: any) => void, withReplacement: boolean = true) => {
    return function (dispatch: any) {
        dispatch(updateNotificationState({ loading: true }))

        return requestClient.post(apiPaths.notification.getNotificationsByProfileId, {
            profileId,
            includeRead,
            pagination
        })
            .then(function (res) {
                if (res.success) {
                    let updates = res.response;

                    if (updates.list) {
                        let tempList = updates.list.map(item => {
                            let tempItem = item;
                            return tempItem;
                        })

                        updates.list = tempList;
                    }

                    let now = Date.now();
                    dispatch(updateNotificationState({ ...updates, withReplacement, lastRefreshed: formatDateTime(new Date(), "HH:mm dd/MM/yyyy"), lastRefreshedDate: now, newMessages: false }))
                }

                if (onCompletedCallback) onCompletedCallback(res);

                dispatch(updateNotificationState({ loading: false }))
            })
    }
}

export const refreshLotNotificationsList = (profileId: string, lotId: string | undefined, pagination: any, includeRead: boolean, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        dispatch(updateNotificationState({ loading: true }))

        return requestClient.post(apiPaths.notification.getLotNotificationsByProfileId, {
            profileId,
            lotId,
            includeRead,
            pagination
        })
            .then(function (res) {
                if (res.success) {
                    let updates = res.response;

                    if (updates.activeLotList) {
                        let tempList = updates.activeLotList.map(item => {
                            let tempItem = item;
                            return tempItem;
                        })

                        updates.activeLotList = tempList;
                    }

                    dispatch(updateNotificationState({ ...updates, lastRefreshed: formatDateTime(new Date(), "yyyy/MM/dd HH:mm") }))
                }

                if (onCompletedCallback) onCompletedCallback(res);

                dispatch(updateNotificationState({ loading: false }))
            })
    }
}

export const setNotificationAsRead = (notificationId: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.notification.setNotificationAsRead, notificationId)
            .then(function (res) {
                if (res.success) {
                    dispatch(updateRead(notificationId))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const setAllNotificationsAsRead = (profileId: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        dispatch(updateNotificationState({ loading: true }))

        return requestClient.post(apiPaths.notification.setAllNotificationsAsRead, profileId)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
                dispatch(updateNotificationState({ loading: false }))
            })
    }
}

export const deleteNotification = (notificationId: string, onCompletedCallback: (response: any) => void) => {
    return function (dispatch: any) {
        dispatch(updateNotificationState({ loading: true }))

        return requestClient.post(apiPaths.notification.deleteNotification, notificationId)
            .then(function (res) {
                if (res.success) {
                    dispatch(removeNotification(notificationId))
                }

                if (onCompletedCallback) onCompletedCallback(res);

                dispatch(updateNotificationState({ loading: false }))
            })
    }
}