import { makeStyles } from "tss-react/mui";

export const useConnectionStatusIndicatorStyles = makeStyles()((theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        padding: 5,
        alignItems: 'center',
    },
    connectorText: {
        paddingTop: 5
    },
    snackbarContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%'
    },
    snackbarText: {
        textAlign: 'center'
    }
}));
