import React, { FC, memo, CSSProperties, useMemo, useCallback } from "react";

import { useViewModeToggleStyles } from "./ViewModeToggle.styles";

import { FaBars, FaThLarge } from "react-icons/fa";
import { Box, Typography } from "@mui/material";

import { View } from "@/components/library/ViewModeToggle";

export interface ViewModeToggleProps {
    containerStyle?: CSSProperties;
    selectedView: View;
    setSelectedView: React.Dispatch<React.SetStateAction<View>>;
}

const ViewModeToggle: FC<ViewModeToggleProps> = ({
    setSelectedView,
    selectedView,
}) => {
    const { classes: {
        viewModeToggle,
        viewModeToggleControlsContainer,
        viewModeToggleButtonNotActive,
    } } = useViewModeToggleStyles();

    const handlePress = useCallback(
        (view: View) => {
            if (selectedView !== view) {
                setSelectedView(view);
            }
        },
        [selectedView]
    );

    return (
        <Box className={viewModeToggle}>
            <Typography variant="caption">View</Typography>
            <Box className={viewModeToggleControlsContainer}>
                <FaBars
                    className={
                        selectedView === View.DETAIL
                            ? "text-primary"
                            : viewModeToggleButtonNotActive
                    }
                    onClick={() => handlePress(View.DETAIL)}
                    fontSize="large"
                />
                <FaThLarge
                    className={
                        selectedView === View.COMPACT
                            ? "text-primary"
                            : viewModeToggleButtonNotActive
                    }
                    onClick={() => handlePress(View.COMPACT)}
                    fontSize="large"
                />
            </Box>
        </Box>
    );
};

export default memo(ViewModeToggle);
