import React, { FC, useEffect } from 'react';

type ReadMoreReadLessProps = {
    children: string;
    charLimit?: number,
    ellipsis?: string,
    readMoreText?: string,
    readLessText?: string,
    readMoreClassName?: string,
    readLessClassName?: string,
    readMoreStyle?: object,
    readLessStyle?: object,
}

const ReadMoreReadLess: FC<ReadMoreReadLessProps> = (props) => {
    const {
        children,
        charLimit = 150,
        ellipsis,
        readMoreText = 'Read more',
        readLessText = 'Read less',
        readMoreClassName = 'react-read-more-read-less react-read-more-read-less-more',
        readLessClassName = 'react-read-more-read-less react-read-more-read-less-less',
        readMoreStyle = { whiteSpace: "nowrap", cursor: "pointer" },
        readLessStyle = { whiteSpace: "nowrap", cursor: "pointer" }
    } = props;

    const [showMore, setShowMore] = React.useState(false);


    const shortText = children
        .substring(0, charLimit)
        .replace(/\s+$/, '') // Remove trailing whitespaces
        .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]+$/, '') // Remove trailing punctuation
        + (charLimit >= children.length ? '' : ellipsis);

    const ReadMore = () => (((charLimit >= children.length) || !readMoreText) ? null : <span
        className={readMoreClassName}
        role="presentation"
        style={readMoreStyle}
        onClick={() => { setShowMore(true); }}
    >{readMoreText}</span>);

    const ReadLess = () => (((charLimit >= children.length) || !readLessText) ? null : <span
        className={readLessClassName}
        role="presentation"
        style={readLessStyle}
        onClick={() => { setShowMore(false); }}
    >{readLessText}</span>);

    return <React.Fragment>{showMore ? children : shortText} {showMore ? <ReadLess /> : <ReadMore />}</React.Fragment>
}

export default ReadMoreReadLess;