import React from 'react';
import {
    Dialog,
    CircularProgress,
    Grid
} from '@mui/material';
import { connect } from 'react-redux';

interface Props {
    loading: boolean,
    size: number,
    thickness: number,
    description: string,
    settings: any,
}

const ProgressSpinner: React.FC<Props> = props => {
    const { size, thickness, description, loading, settings } = props;

    return (
        <Dialog open={loading} fullScreen style={{ background: 'transparent', opacity: 0.8 }}>
            <div className='d-flex flex-direction-column align-items-center justify-content-center' style={{ height: '100%', width: '100%', background: settings?.Styles?.OverrideLoadingSpinnerBackgroundColor ? settings?.Styles?.OverrideLoadingSpinnerBackgroundColor : "#000000"}} >
                <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                    direction="column"
                >
                    <Grid item xs={12} >
                        <div className='d-flex flex-direction-column align-items-center justify-content-center'>
                            <CircularProgress className="text-center" size={size} thickness={thickness} style={{ margin: 'auto', color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />
                        </div>
                    </Grid>
                    {
                        description && (
                            <Grid item xs={12} >
                                <h6 style={{ color: settings?.Styles?.OverrideLoadingSpinnerTextColor ? settings?.Styles?.OverrideLoadingSpinnerTextColor : "#ffffff" }} className="mt-3 text-center">{description}</h6>
                            </Grid>
                        )
                    }
                </Grid>
            </div>
        </Dialog>
    )
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    loading: state.progressSpinner.loading,
    size: state.progressSpinner.size,
    thickness: state.progressSpinner.thickness,
    description: state.progressSpinner.description,
})

export default connect(mapStateToProps)(ProgressSpinner);
