import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react';
import { TextField, Button } from "@mui/material";
import GoogleMapReact from 'google-map-react';
import MapControl from './MapControl';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';

const coloredMarker = (color: string, maps: any) => {
    return {
        path: "M15 13 15 8 16 8 12 5 8 8 9 8 9 13 11 13 11 11 13 11 13 13Zm-3-10.984Q14.906 2.016 16.945 4.055T18.984 9Q18.984 10.453 18.257 12.328T16.499 15.844 14.46 18.914 12.749 21.187L11.999 21.984Q11.718 21.656 11.249 21.117T9.561 18.961 7.428 15.82 5.764 12.375 5 9Q5 6.094 7.039 4.055T11.984 2.016ZM11.25 10 11.25 9 10.25 9 10.25 10Zm1.5-1L13.75 9 13.75 10 12.75 10Z",
        fillColor: color,
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new maps.Point(14, 20),
    }
};

interface SearchBoxProps {
    maps: any,
    placeholder: string,
    onPlacesChanged: (place: any) => void,
    onUseSecondary: (value: boolean) => void,
    locationState: any,
    searchStringDefault?: string
}

const SearchBox: FC<SearchBoxProps> = props => {
    const { maps, onPlacesChanged, placeholder, onUseSecondary, locationState, searchStringDefault } = props;

    const input = useRef<any>(null);
    const autoComplete = useRef<any>(null);

    const handleOnPlacesChanged = useCallback(() => {
        if (onPlacesChanged) onPlacesChanged(autoComplete.current.getPlace());
    }, [onPlacesChanged, autoComplete]);

    useEffect(() => {
        if (input.current.value !== locationState?.primaryLocation?.searchString)
            input.current.value = locationState?.primaryLocation?.searchString ?? searchStringDefault ?? ''
    }, [searchStringDefault, locationState?.primaryLocation]);

    useEffect(() => {
        if (!autoComplete.current && maps) {
            autoComplete.current = new maps.places.Autocomplete(input.current);
            //autoComplete.current.setComponentRestrictions({ country: ["ZA"] }); //Limited to South Africa
            autoComplete.current.addListener('place_changed', handleOnPlacesChanged);
        }

        return () => {
            if (maps) {
                autoComplete.current = null;
                maps.event.clearInstanceListeners(autoComplete);
            }
        };
    }, [maps, handleOnPlacesChanged]);

    const clear = () => {
        input.current.value = '';
        autoComplete.current.set('place', 'clear');
    }

    return <>
        <div style={{ borderRadius: 4, backgroundColor: 'white', width: 'calc(100% - 70px)', marginTop: 10, border: 'none' }}>
            <TextField
                margin="dense"
                fullWidth
                inputRef={input}
                placeholder={placeholder ?? 'Enter a location'}
                variant="outlined"
                autoFocus={true}
                style={{ margin: 0, boxShadow: '0 0.25rem 0.55rem rgb(0 0 0 / 35%)', borderRadius: 4 }}
            />
        </div>
        {(locationState?.primaryLocation || locationState?.secondaryLocation) && <Button
            style={{ padding: 10, marginRight: 10, }}
            variant="contained"
            className="btn-danger mt-1 text-white text-capitalize"
            type="submit"
            onClick={clear}
        >
            Clear Locations
        </Button>}
        {locationState?.secondaryLocation && <Button
            style={{ padding: 10, background: '#2b2b2b' }}
            variant="contained"
            className="btn-secondary mt-1 text-white text-capitalize blue"
            type="submit"
            onClick={() => onUseSecondary(!locationState?.useSecondary)}
        >
            Use {locationState?.useSecondary ? 'Primary' : 'Secondary'} Location Coordinates
        </Button>}
    </>
}

interface GoogleMapContainerProps {
    onLocationChanged?: (location: any) => void,
    mapOptions?: any,
    placeHolder?: string,

    canEdit?: boolean,
    center?: {
        lat: number,
        lng: number
    },
    searchString?: string,
    style?: CSSProperties,
    showLabels?: boolean,
    allowZoom?: boolean,
    apiKey: string
}

const GoogleMapContainer: FC<GoogleMapContainerProps> = props => {
    const {
        onLocationChanged,
        mapOptions,
        canEdit = true,
        center,
        searchString,
        style,
        showLabels = true,
        allowZoom = true,
        apiKey
    } = props;

    const [mapState, setMapState] = useState<any>();
    const [primaryLocation, setPrimaryLocation] = useState<any>(undefined);
    const [secondaryLocation, setSecondaryLocation] = useState<any>(undefined);
    const [useSecondary, setUseSecondary] = useState<boolean>(false);

    const primaryMarker = useRef<any>(null);
    const secondaryMarker = useRef<any>(null);

    useEffect(() => {
        if (onLocationChanged) {
            let location: any = { ...(primaryLocation || {}) };
            if (useSecondary)
                location = {
                    ...location, ...(secondaryLocation || {})
                };

            if (!isEmpty(location)) onLocationChanged(location);
        }
    }, [primaryLocation, secondaryLocation, useSecondary]);

    useEffect(() => {
        if (!primaryLocation && !secondaryLocation && center && mapState?.googlemaps) {
            handleOnPlacesChanged('clear');
        }
    }, [center, primaryLocation, secondaryLocation, mapState]);

    const defaultProps = {
        center: {
            lat: -28.70,
            lng: 23.83
        },
        zoom: 5,
    };

    const createMapOptions = (maps: any) => {
        let options: any = {
            mapTypeId: "roadmap",
            keyboardShortcuts: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            zoomControl: allowZoom,
            zoomControlOptions: {
                position: 1
            },
        };

        if (!canEdit) {
            options = {
                ...options,
                //panControl: false,
                //scrollwheel: false,
                navigationControl: false,
                //scaleControl: false,
                //draggable: false,
            }
        }

        return options;
    }

    const handleApiLoaded = (map, maps) => {
        primaryMarker.current = new maps.Marker({ map });
        secondaryMarker.current = new maps.Marker({ map });

        if (center) {
            primaryMarker.current.setPosition(center);
            if (showLabels) primaryMarker.current.setLabel({ text: 'Primary Location', className: 'googleMarkerLabelPrimary' });
            primaryMarker.current.setIcon(coloredMarker("red", maps));
            primaryMarker.current.setVisible(true);
        }

        if (canEdit) {
            maps.event.addListener(map, 'click', function (e) {
                secondaryMarker.current.setVisible(false);

                //Put markers on the place
                secondaryMarker.current.setPosition(e.latLng);
                if (showLabels) secondaryMarker.current.setLabel({ text: 'Secondary Location', className: 'googleMarkerLabelSecondary' });
                secondaryMarker.current.setVisible(true);
                secondaryMarker.current.setIcon(coloredMarker("blue", maps));

                setSecondaryLocation({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })
            });
        }

        setMapState({
            apiReady: true,
            map: map,
            googlemaps: maps
        });

    };


    const handleOnPlacesChanged = (place: any) => {
        if (place === 'clear') {
            if (center?.lng && center?.lat) {
                primaryMarker.current?.setPosition(center);
                if (showLabels) primaryMarker.current?.setLabel({ text: 'Primary Location', className: 'googleMarkerLabelPrimary' });
                primaryMarker.current?.setIcon(coloredMarker("red", mapState.googlemaps));
                primaryMarker.current?.setVisible(true);
            }
            else {
                primaryMarker.current?.setVisible(false);
            }

            secondaryMarker.current?.setVisible(false);
            mapState.map.setCenter(center?.lat && center?.lng ? center : defaultProps.center);
            mapState.map.setZoom(center?.lat && center?.lng ? 15 : defaultProps.zoom);
            mapState.map.panBy(0, canEdit ? -60 : -30);
            setPrimaryLocation(undefined);
            setSecondaryLocation(undefined);
            setUseSecondary(false);

            return;
        }

        if (!place?.geometry) return; //No place selected

        if (place.geometry.viewport) {
            mapState.map.fitBounds(place.geometry.viewport);
            mapState.map.panBy(0, canEdit ? -60 : -30);
        } else {
            mapState.map.setCenter(place.geometry.location);
            mapState.map.setZoom(10);
            mapState.map.panBy(0, canEdit ? -60 : -30);
        }

        primaryMarker.current.setPosition(place.geometry.location);
        if (showLabels) primaryMarker.current.setLabel({ text: 'Primary Location', className: 'googleMarkerLabelPrimary' });
        primaryMarker.current.setIcon(coloredMarker("red", mapState.googlemaps));
        primaryMarker.current.setVisible(true);

        let address: any = {
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
        };

        place.address_components.forEach(x => {
            if (x.types.includes('street_number')) address.addressLine1 = x.long_name;
            if (x.types.includes('route')) address.addressLine1 += ' ' + x.long_name;
            if (x.types.includes('locality')) address.city = x.long_name;
            if (x.types.includes('administrative_area_level_1')) address.state = x.long_name;
            if (x.types.includes('country')) address.country = x.long_name;
            if (x.types.includes('postal_code')) address.zip = x.long_name;
        });

        setPrimaryLocation({ searchString: place.formatted_address, latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng(), address })
    }

    const handleUseSecondary = (value: boolean) => {
        setUseSecondary(value);
    }

    return <GoogleMapReact
        bootstrapURLKeys={{
            key: apiKey,
            libraries: ['places']
        }}
        keyboardShortcuts={false}
        streetViewControl={false}
        fullscreenControl={false}
        mapTypeControl={false}
        zoomControl={true}
        options={createMapOptions}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        style={{ width: '100%', height: 'calc(100%)', position: 'relative', ...(style || {}) }}
    >
        {mapState?.apiReady && canEdit && <MapControl map={mapState?.map} controlPosition={mapState?.googlemaps?.ControlPosition?.TOP_LEFT} style={{ width: '100%' }}
            locationState={{ primaryLocation: primaryLocation ? true : false, secondaryLocation: secondaryLocation ? true : false, useSecondary }}
        >
            <SearchBox maps={mapState.googlemaps} onPlacesChanged={handleOnPlacesChanged} searchStringDefault={searchString} placeholder="Enter a location" onUseSecondary={handleUseSecondary} locationState={{ primaryLocation, secondaryLocation, useSecondary }} />
        </MapControl>}
    </GoogleMapReact>
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

const mapStateToProps = (state: any) => ({
    apiKey: state.settings.settings?.GoogleMapsApiKey,
})

export default connect(mapStateToProps, mapDispatchToProps)(GoogleMapContainer);
