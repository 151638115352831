import React, { useEffect } from 'react';
import {
    Button,
    TextField,
    CircularProgress,
    InputAdornment,
    Grid,
    DialogContentText,
    Alert
} from '@mui/material';
import { clearApiResponses, clearBidResponses } from '@/pages/Bids/store/Bidding';
import { connect } from 'react-redux';
import { RegisteredUserStatusEnum } from '@/helpers/RegisteredUserStatusEnum'
import { submitBid } from '@/pages/Bids/store/Bidding';
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage'
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import { ConfirmationDialog } from "@/components/Modal/confirmationModal";
import ns from '@/helpers/NotificationService';

import clsx from 'clsx';
import CurrencyLabel from '../CurrencyComponent/CurrencyLabel';
import { handleSetWishlistBiddingNotification } from '../../pages/MyActivity/store/MyActivity';

interface Props {
    submitTender_d: (auctionId: string, lotId: string, amount: number, profileId: string, isTenderSubmission: boolean, onCompletedCallback: (res: any) => void) => void,
    clearResponses_d: () => void,
    clearBidResponses_d: () => void,
    auctionId: string,
    lotId: string,
    validateResult: any,
    success: boolean,
    bidError: boolean,
    bidErrorText: string,
    profileId: string,
    loginSuccess: boolean,
    logoutSuccess: boolean,
    tenderAmount: number,
    onTenderSuccessfullySubmitted: (tenderAmount: number) => void;
    handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => void,
    wishlistNotificationFlagObject: any;
    settings: any
}

const TenderButton: React.FC<Props> = props => {

    const {
        clearResponses_d,
        clearBidResponses_d,
        submitTender_d,
        auctionId,
        lotId,
        validateResult,
        success,
        bidError,
        bidErrorText,
        profileId,
        loginSuccess,
        logoutSuccess,
        tenderAmount,
        onTenderSuccessfullySubmitted,
        handleSetWishlistBiddingNotification_d,
        wishlistNotificationFlagObject,
        settings
    } = props;

    const [isBusy, setIsBusy] = React.useState(false);
    const [tender, setTender] = React.useState({
        status: RegisteredUserStatusEnum.NotRegistered,
        reason: ''
    });

    const [tenderAlreadySubmitted, setTenderAlreadySubmitted] = React.useState(true);

    const [tenderAmountField, setTenderAmountField] = React.useState<any>({ tenderAmount: 0 });
    const [confirmationModal, setConfirmationModal] = React.useState<any>({
        open: false,
        id: ''
    })

    useEffect(() => {
        if (tenderAmount === null || tenderAmount === undefined || tenderAmount === 0) setTenderAlreadySubmitted(false);
        else setTenderAlreadySubmitted(true);
        setTenderAmountField({ ...tenderAmountField, tenderAmount })
    }, [tenderAmount, auctionId, lotId, profileId])

    const onTenderAmountChange = (e: { target: { id: any; value: string } }) => {
        setTenderAmountField({ ...tenderAmountField, [e.target.id]: e.target.value })
    };

    const submitTender = () => {
        showConfirmationModal(true, '');
    }

    React.useEffect(() => {
        clearBidResponses_d();
    }, [profileId])

    React.useEffect(() => {
        if (success) {
            clearResponses_d();
            setIsBusy(false);
        }
    }, [success]);

    React.useEffect(() => {
        if (bidError) {
            clearResponses_d();
            setIsBusy(false);
        }
    }, [bidError]);

    React.useEffect(() => {
        if (loginSuccess) {

        }
    }, [loginSuccess])

    React.useEffect(() => {
        if (logoutSuccess) {
            setTender({ ...tender });
        }
    }, [logoutSuccess])

    React.useEffect(() => {
        if (validateResult && validateResult.length > 0) {
            const result = validateResult.filter(result => result.auctionId === auctionId)[0]
            if (result) {
                if (result.status === RegisteredUserStatusEnum.Approved && PrembidLocalStorage.currentUser) {
                    setTenderAmountField({ ...tenderAmountField, tenderAmount: tenderAmount })
                }
                else {
                    setTender({ ...tender, status: result.status, reason: result.reason });
                }
            }
        }
    }, [validateResult])

    const onConfirmationModalClosed = () => {
        showConfirmationModal(false, '');
    }

    const onConfirmationModalOk = () => {
        showConfirmationModal(false, '');

        setIsBusy(true);
        submitTender_d(auctionId, lotId, Number(tenderAmountField.tenderAmount), profileId, true, (res) => {
            if (res?.success) {
                ns.success('', 'Tender successfully submitted');
                onTenderSuccessfullySubmitted(Number(tenderAmountField.tenderAmount));
                let temp = { ...wishlistNotificationFlagObject };
                temp.biddingActivityFlag = true;
                handleSetWishlistBiddingNotification_d(temp);
                setTenderAlreadySubmitted(true);
            }
        });
    }

    const showConfirmationModal = (open: boolean, id: string) => {
        setConfirmationModal({ ...confirmationModal, open: open, id: id });
    }

    function AlertNotification() {
        if (bidErrorText !== '' && bidErrorText !== undefined) {
            return <Alert severity='error'>{bidErrorText}</Alert>;
        }
        return <div></div>;
    }

    return (
        <React.Fragment>
            {isBusy ? (<div className='d-flex flex-direction-column align-items-center justify-content-center'>
                <CircularProgress
                    className="text-center center mt-3"
                    style={{
                        margin: "auto",
                        color: settings?.Styles?.OverrideLoadingSpinnerColor
                            ? settings?.Styles?.OverrideLoadingSpinnerColor
                            : settings?.Styles?.Primary,
                    }}
                    size={40}
                />

            </div>) : (
                <React.Fragment>
                    <Grid
                        className="mt-2"
                        container
                        spacing={2}
                    >
                        <Grid item xs={12}>
                            <h6>Congratulations, you have been approved!</h6>
                            <h6>Please submit your confidential tender in the 'Tender Amount' section below, then click on Submit.</h6>
                            <h6>Thank you!</h6>
                        </Grid>
                        <Grid item xs={12}>
                            <AlertNotification />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                disabled={tenderAlreadySubmitted}
                                margin="dense"
                                value={tenderAmountField.tenderAmount}
                                onChange={onTenderAmountChange}
                                id="tenderAmount"
                                fullWidth
                                variant="outlined"
                                className='m-1'
                                label="Tender Amount"
                                InputProps={{
                                    inputComponent: CurrencyInput,
                                    startAdornment: (
                                        <InputAdornment position="start" >
                                            <Currency priceWithSymbol={""} disabled={tenderAlreadySubmitted} />
                                        </InputAdornment>
                                    )
                                }} />
                        </Grid>
                        <Grid item xs={6}>
                            <Button style={{
                                height: 56,
                                opacity: tenderAlreadySubmitted ? 0.4 : 1,
                                pointerEvents: tenderAlreadySubmitted ? 'none' : 'auto'
                            }} onClick={submitTender} fullWidth variant="contained" className={clsx(`p-3 mt-1 text-white text-capitalize btn-${tenderAlreadySubmitted ? "secondary" : "primary"}`)}>
                                {tenderAlreadySubmitted ? "Tender Submitted" : "Submit"}
                            </Button>
                        </Grid>
                    </Grid>

                    <ConfirmationDialog
                        open={confirmationModal.open}
                        payload={confirmationModal.id}
                        okButtonText="Confirm"
                        cancelButtonText="Cancel"
                        title="Please note, you are about to submit your tender"
                        content={<>
                            <DialogContentText>Please note that by clicking <strong style={{ color: settings.Styles.Primary }}>confirm</strong> you acknowledge that you are <strong style={{ color: settings.Styles.Primary }}>duly authorised</strong> to do so and that this constitutes a <strong style={{ color: settings.Styles.Primary }}>legally binding agreement</strong>.</DialogContentText>
                            <DialogContentText>{"If your tender is accepted, you are legally obligated to purchase the " + settings?.LotName?.toLowerCase() + " at the agreed-upon price."}</DialogContentText>
                            <DialogContentText>This action cannot be revoked or amended once submitted,  <strong style={{ color: settings.Styles.Primary }}>please confirm</strong> that you are about to make the below tender before proceeding.</DialogContentText>
                            <DialogContentText style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 24 }}><CurrencyLabel textColour={settings.Styles.Primary} value={tenderAmountField.tenderAmount} /></DialogContentText>
                            <DialogContentText>Thank you.</DialogContentText>
                        </>}
                        onCancel={onConfirmationModalClosed}
                        onOk={onConfirmationModalOk}
                    />

                </React.Fragment>
            )}
        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearResponses_d: () => dispatch(clearApiResponses()),
        clearBidResponses_d: () => dispatch(clearBidResponses()),
        submitTender_d: (auctionId: string, lotId: string, amount: number, profileId: string, isTenderSubmission: boolean, onCompletedCallback: (res: any) => void) => dispatch(submitBid(auctionId, lotId, amount, profileId, isTenderSubmission, onCompletedCallback)),
        handleSetWishlistBiddingNotification_d: (payload: any, onCompletedCallback?: () => void) => dispatch(handleSetWishlistBiddingNotification(payload, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    success: state.bidding.success,
    validateResult: state.participateButton.validateResult,
    bidError: state.bidding.error,
    bidErrorText: state.bidding.errorText,
    profileId: state.loginForm.changeProfileId,
    loginSuccess: state.loginForm.loginSuccess,
    logoutSuccess: state.loginForm.logoutSuccess,
    settings: state.settings.settings,
    wishlistNotificationFlagObject: state.myActivity.wishlistNotificationFlagObject,
})

export default connect(mapStateToProps, mapDispatchToProps)(TenderButton);