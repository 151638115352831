import React, { ReactElement } from 'react'
import {
    Popper,
    Paper,
    ClickAwayListener,
} from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';

export interface PopperComponentOptions { }

interface PopperComponentProps extends PopperComponentOptions {
    headerText?: string;
    anchorEl: any;
    element?: React.ReactNode;
    handlePopperComponentClose: () => void;
    popperStyle?: CSSProperties;
}

export const PopperComponent: React.FC<PopperComponentProps> = props => {
    const {
        headerText,
        anchorEl,
        element,
        handlePopperComponentClose,
        popperStyle
    } = props;

    return (
        <ClickAwayListener onClickAway={() => setTimeout(handlePopperComponentClose, 5)}>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={'right-start'} style={popperStyle}>
                <Paper style={{ marginLeft: '10px' }} className="popper dropdown-menu-xl overflow-hidden p-0">
                    {
                        headerText &&
                        <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                            <h5 className="text-capitalize pl-1 text-black">
                                {headerText}
                            </h5>
                        </div>
                    }

                    {element}
                </Paper>
            </Popper>
        </ClickAwayListener>
    );
};

export default PopperComponent;