import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

export default function reducer(state = null, action: any) {
    switch (action.type) {
        default: return state
    }
}

export const handleSendEnquiry = (toEmailAddress: string, auctionId: string, lotId: string, name: string, email: string, phoneNumber: string, message: string,
    acceptedTerms: boolean, optInForMarketing: boolean,
    onCompletedCallback?: (result: any) => void) => {

    let enquiry = {
        toEmailAddress: toEmailAddress,
        auctionId: auctionId,
        lotId: lotId,
        name: name,
        email: email.replace(" ", ""),
        cellNumber: phoneNumber,
        message: message,
        acceptedTerms: acceptedTerms,
        optInForMarketing: optInForMarketing
    }

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lots.sendEnquiry, enquiry)
            .then(function (res) {
                if (onCompletedCallback)
                    onCompletedCallback(res)
            })
    }
}