import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

export const REGISTRATION_MODAL_UPDATE = 'REGISTRATION_MODAL_UPDATE';
export const REGISTRATION_MODAL_SET = 'REGISTRATION_MODAL_SET';
export const REGISTRATION_MODAL_RESET = 'REGISTRATION_MODAL_RESET';

const initialState = {
    step: "PROFILE_SELECTOR",
    profiles: [],
    selectedProfileId: '',
    setSelectedProfileId: null,
    registrationProcessStatus: null,
    registeredProfile: null,
    registrationProcessDocuments: null,
    registrationDocuments: null
}

export default function reducer(state = initialState, action: any) {
    if ([REGISTRATION_MODAL_UPDATE, REGISTRATION_MODAL_SET, REGISTRATION_MODAL_RESET].includes(action.type))
        console.log("UPDATING=> ", state, action)
    switch (action.type) {
        case REGISTRATION_MODAL_UPDATE:
            const tempState = { ...state };
            const { resetProfiles = false, ...rest } = action.payload;

            const newState = { ...tempState, ...rest };
            if (resetProfiles)
                return { ...newState, profiles: [] }

            return newState;
        case REGISTRATION_MODAL_SET:
            return { ...state, ...action.payload };
        case REGISTRATION_MODAL_RESET: return { ...initialState }
        default: return state;
    }
}

export const resetModal = () => {
    return { type: REGISTRATION_MODAL_RESET }
}

export const setStep = (step: string) => {
    return { type: REGISTRATION_MODAL_UPDATE, payload: { step: step } }
}

export const updateState = (data: any) => {
    return { type: REGISTRATION_MODAL_UPDATE, payload: data }
}

export const setState = (data: any) => {
    return { type: REGISTRATION_MODAL_SET, payload: data }
}

export const retrieveProfiles = (onCompletedCallback?: (res?: any) => void, setProfile: boolean = false,) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.profile.retrieveBiddingProfiles, null, null)
            .then(function (res) {
                if (res.success) {
                    let profiles: any[] = [];
                    let targetIndex;
                    let targetvalue;
                    profiles = profiles.concat(res.response.iBiddingProfiles?.map(profile => { return { name: profile.firstName + ' ' + profile.lastName, id: profile.id, type: 'Individual', isDefaultProfile: profile.isDefaultProfile } }))
                    profiles = profiles.concat(res.response.cBiddingProfiles?.map(profile => { return { name: profile.companyName, id: profile.id, type: 'Company', isDefaultProfile: profile.isDefaultProfile } }))
                    let tempArrayObjects = profiles;
                    tempArrayObjects.map((x, index) => {
                        if (x.isDefaultProfile === true) {
                            targetIndex = index;
                        }
                    });
                    if (targetIndex > -1) {
                        targetvalue = tempArrayObjects[targetIndex];
                        tempArrayObjects.splice(targetIndex, 1);
                        tempArrayObjects.unshift(targetvalue);
                    }

                    if (setProfile) dispatch(setState({ profiles: tempArrayObjects, selectedProfileId: profiles[0]?.id ?? '', profileType: profiles[0]?.type ?? null }))
                    else dispatch(setState({ profiles: tempArrayObjects }))
                }
                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}

export const retrieveRegistrationProcessStatus = (profileId: string, auctionId: string, profileType: string, onCompletedCallBack: (res?: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.profile.retrieveRegistrationProcessStatus, { profileId, auctionId, profileType })
            .then(function (res) {
                if (res.success) {
                    dispatch(updateState({ registrationProcessStatus: res.response }))
                }
                if (onCompletedCallBack) onCompletedCallBack(res)
            })
    }
}

export const retrieveRegistrationProcessDocuments = (profileId: string, auctionId: string, onCompletedCallBack?: (res?: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.document.retrieveRegistrationProcessDocuments, { profileId, auctionId })
            .then(function (res) {
                if (res.success) {
                    dispatch(updateState({ registrationProcessDocuments: res.response }))
                }
                if (onCompletedCallBack) onCompletedCallBack(res)
            })
    }
}

export const retrieveRegistrationDocuments = (auctionId: string, onCompletedCallBack?: (res?: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.document.retrieveRegistrationDocuments, { auctionId }, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(updateState({ registrationDocuments: res.response }))
                }
                if (onCompletedCallBack) onCompletedCallBack(res)
            })
    }
}

export const updateProfileAddress = (data: any, onCompletedCallBack?: (res?: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.profile.updateProfileAddress, data)
            .then(function (res) {
                if (onCompletedCallBack) onCompletedCallBack(res)
            })
    }
}

export const uploadRegistrationProcessDocuments = (data: any, onCompletedCallBack?: (res?: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.document.uploadRegistrationProcessDocuments, data)
            .then(function (res) {
                if (onCompletedCallBack) onCompletedCallBack(res)
            })
    }
}

export const finishRegistration = (data: any, onCompletedCallBack?: (res?: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.profile.finishRegistration, data)
            .then(function (res) {
                if (onCompletedCallBack) onCompletedCallBack(res)
            })
    }
}

export const retrieveBankDetailsByAuctionId = (auctionId: any, onCompletedCallBack?: (res?: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.vendor.retrieveBankDetailsByAuctionId, { auctionId }, null)
            .then(function (res) {
                if (onCompletedCallBack) onCompletedCallBack(res)
            })
    }
}