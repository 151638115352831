import React from 'react'
import {
    Popper,
    Paper,
    ClickAwayListener,
} from '@mui/material';
import DocumentsInnerComponent from '../DocumentsInnerComponent/DocumentsInnerComponent';

export interface DocumentPopperOptions { }

interface DocumentPopperProps extends DocumentPopperOptions {
    anchorEl: any;
    isBusy: any;
    documents: any;
    handleDocumentClose: () => void;
    handleViewDocument: (url: string) => void;
    placement?: any;
}

export const DocumentPopper: React.FC<DocumentPopperProps> = props => {
    const {
        anchorEl,
        isBusy,
        documents: documents,
        handleDocumentClose,
        handleViewDocument,
        placement
    } = props;

    return (
        <div>
            <ClickAwayListener onClickAway={() => setTimeout(handleDocumentClose, 5)}>
                <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement={placement ?? 'right-start'} style={{zIndex: 1000} } >
                    <Paper style={{ }} className="popper dropdown-menu-xl overflow-hidden p-0">
                        <div className="d-flex px-3 pt-3 align-items-center justify-content-between">
                            <h5 className="text-capitalize pl-1 text-black">
                                Documents
                            </h5>
                        </div>
                        <DocumentsInnerComponent isBusy={isBusy} lotDocuments={documents} handleViewDocument={handleViewDocument} />
                    </Paper>
                </Popper>
            </ClickAwayListener>
        </div >
    );
};

export default DocumentPopper;