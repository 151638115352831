import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    Tooltip,
    CircularProgress,
    IconButton
} from '@mui/material';
import { handleRegisteredUserCreate, handleRegistrationWizardModalDataSubmission, clearWizardResponses } from './store/Participate';
import { connect } from 'react-redux';
import useStyles from './ParticipateButtonStyles';
import { RegisteredUserStatusEnum } from '@/helpers/RegisteredUserStatusEnum'
import { FaCheckCircle, FaInfoCircle, FaExclamationCircle } from "react-icons/fa";
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage'
import { RegistrationProcess } from '../RegistrationProcess';
import { toggleLoginRegisterModal } from '../LoginForm/store/LoginForm';
import { onReceiveMessage, clearOnReceiveMessage } from 'prembid-shared-library-npm/signalr';
import { AuctionTypeEnum, MessageSubject } from 'prembid-shared-library-npm/types';
import useParticipateStatus from 'prembid-shared-library-npm/hooks/useParticipateStatus';

const excludedStatusses = [RegisteredUserStatusEnum.RegistrationStarted, RegisteredUserStatusEnum.Declined, RegisteredUserStatusEnum.NotRegistered];

interface Props {
    auctionId: string,
    profileId: string,
    loginSuccess: boolean,
    logoutSuccess: boolean,
    settings: any,
    toggleLoginRegisterModal: () => void,
    auctionType: number | string | undefined | null,
}

const ParticipateButton: React.FC<Props> = props => {
    const { classes } = useStyles();

    const {
        auctionId,
        profileId,
        logoutSuccess,
        settings,
        toggleLoginRegisterModal,
        auctionType,
    } = props;

    let typeString = 'Bid'
    if (auctionType === AuctionTypeEnum.Tender || auctionType === AuctionTypeEnum.Tender.toString()) typeString = 'Tender';
    const buttonText = 'Register to ' + typeString;

    const { participateStatus, invalidateParticipateStatus, loading } = useParticipateStatus({
        profileId,
        auctionId
    });

    const [skipProfileSelect, setSkipProfileSelect] = React.useState(false);
    const [auctionWizardModal, setAuctionWizardModal] = React.useState(false);
    const [participate, setParticipate] = React.useState<any>({
        showParticipate: true,
        status: RegisteredUserStatusEnum.NotRegistered,
        reason: ''
    });
    const [complianceStatus, setComplianceStatus] = React.useState<any>();

    const onClick = (skipProfileSelect: boolean = false) => {
        if (!PrembidLocalStorage.currentUser) {
            toggleLoginRegisterModal();
        }
        else {
            setAuctionWizardModal(!auctionWizardModal);
        }
        setSkipProfileSelect(skipProfileSelect);
    }
    function StatusBadge() {
        if (participate.showParticipate === false) {
            if (participate.status === RegisteredUserStatusEnum.Approved) {
                return <div className="text-center">
                    <IconButton>
                        <FaCheckCircle className="text-success" />
                    </IconButton>
                    <span className="font-size-sm text-success">
                        {RegisteredUserStatusEnum[participate.status]} to {typeString}
                    </span>
                </div>

            } else if (participate.status === RegisteredUserStatusEnum.Awaiting) {
                return <div className="text-center">
                    <IconButton>
                        <FaInfoCircle className="text-dark" />
                    </IconButton>
                    <span className="font-size-sm text-dark">
                        {RegisteredUserStatusEnum[participate.status]} Approval
                    </span>
                </div>
            } else if (participate.status === RegisteredUserStatusEnum.Declined) {
                return <Tooltip arrow title={participate.reason} placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                    <div className="text-center">
                        <IconButton>
                            <FaExclamationCircle className="text-danger" />
                        </IconButton>
                        <span className="font-size-sm text-danger">
                            {RegisteredUserStatusEnum[participate.status]}
                        </span>
                    </div>
                </Tooltip>
            }
        }
        return <React.Fragment />
    }

    const renderComplianceStatus = (buttonText: string, status: number, complianceStatus: string | undefined) => {
        if (buttonText === 'Continue Registration' || excludedStatusses.includes(status) || !complianceStatus) return <></>

        switch (complianceStatus) {
            case 'Missing Documents': return <Button onClick={() => onClick(true)} variant="contained" fullWidth className={classes.buttonContainer + " btn-danger p-3 text-white mr-1"} style={{ textTransform: 'none' }}>
                Upload {complianceStatus}
            </Button>
            default: return <></>
        }
    }

    useEffect(() => {
        const callbackId = onReceiveMessage((message: string) => {
            if (message) {
                var payload = JSON.parse(message);

                if (payload?.data) {
                    const messageSubject = payload.data['messageSubject'];
                    const messageAuctionId = payload.data['auctionId'];
                    const profileId = payload.data['profileId'];

                    if (messageSubject === MessageSubject.UpdateRegisteredProfile && messageAuctionId === auctionId && profileId === PrembidLocalStorage.currentProfile.toString()) {
                        invalidateParticipateStatus();
                    }
                }
            }
        });

        return () => clearOnReceiveMessage(callbackId);
    }, [auctionId, invalidateParticipateStatus])

    useEffect(() => {
        if (participateStatus) {
            setComplianceStatus(participateStatus.complianceStatus)
            if (participateStatus.status === RegisteredUserStatusEnum.RegistrationStarted) {
                setParticipate(previous => ({ ...previous, showParticipate: true, buttonText: 'Continue Registration' }));
            }
            else if (participateStatus.status !== RegisteredUserStatusEnum.NotRegistered) {
                setParticipate(previous => ({ ...previous, showParticipate: false, status: participateStatus.status, reason: participateStatus.reason, buttonText: undefined }));
            }
            else {
                setParticipate(previous => ({ ...previous, showParticipate: true, buttonText: undefined }));
            }
        }
    }, [participateStatus])

    useEffect(() => {
        if (logoutSuccess) {
            setParticipate({
                showParticipate: true,
                status: RegisteredUserStatusEnum.NotRegistered,
                reason: ''
            });
            setComplianceStatus(undefined)
        }
    }, [logoutSuccess])

    if (auctionType === undefined || auctionType === null || auctionType === AuctionTypeEnum.Streamed || auctionType === AuctionTypeEnum.Streamed.toString()) return null;

    return (
        <React.Fragment>
            {auctionWizardModal && <RegistrationProcess
                open={auctionWizardModal}
                auctionId={auctionId}
                defaultProfileId={profileId}
                onClose={() => {
                    setAuctionWizardModal(false)
                    invalidateParticipateStatus();
                }}
                skipProfileSelect={skipProfileSelect || participate?.buttonText === 'Continue Registration'}
            />}

            {loading ? (
                <div className='d-flex flex-direction-column align-items-center justify-content-center'>
                    <CircularProgress
                        className="text-center"
                        style={{
                            margin: "auto",
                            color: settings?.Styles?.OverrideLoadingSpinnerColor
                                ? settings?.Styles?.OverrideLoadingSpinnerColor
                                : settings?.Styles?.Primary,
                        }}
                        size={40}
                    />
                </div>
            ) : (
                <div>
                    {
                        participate.showParticipate &&
                        <Button onClick={() => onClick()} variant="contained" fullWidth className={classes.buttonContainer + " btn-primary p-3 text-white mr-1"} style={{ textTransform: 'none' }}>
                            {participate.buttonText ?? buttonText}
                        </Button>
                    }
                    <StatusBadge />
                </div>
            )

            }
            {renderComplianceStatus(participate.buttonText, participate.status, complianceStatus)}
        </React.Fragment>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleRegisteredUserCreate_d: (auctionId: string, profileId: string, onCompletedCallback?: (result: any) => void) => dispatch(handleRegisteredUserCreate(auctionId, profileId, onCompletedCallback)),
        handleRegistrationWizardModalDataSubmission_d: (submitData: any, onCompletedCallback?: (result: any) => void) => dispatch(handleRegistrationWizardModalDataSubmission(submitData, onCompletedCallback)),
        clearWizardResponses_d: () => dispatch(clearWizardResponses()),
        toggleLoginRegisterModal: () => dispatch(toggleLoginRegisterModal()),
    }
}

const mapStateToProps = (state: any) => ({
    id: state.participateButton.id,
    profileId: state.loginForm.changeProfileId,
    loginSuccess: state.loginForm.loginSuccess,
    logoutSuccess: state.loginForm.logoutSuccess,
    wizardSuccess: state.participateButton.wizardSuccess,
    wizardError: state.participateButton.wizardError,
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(memo(ParticipateButton));