import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/Store'
import { AiOutlineWifi } from "react-icons/ai";
import { Tooltip } from '@mui/material';
import { ConnectionStatus } from 'prembid-shared-library-npm/types';
import { useConnectionStatusIndicatorStyles } from './ConnectionStatusIndicator.styles';
import SnackBar from './sub-components/SnackBar';

type ConnectionStatusIndicatorProps = {
    className?: string,
    showText?: boolean,
    style?: React.CSSProperties
}

export const ConnectionStatusIndicator = (props: ConnectionStatusIndicatorProps) => {
    const { classes: { mainContainer, connectorText } } = useConnectionStatusIndicatorStyles();
    const { connectionStatus } = useSelector((state: RootState) => state.signalR);
    const { className, showText = false, style } = props;

    const determineText = (connectionStatus: ConnectionStatus) => {
        switch (connectionStatus) {
            case ConnectionStatus.ReConnecting: return "You have lost connection to our 'Live Update' servers. We will try and automatically reconnect for you. Once the indicator turns green, reload the screen data";
            case ConnectionStatus.Connecting: return "Connecting to 'Live Update' servers";
            case ConnectionStatus.Connected: return "Connected";
            case ConnectionStatus.Error: return "Something went wrong connecting to our 'Live Update' servers. Please refresh your browser and if the problem persists, contact support";
            default: return ConnectionStatus.Disconnected;
        }
    }

    const determineColor = (connectionStatus: ConnectionStatus, snackbarColour: boolean) => {
        switch (connectionStatus) {
            case ConnectionStatus.ReConnecting: return snackbarColour ? "bg-warning" : "orange";
            case ConnectionStatus.Connecting: return snackbarColour ? "bg-info" : "lightblue";
            case ConnectionStatus.Connected: return snackbarColour ? "bg-success" : "green";
            case ConnectionStatus.Error: return snackbarColour ? "bg-danger" : "red";
            default: return snackbarColour ? "bg-gray-800" : "gray";
        }
    }

    const determineTooltip = (connectionStatus: ConnectionStatus) => {
        switch (connectionStatus) {
            case ConnectionStatus.ReConnecting: return "Re-Connecting";
            case ConnectionStatus.Connecting: return "Connecting";
            case ConnectionStatus.Connected: return "Connected";
            case ConnectionStatus.Error: return "Error";
            default: return ConnectionStatus.Disconnected;
        }
    }

    return (<div className={className} style={style}>
        <div className={mainContainer}>
            {showText && <div className={connectorText}>{connectionStatus}</div>}
            <Tooltip title={determineTooltip(connectionStatus)} PopperProps={{ style: { zIndex: 0 } }}>
                <div>
                    <AiOutlineWifi color={determineColor(connectionStatus, false)} size={24} />
                </div>
            </Tooltip>

            {
                connectionStatus != ConnectionStatus.Connected && connectionStatus != ConnectionStatus.Connecting && connectionStatus != ConnectionStatus.TimedOut &&
                <SnackBar title={determineText(connectionStatus)} showSnackbar={connectionStatus} snackBarColour={determineColor(connectionStatus, true)} />
            }
        </div>
    </div>)
}

export default ConnectionStatusIndicator