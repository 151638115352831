import { PrembidLocalStorage } from './PrembidLocalStorage';
import { phoneRegex } from './Regex';
import { format as fnsFormat } from 'date-fns';
import { isString } from 'lodash';

// RFC822 email validation
export const isValidEmail = (emailAddress, noneIsValid = false) => {
    if (noneIsValid && (!emailAddress || emailAddress.trim() === '')) return true;
    const sQtext = '[^\\x0d\\x22\\x5c\\x80-\\xff]';
    const sDtext = '[^\\x0d\\x5b-\\x5d\\x80-\\xff]';
    const sAtom =
        '[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+';
    const sQuotedPair = '\\x5c[\\x00-\\x7f]';
    const sDomainLiteral = `\\x5b(${sDtext}|${sQuotedPair})*\\x5d`;
    const sQuotedString = `\\x22(${sQtext}|${sQuotedPair})*\\x22`;
    const sDomainRef = sAtom;
    const sSubDomain = `(${sDomainRef}|${sDomainLiteral})`;
    const sWord = `(${sAtom}|${sQuotedString})`;
    const sDomain = `${sSubDomain}(\\x2e${sSubDomain})*`;
    const sLocalPart = `${sWord}(\\x2e${sWord})*`;
    const sAddrSpec = `${sLocalPart}\\x40${sDomain}`; // complete RFC822 email address spec
    const sValidEmail = `^${sAddrSpec}$`; // as whole string
    const reValidEmail = new RegExp(sValidEmail);
    return reValidEmail.test(emailAddress);
};

export const isValidPhoneNumber = (phoneNumber, noneIsValid = false) => {
    if (noneIsValid && (!phoneNumber || phoneNumber.trim() === '')) return true;
    return phoneRegex.test(phoneNumber);
}

export const getSTCStatus = (paramStcStatus) => {
    if (paramStcStatus?.toString() === "0") {
        return "STC No";
    } else if (paramStcStatus?.toString() === "1") {
        return "STC Yes";
    } else if (paramStcStatus?.toString() === "2") {
        return "STC Awaiting";
    }
};

export const getHighestBidColour = (profileId) => {
    return PrembidLocalStorage.currentUser ? (PrembidLocalStorage.currentProfile === profileId ? "#1bc943" : "#FF0000") : "text-primary"
};

export const getHighestBidSuffix = (profileId) => {
    return PrembidLocalStorage.currentUser ? (PrembidLocalStorage.currentProfile === profileId ? "(Winning)" : "(Losing)") : ""
};

export function replaceUrlParams(params: { [key: string]: string }) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    Object.keys(params).forEach((key) => {
      searchParams.set(key, params[key]);
    });
    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParams.toString();
    window.history.pushState({ path: newurl }, "", newurl);
  }
}

export function insertUrlParam(key, value) {
    if (window.history.pushState) {
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.set(key, value);
        let newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + searchParams.toString();
        window.history.pushState({ path: newurl }, '', newurl);
    }
}

// to remove the specific key
export function removeUrlParameter(paramKey) {
    const url = window.location.href
    var r = new URL(url)
    r.searchParams.delete(paramKey)
    const newUrl = r.href
    window.history.pushState({ path: newUrl }, '', newUrl)
}

export function formatDateTime(date: Date | string, format: string) {
    if (date === null || date === undefined) return "";
    if (isString(date)) return fnsFormat(new Date(date), format);
    else return fnsFormat(date as Date, format)
}