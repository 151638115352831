import { useCallback, useEffect, useRef } from "react";
import { singletonHook } from "react-singleton-hook";

import { useConfirmationDialogActivatorStore } from "@/store/ConfirmationDialogActivatorStore";
import { ConfirmationDialogActivatorPayload } from "@/store/ConfirmationDialogActivatorStore/ConfirmationDialogActivator.types";

const useConfirmationDialogActivatorImpl = () => {
    const { okPressed, cancelPressed, update } = useConfirmationDialogActivatorStore();

    const onOkPressCallback = useRef<(() => void) | null>(null);
    const onCancelPressCallback = useRef<(() => void) | null>(null);

    const showConfirmationDialog = useCallback(
        (settings: ConfirmationDialogActivatorPayload, { onOkPress, onCancelPress }: { onOkPress: () => void, onCancelPress?: () => void }) => {
            update({ ...settings, open: true });
            onOkPressCallback.current = onOkPress;
            if(onCancelPress) onCancelPressCallback.current = onCancelPress;
        },
        [update]
    );

    useEffect(() => {
        if (okPressed && onOkPressCallback.current) {
            onOkPressCallback.current();
        }
    }, [okPressed]);

    useEffect(() => {
        if (cancelPressed && onCancelPressCallback.current) {
            onCancelPressCallback.current();
        }
    }, [cancelPressed]);

    return {
        showConfirmationDialog,
    };
};

export const useConfirmationDialogActivator = singletonHook(null, useConfirmationDialogActivatorImpl);
