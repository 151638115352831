import React from "react";
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types'

const CurrencyInput: React.FC = (props: any) => {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        id: props.id,
                        value: values.value
                    },
                });
            }}
            valueIsNumericString={true}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator="."
            thousandSeparator=","
        />
    );
};

export default CurrencyInput;

CurrencyInput.propTypes = {
    inputRef: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};