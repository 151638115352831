import React from 'react'
import DeleteAccountConfirmationCard from '@/components/DeleteAccountConfirmation/DeleteAccountConfirmationCard';

const DeleteAccountConfirmation: React.FC = () => {

    return (
        <DeleteAccountConfirmationCard/>
    )
}

export default DeleteAccountConfirmation;