import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    container: {
        zIndex: 10,
        position: 'absolute',
        top: '10%',
    },
    appDownloadImg: {
        
        cursor: 'pointer',
        margin: '5px'
    },
    mobileContainer: {
        zIndex: 10,
        position: 'absolute',
        top: '60%',
    },
}))

export default useStyles;