import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { handleChangeProfile } from '@/components/LoginForm/store/LoginForm';
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage';

//Action
const ERROR = 'ERROR';
const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
const CLEAR_COMPANY_PROFILE = 'CLEAR_COMPANY_PROFILE';
const CREATE_COMPANY_PROFILE_SUCCESS = 'CREATE_COMPANY_PROFILE_SUCCESS';
const RETRIEVE_COMPANY_PROFILE_SUCCESS = 'RETRIEVE_COMPANY_PROFILE_SUCCESS';
const UPDATE_COMPANY_PROFILE_SUCCESS = 'UPDATE_COMPANY_PROFILE_SUCCESS';
//GL: Not implemented
//const DELETE_COMPANY_PROFILE_SUCCESS = 'DELETE_COMPANY_PROFILE_SUCCESS';

const initialState = {
    error: false,
    errorText: '',
    reload: false,
    success: false,
    editSuccess: false,
    profileId: undefined,
    profile: undefined,
    testProfileData: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_API_RESPONSES: return { ...state, error: false, errorText: '', success: false }
        case CLEAR_COMPANY_PROFILE: return { ...state, profileId: undefined, profile: undefined, testProfileData: undefined }
        case CREATE_COMPANY_PROFILE_SUCCESS: return { ...state, error: false, errorText: '', profileId: action.payload, reload: !state.reload, success: true }
        case UPDATE_COMPANY_PROFILE_SUCCESS: return { ...state, error: false, errorText: '', success: true }
        case RETRIEVE_COMPANY_PROFILE_SUCCESS: return { ...state, error: false, errorText: '', profile: action.payload }
        default: return state
    }
}

//Action creator
export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const clearCompanyProfile = () => {
    return { type: CLEAR_COMPANY_PROFILE, payload: CLEAR_COMPANY_PROFILE }
}

export const createCompanyProfileSuccess = (id: any) => {
    return { type: CREATE_COMPANY_PROFILE_SUCCESS, payload: id }
}

export const updateCompanyProfileSuccess = () => {
    return { type: UPDATE_COMPANY_PROFILE_SUCCESS, payload: UPDATE_COMPANY_PROFILE_SUCCESS }
}

export const retrieveCompanyProfileSuccess = (profileData: any) => {
    return { type: RETRIEVE_COMPANY_PROFILE_SUCCESS, payload: profileData }
}

export const handleCompanyProfileCreate = (formFields: any) => {
    return function (dispatch: any) {

        const companyProfileData = {
            CompanyName: formFields.companyName,
            TradingName: formFields.tradingName,
            RegistrationNumber: formFields.registrationNumber,
            VatNumber: formFields.vatNumber,
            ContactPersonFirstName: formFields.contactPersonFirstName,
            ContactPersonLastName: formFields.contactPersonLastName,
            Email: formFields.email.replace(" ", ""),
            PhoneNumber: formFields.phoneNumber,
            Address: formFields.address,
            DataCapturedDictionary: {},
            IsDefaultProfile: formFields.isDefaultProfile,
        }

        return requestClient.post(apiPaths.profile.companyBiddingProfileCreate, companyProfileData)
            .then(function (res) {
                if (res.success) {
                    const profileId = res.response;
                    dispatch(createCompanyProfileSuccess(profileId))
                    if (formFields.isDefaultProfile) {
                        dispatch(handleChangeProfile(profileId));
                    }
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleCompanyProfileUpdate = (formFields: any) => {
    return function (dispatch: any) {

        const companyProfileData = {
            Id: formFields.id,
            CompanyName: formFields.companyName,
            TradingName: formFields.tradingName,
            RegistrationNumber: formFields.registrationNumber,
            VatNumber: formFields.vatNumber,
            ContactPersonFirstName: formFields.contactPersonFirstName,
            ContactPersonLastName: formFields.contactPersonLastName,
            Email: formFields.email.replace(" ", ""),
            PhoneNumber: formFields.phoneNumber,
            Address: formFields.address,
            DataCapturedDictionary: {},
            IsDefaultProfile: formFields.isDefaultProfile,
        }

        return requestClient.post(apiPaths.profile.companyBiddingProfileUpdate, companyProfileData)
            .then(function (res) {
                if (res.success) {
                    dispatch(updateCompanyProfileSuccess())

                    if (formFields.isDefaultProfile) {
                        dispatch(handleChangeProfile(formFields.id));
                    }
                } else {
                    dispatch(error(res.error))
                }
            })
    }
}

export const handleCompanyProfileFetch = (id: any) => {

    const companyProfileData = {
        profileId: id
    }

    return (dispatch: any) => {
        return requestClient.get(apiPaths.profile.retrieveCompanyBiddingProfile, null, companyProfileData)
            .then(function (resp) {
                if (resp.success) {
                    const jsonData = resp.response;
                    dispatch(retrieveCompanyProfileSuccess(jsonData[0]))
                } else {
                    dispatch(error(resp.error))
                }
            })
    }

}