import {
    Button,
    CircularProgress,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { forEach, sortBy } from "lodash";
import * as React from "react";
import { useEffect, useReducer, useState } from "react";
import { connect } from "react-redux";
import FileUploader from "../../FileUploader/FileUploader";
import {
    retrieveBankDetailsByAuctionId,
    retrieveRegistrationDocuments,
    retrieveRegistrationProcessDocuments,
    updateState,
    uploadRegistrationProcessDocuments,
} from "../store/RegistrationModal";
import FooterButtons from "./FooterButtons";
import ns from "@/helpers/NotificationService";
import { AiOutlineBank } from "react-icons/ai";
import CurrencyComponent from "../../CurrencyComponent/CurrencyComponent";
import CurrencyLabel from "../../CurrencyComponent/CurrencyLabel";
import { FaFileDownload } from "react-icons/fa";
import { downloadFile } from "@/pages/Documents/store/Documents";
import LoadingButton from "../../LoadingButton";

interface DocumentUploadsProps {
    onClose: () => void;
    onNextStep: () => void;
    onBackStep: () => void;
    settings: any;
    state: any;
    updateState: (data: any) => void;
    retrieveRegistrationProcessDocuments: (
        profileId: string,
        auctionId: string,
        onCompletedCallback?: (res: any) => void
    ) => void;
    retrieveRegistrationDocuments: (
        auctionId: string,
        onCompletedCallback?: (res: any) => void
    ) => void;
    uploadRegistrationProcessDocuments: (
        data: any,
        onCompletedCallback?: (res: any) => void
    ) => void;
    retrieveBankDetails: (
        auctionId: string,
        onCompletedCallback?: (res: any) => void
    ) => void;
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
}

export const DocumentUploads: React.FC<DocumentUploadsProps> = (props) => {
    const {
        onClose,
        onNextStep,
        onBackStep,
        state,
        updateState,
        settings,
        retrieveRegistrationProcessDocuments,
        retrieveRegistrationDocuments,
        uploadRegistrationProcessDocuments,
        retrieveBankDetails,
        downloadFile_d
    } = props;

    const [downloading, updateDownloading] = useReducer((state, action) => {
        if (action.type === 'add') {
            return [...state, action.id]
        } else if (action.type === 'remove') {
            return state.filter((id) => id !== action.id)
        }

        return state;
    }, [])


    const [loading, setLoading] = useState<boolean>(true);
    const [loadingBankDetails, setLoadingBankDetails] = useState<boolean>(true);
    const [showBankDetails, setShowBankDetails] = useState<boolean>(true);
    const [uploadingDocuments, setUploadingDocuments] = useState<boolean>(false);
    const [uploadedFileIds, setUploadedFileIds] = useState<string[]>([]);

    const [profileDocuments, setProfileDocuments] = useState<any[]>([]);
    const [auctionDocuments, setAuctionDocuments] = useState<any[]>([]);

    const [bankDetails, setBankDetails] = useState<any>(undefined);

    const DEFAULT_MAX_FILE_SIZE = 10485760; // 10MB
    const DEFAULT_ACCEPTED_FILE_TYPES = "image/jpeg,image/png,application/pdf";

    const ERROR_NO_FILE_SELECTED = "No file selected";
    const ERROR_INVALID_FILE_TYPE = "Invalid file type";
    const ERROR_FILE_TOO_LARGE = (size) =>
        `File is too large. Maximum size is ${size / 1024 / 1024}MB`;

    const validateFile = (file) => {
        if (!file) {
            ns.error("Error", ERROR_NO_FILE_SELECTED);
            return false;
        }

        if (!DEFAULT_ACCEPTED_FILE_TYPES.includes(file.type)) {
            ns.error("Error", ERROR_INVALID_FILE_TYPE);
            return false;
        }

        if (file.size > DEFAULT_MAX_FILE_SIZE) {
            ns.error("Error", ERROR_FILE_TOO_LARGE(DEFAULT_MAX_FILE_SIZE));
            return false;
        }

        return true;
    };

    useEffect(() => {
        retrieveBankDetails(state.auctionId, (res) => {
            if (res.success) {
                if (!res.response) setShowBankDetails(false);
                else {
                    setBankDetails(res.response);
                }
            } else setShowBankDetails(false);
            setLoadingBankDetails(false);
        });
        retrieveRegistrationProcessDocuments(
            state.selectedProfileId,
            state.auctionId,
            () => {
                retrieveRegistrationDocuments(state.auctionId, () => {
                    setLoading(false);
                });
            }
        );
    }, []);

    useEffect(() => {
        if (!loading && state.registrationProcessDocuments) {
            const profileDocs = state.registrationProcessDocuments.filter(
                (x) =>
                    (x.type === "Individual Profile Document" ||
                        x.type === "Company Profile Document") &&
                    x.status === "Awaiting Upload"
            );
            const docs = state.registrationProcessDocuments.filter(
                (x) =>
                    x.documentUrl &&
                    x.lotId === null &&
                    x.documentTemplate.dataCaptured.auction.isRegistrationDocument &&
                    x.status === "Awaiting Upload"
            );
            const auctionDocs = state.registrationProcessDocuments.filter(
                (x) =>
                    x.type === "Auction Uploaded Document" &&
                    x.status === "Awaiting Upload"
            );

            if (
                profileDocs.length === 0 &&
                docs.length === 0 &&
                auctionDocs.length === 0
            )
                onNextStep();
        }
    }, [loading, state.registrationProcessDocuments]);

    const handleOnClose = () => {
        //Custom logic
        onClose();
    };

    const handleBack = () => {
        //Custom logic
        onBackStep();
    };

    const handleSubmit = () => {
        setUploadingDocuments(true);
        const payload = {
            profileId: state.selectedProfileId,
            auctionId: state.auctionId,
            profileDocuments: profileDocuments ?? [],
            registrationProcessDocuments: auctionDocuments ?? [],
        };

        uploadRegistrationProcessDocuments(payload, (res) => {
            setUploadingDocuments(false);
            if (res.success) {
                onNextStep();
            } else {
                ns.error("", "Failed to upload a document.");
            }
        });
    };

    /* const handleProfileFileUpload = (item, fileInfo) => {
      let existingDocIndex = profileDocuments.findIndex((x) => x.id === item.id);
  
      let tempDoc: any = {
        id: item.id,
        documentTemplateId: item.documentTemplate.id,
        documentBase64String: fileInfo ? fileInfo.base64 : "",
      };
  
      let tempList = [...profileDocuments];
  
      if (existingDocIndex > -1) {
        tempList.splice(existingDocIndex, 1, tempDoc);
      } else tempList.push(tempDoc);
  
      setProfileDocuments(tempList);
    }; */
    const handleProfileFileUpload = (item, fileInfo) => {
        let existingDocIndex = profileDocuments.findIndex((x) => x.id === item.id);

        let tempDoc: any = {
            id: item.id,
            documentTemplateId: item.documentTemplate.id,
            documentBase64String: fileInfo ? fileInfo.base64 : "",
        };

        let tempList = [...profileDocuments];

        if (existingDocIndex > -1) {
            tempList.splice(existingDocIndex, 1, tempDoc);
        } else tempList.push(tempDoc);

        setProfileDocuments(tempList);

        let tempProfileDocs = state.registrationProcessDocuments.map((doc) => {
            if (doc.id === item.id) {
                return { ...doc, documentBase64String: tempDoc.documentBase64String };
            } else {
                return doc;
            }
        });

        updateState({ ...state, registrationProcessDocuments: tempProfileDocs });

        setUploadedFileIds((prevIds) => [...prevIds, item.id]);
    };

    const handleViewDocument = (url: string, onCompletedCallback?: () => void) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
            onCompletedCallback?.();
        })
    }


    const renderProfileDocumentsSection = (documents: any[]) => {
        let profileDocs = documents.filter(
            (x) =>
                (x.type === "Individual Profile Document" ||
                    x.type === "Company Profile Document") &&
                x.status === "Awaiting Upload"
        );

        if (profileDocs.length === 0) return <></>;

        profileDocs = sortBy(profileDocs, "documentTemplate.name");

        return (
            <>
                <p style={{ marginBottom: 0 }}>
                    <b>Profile Documents</b>
                </p>
                <div className="table-responsive-md mb-4">
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {profileDocs.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell hidden component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell hidden component="th" scope="row">
                                            {row.documentTemplateId}
                                        </TableCell>
                                        <TableCell scope="row" width="80%">
                                            {row.documentTemplate.name}
                                        </TableCell>
                                        <TableCell scope="row" width="20%">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {row.documentUrl && row.documentUrl.length > 0 && (
                                                    downloading.includes(row.id) ? <CircularProgress size={20} style={{ marginRight: 20 }} className="text-primary text-center center" /> :
                                                        <a
                                                            className="text-info"
                                                            href={undefined}
                                                            onClick={() => {
                                                                updateDownloading({ type: 'add', id: row.id })
                                                                setTimeout(() => handleViewDocument(row.documentUrl, () => {
                                                                    updateDownloading({ type: 'remove', id: row.id })
                                                                }), 50)
                                                            }}
                                                            style={{
                                                                textDecoration: 'underline', cursor: 'pointer', marginRight: 10
                                                            }}
                                                        >View</a>
                                                )}
                                                {/* <FileUploader
                          uploadedFile={row.documentBase64String}
                          buttonFileUploadedText={"Attached"}
                          acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                          onFileUpload={(file) =>
                            handleProfileFileUpload(row, file)
                          }
                          maxImageSize={10240}
                          maxFileSize={10240}
                          buttonSize={120}
                          buttonText={"Upload"}
                        /> */}

                                                <FileUploader
                                                    uploadedFile={row.documentBase64String}
                                                    buttonFileUploadedText={"Attached"}
                                                    acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                                                    onFileUpload={(file) => {
                                                        handleProfileFileUpload(row, file);
                                                    }}
                                                    maxImageSize={10240}
                                                    maxFileSize={10240}
                                                    buttonSize={120}
                                                    buttonText={"Upload"}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    };

    const renderAuctionRegistrationDocumentSection = (
        registrationDocuments: any[],
        registrationProcessDocuments: any[]
    ) => {
        let docs = registrationDocuments.filter(
            (x) =>
                x.documentUrl &&
                x.lotId === null &&
                x.documentTemplate.dataCaptured.auction.isRegistrationDocument
        );
        let auctionDocs = registrationProcessDocuments.filter(
            (x) =>
                x.type === "Auction Uploaded Document" &&
                x.status !== "Document Invalidated"
        );

        if (docs.length === 0 && auctionDocs.length === 0) return <></>;

        docs = sortBy(docs, "documentTemplate.name");
        auctionDocs = sortBy(auctionDocs, "documentTemplate.name");

        return (
            <>
                <p style={{ marginBottom: 0, marginTop: 10 }}>
                    <b>Auction Documents</b>
                </p>
                {docs?.length > 0 && (
                    <>
                        <p style={{ marginBottom: 0, marginTop: 10 }}>
                            Please download and complete the forms. Upload the completed forms
                            in the next section:
                        </p>
                        <div className="table-responsive-md mb-4">
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {docs.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell hidden component="th" scope="row">
                                                    {row.id}
                                                </TableCell>
                                                <TableCell hidden component="th" scope="row">
                                                    {row.documentTemplateId}
                                                </TableCell>
                                                <TableCell scope="row" width="80%">
                                                    {row.documentTemplate.name}
                                                </TableCell>
                                                <TableCell scope="row" width="20%">
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            justifyContent: "flex-end",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <LoadingButton
                                                            loading={downloading.includes(row.id)}
                                                            onClick={() => {
                                                                updateDownloading({ type: 'add', id: row.id })
                                                                setTimeout(() => handleViewDocument(row.documentUrl, () => {
                                                                    updateDownloading({ type: 'remove', id: row.id })
                                                                }), 50)
                                                            }}
                                                            type='secondary'
                                                            icon={<FaFileDownload />}
                                                            label="Download"
                                                        />
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                )}
                {renderAuctionDocumentsSection(auctionDocs)}
            </>
        );
    };

    /* const handleAuctionUploadFileUpload = (item, file) => {
          let existingDocIndex = auctionDocuments.findIndex((x) => x.id === item.id);
  
          let tempDoc: any = {
              id: item.id,
              documentTemplateId: item.documentTemplate.id,
              documentBase64String: file ? file.base64 : "",
          };
  
          let tempList = [...auctionDocuments];
  
          if (existingDocIndex > -1) {
              tempList.splice(existingDocIndex, 1, tempDoc);
          } else tempList.push(tempDoc);
  
          setAuctionDocuments(tempList);
      }; */

    const handleAuctionUploadFileUpload = (item, file) => {
        let existingDocIndex = auctionDocuments.findIndex((x) => x.id === item.id);

        let tempDoc: any = {
            id: item.id,
            documentTemplateId: item.documentTemplate.id,
            documentBase64String: file ? file.base64 : "",
        };

        let tempList = [...auctionDocuments];

        if (existingDocIndex > -1) {
            tempList.splice(existingDocIndex, 1, tempDoc);
        } else tempList.push(tempDoc);

        setAuctionDocuments(tempList);
    };

    const renderAuctionDocumentsSection = (documents: any[]) => {
        if (documents.length === 0) return <></>;

        return (
            <>
                <p style={{ marginBottom: 0, marginTop: 10 }}>
                    Please upload the following documents:
                </p>
                <div className="table-responsive-md mt-1 mb-4">
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                {documents.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell hidden component="th" scope="row">
                                            {row.id}
                                        </TableCell>
                                        <TableCell hidden component="th" scope="row">
                                            {row.documentTemplateId}
                                        </TableCell>
                                        <TableCell scope="row" width="80%">
                                            {row.documentTemplate.name}
                                        </TableCell>
                                        <TableCell scope="row" width="20%">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {row.documentUrl && row.documentUrl.length > 0 && (
                                                    downloading.includes(row.id) ? <CircularProgress size={20} style={{ marginRight: 20 }} className="text-primary text-center center" /> : <a
                                                        className="text-info"
                                                        href={undefined}
                                                        onClick={() => {
                                                            updateDownloading({ type: 'add', id: row.id })
                                                            setTimeout(() => handleViewDocument(row.documentUrl, () => {
                                                                updateDownloading({ type: 'remove', id: row.id })
                                                            }), 50)
                                                        }                                                        }
                                                        style={{
                                                            textDecoration: 'underline', cursor: 'pointer', marginRight: 10
                                                        }}
                                                    >View</a>
                                                )}
                                                {/* <FileUploader
                          uploadedFile={row.documentBase64String}
                          buttonFileUploadedText={"Attached"}
                          acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                          onFileUpload={(file) =>
                            handleAuctionUploadFileUpload(row, file)
                          }
                          maxImageSize={10240}
                          maxFileSize={10240}
                          buttonSize={120}
                          buttonText={"Upload"}
                        /> */}
                                                <FileUploader
                                                    uploadedFile={
                                                        auctionDocuments.find((doc) => doc.id === row.id)
                                                            ?.documentBase64String
                                                    }
                                                    buttonFileUploadedText={"Attached"}
                                                    acceptedFileExtensions={".jpeg, .jpg, .png, .pdf"}
                                                    onFileUpload={(file) =>
                                                        handleAuctionUploadFileUpload(row, file)
                                                    }
                                                    maxImageSize={10240}
                                                    maxFileSize={10240}
                                                    buttonSize={120}
                                                    buttonText={"Upload"}
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    };

    const renderItem = (label: string, value: string) => {
        if (!value) return <></>;
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div style={{ fontWeight: "bold" }}>{label}: </div>
                <div>{value}</div>
            </div>
        );
    };

    const handleViewBankDetails = () => {
        ns.showDialog({
            title: "Bank Details",
            content: (
                <div>
                    {renderItem("Bank Name", bankDetails?.bankDetails?.bankName)}
                    {renderItem(
                        "Account Number",
                        bankDetails?.bankDetails?.accountNumber
                    )}
                    {renderItem("Branch Code", bankDetails?.bankDetails?.branchCode)}
                    {renderItem("Branch Name", bankDetails?.bankDetails?.branchName)}
                    {renderItem("SWIFT Code", bankDetails?.bankDetails?.swiftCode)}
                </div>
            ),
            locales: {
                cancelText: "Close",
            },
            onCancel: () => { },
        });
    };

    const renderBankDetails = (showBankDetails) => {
        if (!showBankDetails || bankDetails?.depositAmount === 0) return <></>;

        return (
            <>
                <p style={{ marginBottom: 0 }}>
                    <b>Deposit Required</b>
                </p>
                <p style={{ marginBottom: 0, marginTop: 10 }}>
                    {"A deposit is required to register for this " +
                        settings.AuctionName +
                        ", please note this is fully refundable if you are an unsuccessful bidder"}
                </p>
                <div className="table-responsive-md mb-4">
                    <TableContainer>
                        <Table size="small">
                            <TableBody>
                                <TableRow>
                                    <TableCell scope="row" width="80%">
                                        <CurrencyLabel
                                            textColour={settings.Styles.Primary}
                                            value={bankDetails?.depositAmount}
                                        />
                                    </TableCell>
                                    <TableCell scope="row" width="20%">
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Button
                                                onClick={() => handleViewBankDetails()}
                                                variant="contained"
                                                className="btn-primary text-white m-1 text-capitalize shadow-none hover-scale-sm border-0 d-inline-flex align-items-center justify-content-center"
                                                style={{
                                                    width: 180,
                                                    padding: 11,
                                                    background: "#2b2b2b",
                                                }}
                                            >
                                                <span className="btn-wrapper--icon">
                                                    <AiOutlineBank />
                                                </span>
                                                <span className="btn-wrapper--label">
                                                    View Bank Details
                                                </span>
                                            </Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </>
        );
    };

    return (
        <div style={{ width: 500 }}>
            <h5 style={{ marginTop: 10 }}>Document Uploads</h5>
            <Divider style={{ marginBottom: 10 }} />
            {loading && (
                <>
                    <p>Loading documents...</p>
                    <div
                        className="d-flex flex-direction-column align-items-center justify-content-center"
                        style={{ paddingBottom: 20 }}
                    >
                        <CircularProgress
                            className="text-center"
                            style={{
                                margin: "auto",
                                color: settings?.Styles?.OverrideLoadingSpinnerColor
                                    ? settings?.Styles?.OverrideLoadingSpinnerColor
                                    : settings?.Styles?.Primary,
                            }}
                        />
                    </div>
                </>
            )}
            {!loading && (
                <>
                    <p>
                        Please upload the below documents if you have them on your device,
                        or take an image of them if you have them nearby.
                    </p>
                    <p style={{ color: "red" }}>
                        <b>NB!</b> You{" "}
                        <b>
                            <u>do not</u>
                        </b>{" "}
                        have to upload your documents now if you do not have them available.
                        Please proceed{" "}
                        <b>
                            <u>(CLICK NEXT)</u>
                        </b>{" "}
                        and you may upload your documents at a later stage.
                    </p>
                    {renderBankDetails(showBankDetails)}
                    {renderProfileDocumentsSection(state.registrationProcessDocuments)}
                    {renderAuctionRegistrationDocumentSection(
                        state.registrationDocuments,
                        state.registrationProcessDocuments
                    )}
                </>
            )}

            <FooterButtons
                onClose={handleOnClose}
                onSubmit={loading ? undefined : handleSubmit}
                loading={loading || uploadingDocuments}
            />
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    state: state.biddingRegistrationModal,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateState: (data: any) => dispatch(updateState(data)),
        retrieveRegistrationProcessDocuments: (
            profileId: string,
            auctionId: string,
            onCompletedCallback?: (res: any) => void
        ) =>
            dispatch(
                retrieveRegistrationProcessDocuments(
                    profileId,
                    auctionId,
                    onCompletedCallback
                )
            ),
        retrieveRegistrationDocuments: (
            auctionId: string,
            onCompletedCallback?: (res: any) => void
        ) =>
            dispatch(retrieveRegistrationDocuments(auctionId, onCompletedCallback)),
        uploadRegistrationProcessDocuments: (
            data: any,
            onCompletedCallback?: (res: any) => void
        ) =>
            dispatch(uploadRegistrationProcessDocuments(data, onCompletedCallback)),
        retrieveBankDetails: (
            auctionId: string,
            onCompletedCallback?: (res: any) => void
        ) =>
            dispatch(retrieveBankDetailsByAuctionId(auctionId, onCompletedCallback)),
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentUploads);
