import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import HeaderBlock from "@/components/HeaderBlock/HeaderBlock";
import AuctionDetailCard from "@/components/Cards/AuctionDetailCard";
import { Container, Grid } from "@mui/material";
import { handleAuctionDataFetch } from "./store/Auctions";
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { debounce } from 'lodash';
import { PrembidLocalStorage } from "../../helpers/PrembidLocalStorage";
import { handleRegisteredUsersValidate } from "../../components/Buttons/store/Participate";
import useStyles from '../Auctions/AuctionStyles';
import './../../../src/index.css'
import { useSignalRConnectionStatus } from "prembid-shared-library-npm/signalr";
interface Props {
    handleAuctionDataFetch_d: (onCompletedCallback?: (result: any) => void) => void,
    handleRegisteredUsersValidate_d: (auctionIds: string[], profileId: string) => void,
    auctionData: any,
    profileId: string,
    loading?: boolean,
    settings: any,
    location?: any,
}

const AuctionsListView: React.FC<Props> = (props) => {

    const {
        handleAuctionDataFetch_d,
        handleRegisteredUsersValidate_d,
        auctionData,
        settings,
        profileId,
        location,
        loading
    } = props;
    const [lastTop, setLastTop] = useState<any>(0);
    const {classes } = useStyles();

    useEffect(() => {
        if (!loading && lastTop) {
            window.scroll({ top: lastTop, left: 0, behavior: 'auto' })
            setLastTop(undefined)
        }
    }, [loading])

    useEffect(() => {
        showProgressSpinner({ description: "Retrieving " + settings.AuctionName + "s..." });

        const urlSearchParams: any = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());

        window.scroll({ top: 0, left: 0, behavior: 'auto' })
        setLastTop(params?.top ?? 0);

        handleAuctionDataFetch_d((res) => {
            hideProgressSpinner();
        });
    }, []);

    useEffect(() => {
        if (auctionData?.auctions && auctionData?.auctions?.length > 0) {
            let auctionIds = auctionData.auctions.map(x => x.id);
            handleRegisteredUsersValidate_d(auctionIds, PrembidLocalStorage.currentProfile.toString());
        }
    }, [auctionData, profileId]);

    const refreshAuctions = debounce(() => {
        handleAuctionDataFetch_d();
    }, 500);

    useEffect(() => {
        if (location?.state?.reloadAuctions) {
            refreshAuctions();
            location.state.reloadAuctions = false;
        }
    }, [location?.state?.reloadAuctions]);

    useSignalRConnectionStatus({
        onConnect: () => {
            refreshAuctions();
        },
    });

    return (<div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <div>
            <HeaderBlock imageSrc={settings.VendorBannerUrl} />

            <div className="pb-5 pt-5">
                <div className="text-center" >
                    <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }} >{settings.AuctionName + "s"}</h3>
                </div>
                <div className="text-center mb-5" >
                    <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }} >{"See what exciting " + settings.AuctionName + "s are open and upcoming!"}</h4>
                </div>

                {auctionData && <Container maxWidth={false} className={classes.containerWidth} >
                    {auctionData?.auctions === undefined || auctionData?.auction === null || auctionData?.auctions.length === 0 && (
                        <div className="text-center">
                            <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }} >{"No " + settings.AuctionName + "s to be displayed"}</h4>
                        </div>
                    )}

                    {auctionData?.auctions && auctionData?.auctions.length > 0 && (
                        <Grid container style={{ flexGrow: 1 }} spacing={3}>
                            {
                                auctionData?.auctions.map((auction) => {
                                    return (
                                        <AuctionDetailCard
                                            key={auction.id}
                                            imgSrc={auction?.imageUrl}
                                            header={auction?.name}
                                            description={auction?.description}
                                            auctionId={auction?.id}
                                            startDateTimeAt={auction?.startDateTimeAt}
                                            endDateTimeAt={auction?.endDateTimeAt}
                                            auctionEndDateTimeAt={auction?.auctionEndDateTimeAt}
                                            auctionType={auction?.auctionType}
                                            infoBadgeText={auction?.depositAmount.toString()}
                                            dangerBadgeText={`${auction?.numberOfLots} ${settings.LotName + "s"}`}
                                            hasExtensions={auction?.lotBidExtensionCount > 0 ? true : false}
                                            defaultCommission={auction?.defaultCommission > 0 ? Number(auction?.defaultCommission).toFixed(2) : '0.00'}
                                            onRefresh={() => refreshAuctions()}
                                            documentCount={PrembidLocalStorage?.currentProfile ? (auction?.publicDocumentCount + auction?.privateDocumentCount) : auction?.publicDocumentCount}
                                        />
                                    );
                                })
                            }
                        </Grid>
                    )}
                </Container>}

                
               
            </div>
        </div>
    </div>);
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleAuctionDataFetch_d: (onCompletedCallback?: (result: any) => void) => dispatch(handleAuctionDataFetch(onCompletedCallback)),
        handleRegisteredUsersValidate_d: (auctionIds: string[], profileId: string, onCompletedCallback?: (result: any) => void) => dispatch(handleRegisteredUsersValidate(auctionIds, profileId, onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    auctionData: state.auctions.auctionData,
    settings: state.settings.settings,
    profileId: state.loginForm.changeProfileId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionsListView);
