import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

const RETRIEVED_CONTACT_SUCCESS = 'RETRIEVED_CONTACT_SUCCESS';
const CLEAR_STATE = 'CLEAR_STATE';

export interface ContactsState {
    contact: any
}

const initialState: ContactsState = {
    contact: undefined
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case RETRIEVED_CONTACT_SUCCESS: return { ...state, contact: action.contact };
        case CLEAR_STATE: return { ...initialState };
        default: return state;
    }
}

export const retrievedContact = (contact: any) => {
    return { type: RETRIEVED_CONTACT_SUCCESS, contact }
}
export const clearContactsState = () => {
    return { type: CLEAR_STATE }
}

export const handleContactFetch = (contactId: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.contact.retrieveContact, { contactId }, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(retrievedContact(res.response))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}