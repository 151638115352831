import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    divSpacingHeaderBlock: {
        margin: theme.spacing(0, 0, 15, 0)
    },
    divSpacing: {
        margin: theme.spacing(15, 0)
    },
    containerWidth: {
        maxWidth: '1280px'
    }
}))

export default useStyles;