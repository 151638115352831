import { makeStyles } from "tss-react/mui";


const useStyles = makeStyles()((theme) => ({
    detailCard: {
        boxShadow: '0 0 2px #fff',
        marginLeft: 2,
        marginRight: 1
    },
}));

export default useStyles;