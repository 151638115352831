import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({

    bigAvatar: {
        width: '150px',
        height: '150px',
        margin: '0 auto 16px'
    },

    centerDiv:{
        textAlign: 'center'
    },

    mainDivPadding:{
        paddingLeft:'350px',
        paddingRight:'350px'
    },

    cardPadding:{
        paddingBottom: '40px',
        paddingTop: '40px'
    },

    divSpacing: {
        margin: theme.spacing(0, 0, 15, 0)
    },

    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginLeft: "11%"
      },

      containerSpacing:{
        maxWidth: 900,
        marginLeft: "auto",
        marginRight: "auto"
    },
    containerWidth: {
        maxWidth: '1280px'
    }

}));

export default useStyles;