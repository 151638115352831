import { Card, Grid } from '@mui/material';
import React, { useEffect } from 'react';
import { HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';
import { connect } from 'react-redux';
import { clearContactsState, handleContactFetch } from './store/Contacts';

interface Props {
    contactId: string,
    contact: any,
    handleContactFetch_d: (contactId: any, onCompletedCallback?: (res: any) => void) => void,
    clearContactsState_d: () => void,
    loading: boolean
}

const ContactCard: React.FC<Props> = props => {
    const {
        contactId,
        contact,
        handleContactFetch_d,
        clearContactsState_d,
        loading
    } = props;

    useEffect(() => {
        return (() => {
            clearContactsState_d();
        })
    }, [])

    useEffect(() => {
        if (contactId) {
            handleContactFetch_d(contactId);
        }
    }, [contactId])

    return contact ?
        <Card className="mb-5">
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                {
                    contact?.image ? (<>
                        <Grid item xs={4} className="d-flex flex-column align-items-center justify-center">
                            <img className="p-2" src={contact.image.url} style={{ height: '125px', width: '125px', objectFit: "cover", borderRadius: 100 }} />
                        </Grid>
                        <Grid item xs={8} className="p-1 d-flex flex-column align-items-flex-start justify-center" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            <h5 className="text-black mr-1">{contact.firstName + ' ' + contact.lastName}</h5>
                            <div className="divider w-80 mb-3 mr-1" />
                            <h6 className="mb-1 text-black-75" style={{ color: "#5a5a5a" }}><HiOutlinePhone className="mr-1" />{contact.phoneNumber}</h6>
                            <h6 className="mb-1 text-black-75" style={{ color: "#5a5a5a" }}><HiOutlineMail className="mr-1" />{contact.email}</h6>
                        </Grid>
                    </>) : (
                        <Grid item xs={12} className="p-3 d-flex flex-column align-items-flex-start justify-center" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                            <h5 className="text-black mr-1">{contact.firstName + ' ' + contact.lastName}</h5>
                            <div className="divider w-80 mb-3 mr-1" />
                            <h6 className="mb-1 text-black-75" style={{ color: "#5a5a5a" }}><HiOutlinePhone className="mr-1" />{contact.phoneNumber}</h6>
                            <h6 className="mb-1 text-black-75" style={{ color: "#5a5a5a" }}><HiOutlineMail className="mr-1" />{contact.email}</h6>
                        </Grid>
                    )
                }

            </Grid >
        </Card> : <></>
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleContactFetch_d: (contactId: string, onCompletedCallback?: (res: any) => void) => dispatch(handleContactFetch(contactId, onCompletedCallback)),
        clearContactsState_d: () => dispatch(clearContactsState())
    }
}

const mapStateToProps = (state: any) => ({
    contact: state.contacts.contact,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard);