import React, { useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Card, Container, Grid, Table } from '@mui/material';
import { useParams } from 'react-router-dom';
import ParticipateButton from "@/components/Buttons/ParticipateButton";
import { Countdown } from "@/components/library/Countdown";
import { CountdownRenderProps, zeroPad } from 'react-countdown';
import useStyles from "./BiddingStyles";
import CurrencyLabel from "@/components/CurrencyComponent/CurrencyLabel";
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage'
import { uniqueId } from 'lodash';
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import SolidFlagBanner from '@/components/Banners/SoldFlagBanner';
import ns from '@/helpers/NotificationService';
import { getNewDateWithOffset, } from "prembid-shared-library-npm/helpers";
import { RootState } from '../../store/Store';
import { BidButtonContainer } from './components/BidButtonContainer';
import { CustomBidButtonContainer } from './components/CustomBidButtonContainer';
import { MaxBidButtonContainer } from './components/MaxBidButtonContainer';
import { TenderBidButtonContainer } from './components/TenderBidButtonContainer';
import Loading from '../../components/Loading';
import { useBidding } from "prembid-shared-library-npm/hooks";
import { useSignalRConnectionStatus } from 'prembid-shared-library-npm/signalr';
interface Props {
    priceWithSymbol?: any,
    profileId: string,
    settings: any,
}

function PricingDisplayComponent(props) {
    if (props.guidePrice && props.guidePrice > 0) {
        if (!PrembidLocalStorage.currentUser?.userId) {
            return (
                <>
                    <Grid item xs={6}>
                        <h5 className=" text-black text-left">Guide Price</h5>
                    </Grid>
                    <Grid item xs={6}>
                        <h6 className="text-black-75 font-size-sm text-right" style={{ color: "#5a5a5a" }} ><CurrencyLabel value={props.guidePrice} /></h6>
                    </Grid>
                </>
            )
        } else {
            if (props.registrationStatus === 1) {
                return <></>
            } else {
                return (
                    <>
                        <Grid item xs={6}>
                            <h5 className=" text-black text-left">Guide Price</h5>
                        </Grid>
                        <Grid item xs={6}>
                            <h6 className="text-black-75 font-size-sm text-right" style={{ color: "#5a5a5a" }}><CurrencyLabel value={props.guidePrice} /></h6>
                        </Grid>
                    </>
                )
            }
        }
    } else {
        return <></>
    }
}

function ReserveMetInnerComponent({ reserveMet }) {
    if (reserveMet) {
        return <h6 className="font-size-sm text-right" style={{ color: "#1bc943" }}>{"Yes"}</h6>
    } else {
        return <h6 className="font-size-sm text-right" style={{ color: "#f83245" }}>{"No"}</h6>
    }
}

const CountdownRenderer = ({ days, hours, minutes, seconds, completed, countdownHeader }: CountdownRenderProps & { countdownHeader: string }) => {
    return (
        <div>
            <h5><span>{zeroPad(days)}</span><span style={{ fontSize: '10px' }}> dd:</span> <span> {zeroPad(hours)}</span><span style={{ fontSize: '10px' }}> hh:</span> <span> {zeroPad(minutes)}</span><span style={{ fontSize: '10px' }}> mm:</span> <span> {zeroPad(seconds)}</span><span style={{ fontSize: '10px' }}> ss</span></h5>
        </div>
    );
};

const CountdownTimerComponent = ({ currentDateTimeOffset, countDownTimer }: any) => (
    <Countdown
        now={() => getNewDateWithOffset(currentDateTimeOffset).getTime()}
        date={countDownTimer?.countdownDate}
        renderer={(props) => CountdownRenderer({ ...props, countdownHeader: countDownTimer?.countdownHeader })}
    />
);

const Bidding: React.FC<Props> = (props) => {
    const { classes } = useStyles();
    const { auctionId, lotId }: any = useParams();

    const { profileId, settings, } = props;

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const bidTableRef = React.useRef<HTMLTableElement>(null);

    const scrollToTop = useCallback(() => {
        bidTableRef.current?.scrollTo({
            top: 0
        });
    }, []);

    const {
        auction,
        lot,
        now,
        maximumBidAmount,
        tenderAmount,
        paddleNumber,
        registrationStatus,
        soldBanner,
        locked,
        startingPrice,
        validating,
        countDownTimer,
        topBidder,
        reserveMet,
        minimumIncrement,
        loading,
        refreshing,
        dataIsLoaded,
        finalizingText,
        lockedText,
        unlockedText,
        refresh,
        handleBidSubmittedSuccess,
        handleMaxBidSubmittedSuccess,
        handleTenderSubmittedSuccess,
        lotStatusFailureCount,
        error,
        loadingRegisteredProfile,
        end,
        start,
        tableRowData,
        isExtended
    } = useBidding({
        auctionId,
        lotId,
        profileId,
        currentDateTimeOffset,
        locales: {
            lotName: settings.LotName,
        },
        onScrollToTop: scrollToTop,
    });

    useEffect(() => {
        if (locked?.isLocked === false && unlockedText) ns.information(uniqueId(), unlockedText);
        else if (locked?.isLocked === true && lockedText) ns.information(uniqueId(), lockedText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locked]);

    useEffect(() => {
        if (error) ns.error(uniqueId(), error);
    }, [error]);

    const getSTCStatus = () => {
        if (lot?.stcStatus.toString() === "0") return "STC - No";
        else if (lot?.stcStatus.toString() === "1") return "STC - Yes";
        else if (lot?.stcStatus.toString() === "2") return "STC - Awaiting";
    };

    useEffect(() => {
        //TODO: This is a hack to prevent the screen from scrolling to the bottom - Need to investigate further and figure out why its doing that
        window.scrollTo(0, 0);
    }, [])

    useSignalRConnectionStatus({
        onConnect: () => {
            refresh();
        },
    });

    return (<div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <div className="mb-5 pt-5 pl-5 pr-5 pb-4 mt-5" />

        <Grid
            className="pt-5"
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={0}>
            <Grid item md={12}>
                {
                    lot &&
                    <>
                        <div className="text-center">
                            <h3 style={{
                                color: settings?.Styles?.OverridePageTextColor
                                    ? settings?.Styles?.OverridePageTextColor
                                    : "#000000",
                            }}>
                                <span
                                    className="text-black-75 font-weight-bold"
                                    style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#2b2b2b" }}
                                >
                                    {lot.number + " | "}
                                </span>
                                <span
                                    className="text-black font-weight-bold"
                                    style={{
                                        whiteSpace: "pre-wrap",
                                        wordWrap: "break-word",
                                        color: settings?.Styles?.OverridePageTextColor
                                            ? settings?.Styles?.OverridePageTextColor
                                            : "#000000",
                                    }}
                                >
                                    {lot.name}
                                </span>
                            </h3>
                        </div>
                        <div className="text-center">
                            <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>{"Bid to have the chance of owning this exquisite " + settings.LotName.toLowerCase()}</h4>
                        </div>
                    </>
                }
            </Grid>
        </Grid>

        <Loading loading={loading} style={{ padding: 50 }}>
            <Container className={`pb-5 pt-5 ${classes.containerWidth}`} maxWidth={false}>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={3}>
                    <Grid item md={7}  >
                        <Card className={`${classes.biddingCard}`}>
                            <div style={{ maxHeight: '500px', aspectRatio: '1.78' }}>
                                {
                                    validating &&
                                    <div style={{ position: 'absolute', width: '100%', top: 'calc(50% - 60px)', zIndex: 10 }}>
                                        <Loading loading={true} loadingText={finalizingText} errorText={lotStatusFailureCount > 3 ? `Unable to connect, retrying(${lotStatusFailureCount - 3})...` : undefined} size={80} loadingTextStyle={{ color: 'white' }} />
                                    </div>
                                }

                                {
                                    lot &&
                                    <div className="m-1 p-1 bg-primary" style={{ position: 'absolute', right: 0, borderRadius: '5px' }}>
                                        <h5 className="text-center text-light text-capitalize mt-1">{getSTCStatus()}</h5>
                                    </div>
                                }

                                {
                                    !validating && soldBanner?.showBanner &&
                                    <div style={{ position: 'absolute', width: '100%', top: '45%', zIndex: 3 }}>
                                        <SolidFlagBanner backgroundColor="#ff0000" bannerText={soldBanner?.bannerText} />
                                    </div>
                                }

                                <img src={lot?.imageUrl} className="card-img-top img-fluid rounded img-fit-container"
                                    alt="Missing"
                                    style={{ height: "100%", width: "100%", objectFit: 'scale-down', opacity: validating ? 0.3 : 1 }} />
                            </div>
                        </Card>
                    </Grid>
                    <Grid item md={5}>
                        <Card className="pl-4 pr-4 pb-4">
                            <div className="py-3">
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    spacing={1}>
                                    <Grid item xs={6}>
                                        <h5 className="text-black text-left">Status</h5>
                                    </Grid>
                                    <Grid item xs={6} style={{ textAlign: 'right' }}>
                                        {!lot?.isActive ? <h5 className="text-dark text-capitalize" style={{ fontWeight: 900 }}>Unavailable</h5> : <>
                                            {validating && <h5 className="text-dark text-capitalize" style={{ fontWeight: 900 }}>Validating</h5>}
                                            {!validating && <>
                                                {!!start && now < start && <h5 className="text-dark text-capitalize" style={{ fontWeight: 900 }}>Awaiting</h5>}
                                                {!!end && !!start && start < now && now < end && isExtended && <h5 className="text-warning text-capitalize" style={{ fontWeight: 900 }}>Extended</h5>}
                                                {!!end && !!start && start < now && now < end && !isExtended && <h5 className="text-success text-capitalize" style={{ fontWeight: 900 }}>Open</h5>}
                                                {!!end && now > end && <h5 className="text-danger text-capitalize" style={{ fontWeight: 900 }}>Completed</h5>}
                                            </>}
                                        </>}
                                    </Grid>
                                    {paddleNumber !== undefined && paddleNumber.length > 0 && <>
                                        <Grid item xs={6}>
                                            <h5 className=" text-danger text-left">You</h5>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <h5 className=" text-danger text-right">{paddleNumber}</h5>
                                        </Grid>
                                    </>}
                                    <Grid item xs={12}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                            <h5 className=" text-black text-left">Closing In</h5>
                                            <h6 className=" text-primary text-right">
                                                {
                                                    auction && lot && <CountdownTimerComponent countDownTimer={countDownTimer} currentDateTimeOffset={currentDateTimeOffset} />
                                                }
                                            </h6>
                                        </div>
                                    </Grid>
                                </Grid>

                                <div className="divider mb-3" />

                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    spacing={1}>
                                    {lot?.showReserve && <>
                                        <Grid item xs={6}>
                                            <h5 className=" text-black text-left">Reserve Met</h5>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <ReserveMetInnerComponent reserveMet={reserveMet} />
                                        </Grid>
                                    </>
                                    }

                                    <PricingDisplayComponent auctionType={auction?.auctionType} guidePrice={lot?.guidePrice} registrationStatus={registrationStatus} />

                                    {auction?.auctionType !== AuctionTypeEnum.Tender && <>
                                        <Grid item xs={6}>
                                            <h5 className=" text-black text-left">Opening Bid</h5>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <h6 className="text-black-75 font-size-sm text-right" style={{ color: "#5a5a5a" }}><CurrencyLabel value={startingPrice} /></h6>
                                        </Grid>
                                        {lot?.showPaddleNumber && <>
                                            <Grid item xs={6}>
                                                <h5 className=" text-black text-left">Highest Bidder</h5>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h6 className="text-black-75 font-size-sm text-right" style={{ color: "#5a5a5a" }}>{topBidder.bidder}</h6>
                                            </Grid>
                                        </>}
                                        <Grid item xs={6}>
                                            <h5 className=" text-black text-left">Highest Bid</h5>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <h6 className="text-black-75 font-size-sm text-right" style={{ color: "#5a5a5a" }}><CurrencyLabel value={topBidder.amount} /></h6>
                                        </Grid>

                                        <Table className={'table table-bordered ' + classes.biddingHistoryTable} ref={bidTableRef}>
                                            <thead className="thead-light">
                                                <tr>
                                                    {
                                                        !lot?.showPaddleNumber ? (
                                                            <th style={{ width: '0.1%' }}></th>) : (
                                                            <th style={{ width: '100%' }}>Paddle number</th>)
                                                    }
                                                    <th style={{ 'width': '100%', textAlign: 'center' }}>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tableRowData.map((bid, idx) => {
                                                    return (
                                                        <>
                                                            {bid.paddleNumber === paddleNumber ?
                                                                <tr className="bg-primary" key={idx}>
                                                                    {!lot?.showPaddleNumber && <td style={{ width: '1%' }}></td>}
                                                                    {lot?.showPaddleNumber && <td className="text-white">{bid.paddleNumber}</td>}
                                                                    <td className="text-white" style={{ 'width': '100%', textAlign: 'end' }}><CurrencyLabel value={bid.amount} textColour={"text-white"} /></td>
                                                                </tr> :
                                                                <tr key={idx}>
                                                                    {!lot?.showPaddleNumber && <td style={{ width: '1%' }}></td>}
                                                                    {lot?.showPaddleNumber && <td>{bid.paddleNumber}</td>}
                                                                    <td style={{ 'width': '100%', textAlign: 'end' }}><CurrencyLabel value={bid.amount} textColour={"text-white"} /></td>
                                                                </tr>}
                                                        </>
                                                    )
                                                })}
                                            </tbody>
                                        </Table>

                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="center"
                                            spacing={1}>
                                            <Grid item xs={6}>
                                                <h5 className=" text-black text-left">Minimum Increment</h5>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <h6 className="text-black-75 font-size-sm text-right" style={{ color: "#5a5a5a" }}><CurrencyLabel value={minimumIncrement} /></h6>
                                            </Grid>
                                        </Grid>
                                    </>}
                                </Grid>

                                <Loading loading={loadingRegisteredProfile}>
                                    {auction?.auctionType?.toString() === AuctionTypeEnum.Timed.toString() && (validating || (end && end > now)) && registrationStatus === 1 && (<>
                                        <BidButtonContainer
                                            commission={lot?.commission}
                                            biddingCalculation={lot?.biddingCalculation}
                                            increment={minimumIncrement}
                                            topBidderAmount={topBidder.amount}
                                            startingPrice={startingPrice}
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleBidSubmittedSuccess}
                                        />
                                        <CustomBidButtonContainer
                                            commission={lot?.commission}
                                            biddingCalculation={lot?.biddingCalculation}
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleBidSubmittedSuccess}
                                        />
                                        <MaxBidButtonContainer
                                            commission={lot?.commission}
                                            biddingCalculation={lot?.biddingCalculation}
                                            increment={minimumIncrement}
                                            topBidderAmount={topBidder.amount}
                                            startingPrice={startingPrice}
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleMaxBidSubmittedSuccess}
                                            maximumBidAmount={maximumBidAmount}
                                        />
                                    </>)}

                                    {auction?.auctionType?.toString() === AuctionTypeEnum.Tender.toString() && registrationStatus !== null && end && end > now && registrationStatus == 1 && (<>
                                        <TenderBidButtonContainer
                                            auctionId={auctionId}
                                            lotId={lotId}
                                            onSuccess={handleTenderSubmittedSuccess}
                                            tenderAmount={tenderAmount}
                                        />
                                    </>)}

                                    <div className="mb-1" />
                                    <ParticipateButton
                                        auctionId={auctionId}
                                        auctionType={auction?.auctionType}
                                    />
                                </Loading>
                            </div>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </Loading>
    </div >);
};

const mapStateToProps = (state: any) => ({
    profileId: state.loginForm.changeProfileId,
    settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Bidding)