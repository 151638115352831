import React from 'react'
import useStyles from './MainStyles';
import { connect } from 'react-redux';
import Navbar from '@/components/Navbar/Navbar';
import NotificationPanel from '@/components/NotificationPanel/NotificationPanel';
import { Outlet } from 'react-router';
import Footer from '@/components/Footer/Footer';

const Main: React.FC = props => {
    const { classes } = useStyles()

    return (
        <main className={classes.content}>
            <NotificationPanel />
            <Navbar />
            <Outlet />
            <Footer />
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps, null)(Main);
