import React from "react";
import { connect } from "react-redux";
import useStyles from './ConfirmAccountConfirmationCardStyles';
import {
    Button,
    Container
} from '@mui/material';
import useHistory from "../../helpers/hooks/useHistory";

interface Props {
    settings: any,
}

const ConfirmAccountConfirmationCard: React.FC<Props> = props => {

    const styles = useStyles()
    const history = useHistory();

    const {
        settings,
    } = props;

    const navigateHome = () => {
        let path = '/home';
        history.replace(path);
    }

    return (
        <Container component="main" maxWidth="xs" style={{ textAlign: "center", marginTop: 30 }}>
            <img className={styles.classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
            <h3 className="text-black text-center mb-4">Your account has been confirmed and is now active!</h3>

            <h5 className="mb-3" style={{ justifyContent: "center" }}>Please click on the button below to log in before registering for the auction you wish to partake in.</h5>
            <h5 className="mb-3" style={{ justifyContent: "center" }}>Happy Bidding!</h5>
            <Button onClick={navigateHome} variant="contained" className="btn-primary p-3 text-white text-capitalize" style={{ marginTop: 20 }}>
                <span className="btn-wrapper--label">Go To Log In</span>
            </Button>
        </Container>
    )
}


const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps)(ConfirmAccountConfirmationCard);