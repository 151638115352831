import { FC, memo } from 'react';
import { Snackbar, Button } from '@mui/material';
import { AiOutlineWifi } from 'react-icons/ai';
import { useConnectionStatusIndicatorStyles } from '../ConnectionStatusIndicator.styles';

export interface SnackBarProps {
    title: string;
    showSnackbar: boolean;
    snackBarColour: string;
}

const SnackBar: FC<SnackBarProps> = ({
    title,
    showSnackbar,
    snackBarColour,
}) => {

    const { classes: { snackbarContainer, snackbarText } } = useConnectionStatusIndicatorStyles();

    return (
        <Snackbar
            open={showSnackbar}
            message={
                <span id="message-id" className={snackbarContainer}>
                    <div className={snackbarText}>{title}</div>
                </span>
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            ContentProps={{
                "aria-describedby": "message-id",
                className: snackBarColour,
            }}
            action={
                <AiOutlineWifi size={24} />
            }
        />
    );
};

export default memo(SnackBar);
