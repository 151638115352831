import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage'
import { User } from '@/dto/User'

//Action
export const LOG_IN_SUCCESS = 'LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'LOG_IN_ERROR';
export const LOGIN_IN_RESET_STATE = 'LOGIN_IN_RESET_STATE'
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS'
export const CHANGE_PROFILE_SUCCESS = 'CHANGE_PROFILE_SUCCESS';
export const TOGGLE_LOGIN_REGISTER_MODAL = 'TOGGLE_LOGIN_REGISTER_MODAL';
export const REFRESH_PROFILES_SUCCESS = 'REFRESH_PROFILES_SUCCESS';
export const SET_AUTOWISHLIST_DATA = 'SET_AUTOWISHLIST_DATA';
export const CLEAR_AUTOWISHLIST_DATA = 'CLEAR_AUTOWISHLIST_DATA';

const initialState = {
    error: false,
    errorText: '',
    token: '',
    success: false,
    loginSuccess: false,
    logoutSuccess: false,
    changeProfileId: '',
    user: {
        jwtToken:'',
        userId:'',
    },
    showLoginRegisterModal: false,
    autoWishlistData: {
        lotId: '',
    }
}

export default function reducer(state:any = initialState, action: any) {
    switch (action.type) {
        case LOG_IN_SUCCESS: return { ...state, loginSuccess: true, logoutSuccess: false, success: true, error: false, user:{...state.user,jwtToken:action.payload.jwtToken,userId:action.payload.userId}  }
        case REFRESH_PROFILES_SUCCESS: return { ...state, user: { ...(state.user ?? {}), profiles: action.payload } }
        case LOG_IN_ERROR: return { ...state, loginSuccess: false, logoutSuccess: false, success: false, error: true, errorText: action.payload }
        case LOGIN_IN_RESET_STATE: return { ...state, success: false, error: false }
        case LOG_OUT_SUCCESS: return { ...state, changeProfileId: '', loginSuccess: false, logoutSuccess: true, success: true, error: false, user: null }
        case CHANGE_PROFILE_SUCCESS: return { ...state, changeProfileId: action.payload, loginSuccess: true, logoutSuccess: false, success: true, error: false, user: null }
        case TOGGLE_LOGIN_REGISTER_MODAL: return { ...state, showLoginRegisterModal: action.override !== null ? action.override : !state.showLoginRegisterModal }
        case SET_AUTOWISHLIST_DATA: return {
            ...state, autoWishlistData: { lotId: action.payload.lotId }
        }
        case CLEAR_AUTOWISHLIST_DATA: return { ...state, autoWishlistData: initialState.autoWishlistData }
        default: return state;
    }
}

//Action creator
export const setAutoWishlistData = (payload: any) => {
    return { type: SET_AUTOWISHLIST_DATA, payload: payload }
}

export const clearAutoWishlistdata = () => {
    return { type: CLEAR_AUTOWISHLIST_DATA, payload: initialState.autoWishlistData }
}

export const loginSuccess = (user: User) => {
    return { type: LOG_IN_SUCCESS, payload: user }
}

export const toggleLoginRegisterModal = (override: boolean | null = null) => {
    return { type: TOGGLE_LOGIN_REGISTER_MODAL, override }
}

export const loginError = (errorText: string) => {
    return { type: LOG_IN_ERROR, payload: errorText }
}

export const loginResetState = () => {
    return { type: LOGIN_IN_RESET_STATE, payload: LOGIN_IN_RESET_STATE }
}

export const logoutSuccess = () => {
    return { type: LOG_OUT_SUCCESS, payload: LOG_OUT_SUCCESS }
}

export const refreshProfilesSuccess = (profiles) => {
    return { type: REFRESH_PROFILES_SUCCESS, payload: profiles }
}

export const changeProfileId = (profileId: string) => {
    return { type: CHANGE_PROFILE_SUCCESS, payload: profileId }
}

//Thunks
export const handleSubmit = (email: string, password: string, onCompletedCallback?: (result: any) => void) => {

    let userInfo = {
        email: email.replace(" ", ""),
        password: password,
        FirebaseToken: ""
    };

    return (dispatch: any) => {
        return requestClient.post(apiPaths.account.login + "?platform=clientportal", userInfo)
            .then(function (resp) {
                if (resp.success) {
                    const user = resp.response;
                    const { profiles } = user;
                    PrembidLocalStorage.currentUser = (user) as User;
                    dispatch(loginSuccess(resp.response));
                    dispatch(toggleLoginRegisterModal(false));

                    const defaultProfile = profiles.find(({ isDefaultProfile }) => isDefaultProfile === true);

                    if (defaultProfile) {
                        dispatch(handleChangeProfile(defaultProfile.profileId));
                    }
                }
                else {
                    dispatch(loginError(resp.error));
                }
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleLogout = () => {

    return (dispatch: any) => {
        PrembidLocalStorage.clearCurrentUser();
        return dispatch(logoutSuccess());
    }
}

export const handleChangeProfile = (profileId: string) => {
    PrembidLocalStorage.currentProfile = profileId;

    return (dispatch: any) => {
        return dispatch(changeProfileId(profileId));
    }
}

export const handleRefreshProfiles = (onCompletedCallback?: (result: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.account.refreshProfiles, null, null)
            .then(function (resp) {
                if (resp.success) {
                    const profiles = resp.response;
                    dispatch(refreshProfilesSuccess(profiles));

                    const defaultProfile = profiles.find(({ isDefaultProfile }) => isDefaultProfile === true);
                    if (defaultProfile) {
                        dispatch(handleChangeProfile(defaultProfile.profileId));
                    }
                }
                else {
                    dispatch(loginError(resp.error));
                }
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const handleSetAutoWishlistData = (payload: any, onCompletedCallback?: (result: any) => void) => {
    return (dispatch: any) => {
        dispatch(setAutoWishlistData(payload))
        if (onCompletedCallback) onCompletedCallback(payload);
    }
}

export const handleClearAutoWishlistData = () => {
    return (dispatch: any) => {
        dispatch(clearAutoWishlistdata());
    }
}