import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
export const ADD_DOCUMENT_SUCCESS = 'ADD_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DOCUMENT_GET_ALL_SUCCESS = 'DOCUMENT_GET_ALL_SUCCESS';
export const DOCUMENT_T_AND_C_GET_ALL_SUCCESS = 'DOCUMENT_T_AND_C_GET_ALL_SUCCESS';

export const CLEAR_API_RESPONSES = 'CLEAR_API_RESPONSES';
export const ERROR = 'ERROR';

const initialState = {
    error: false,
    errorText: null,
    success: false,
    reload: false,
    documentId: '',
    documents: undefined,
    tandcDocument: undefined,
    relatedId: '',
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ADD_DOCUMENT_SUCCESS: return { ...state, error: false, errorText: '', documentId: action.payload, success: true }
        case DELETE_DOCUMENT_SUCCESS: return { ...state, error: false, errorText: null, success: true }
        case DOCUMENT_GET_ALL_SUCCESS: return { ...state, documents: action.payload.files, relatedId: action.payload.relatedId }
        case DOCUMENT_T_AND_C_GET_ALL_SUCCESS: return { ...state, tandcDocument: action.payload }
        case ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_API_RESPONSES: return { ...state, success: false, documentId: '', tandcDocument: undefined }
        default: return state;
    }
}

//Action creator
export const addDocumentSuccess = (id: string) => {
    return { type: ADD_DOCUMENT_SUCCESS, payload: id }
}

export const deleteDocumentSuccess = () => {
    return { type: DELETE_DOCUMENT_SUCCESS, payload: deleteDocumentSuccess }
}

export const retrieveAllDocumentsSuccess = (files, relatedId) => {
    return {
        type: DOCUMENT_GET_ALL_SUCCESS, payload: { files: files, relatedId: relatedId }
    }
}

export const retrieveTAndCDocumentsSuccess = (file) => {
    return { type: DOCUMENT_T_AND_C_GET_ALL_SUCCESS, payload: file }
}

export const error = (errorText: string) => {
    return { type: ERROR, payload: errorText }
}

export const clearApiResponses = () => {
    return { type: CLEAR_API_RESPONSES, payload: CLEAR_API_RESPONSES }
}

export const handleDocumentAdd = (relatedTableRecordId: string, document: any) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.document.createDocument, { documentTemplateId: document.documentTemplateId, relatedTableRecordId: relatedTableRecordId, documentUploadData: [{ documentBase64String: document.fileUploadData.base64 }] })
            .then(function (resp) {
                if (resp.success) {
                    const id = resp.response
                    dispatch(addDocumentSuccess(id))

                } else {
                    dispatch(error(resp.error))
                }
            });
    }
}

export const handleDocumentDelete = (documentId: string) => {
    return (dispatch: any) => {
        return requestClient.post(apiPaths.document.deleteDocument, { id: documentId })
            .then(function (resp) {
                if (resp.success) {
                    dispatch(deleteDocumentSuccess())

                } else {
                    dispatch(error(resp.error))
                }
            });
    }
}

export const handleDocumentsForRelatedTableRecordFetch = (relatedTableRecordId: string, currentDocumentType: any) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecord, null, { relatedTableRecordId: relatedTableRecordId, documentType: currentDocumentType })
            .then(function (resp) {
                if (resp.success) {
                    dispatch(retrieveAllDocumentsSuccess(resp.response, relatedTableRecordId));
                }
                else {
                    dispatch(error(resp.error));
                }
            });
    }
}

export const handleDocumentsForRelatedTableRecordByPublicAccessFetch = (relatedTableRecordId: string, currentDocumentType: any) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPublicAccess, null, { relatedTableRecordId: relatedTableRecordId, documentType: currentDocumentType })
            .then(function (resp) {
                if (resp.success) {
                    dispatch(retrieveAllDocumentsSuccess(resp.response, relatedTableRecordId));
                }
                else {
                    dispatch(error(resp.error));
                }
            });
    }
}

//export const handleDocumentsForRelatedTableRecordByPrivateAccessFetch = (relatedTableRecordId: string, currentDocumentType: any) => {
//    return (dispatch: any) => {
//        return requestClient.get(apiPaths.document.retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByPrivateAccess, null, { relatedTableRecordId: relatedTableRecordId, documentType: currentDocumentType })
//            .then(function (resp) {
//                if (resp.success) {
//                    dispatch(retrieveAllDocumentsSuccess(resp.response));
//                }
//                else {
//                    dispatch(error(resp.error));
//                }
//            });
//    }
//}

export const handleTsAndCsDocumentsForRelatedTableRecordFetch = (relatedTableRecordId: string, currentDocumentType: any) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveTsAndCsForDocumentType, null, { relatedTableRecordId: relatedTableRecordId, documentType: currentDocumentType })
            .then(function (resp) {
                if (resp) {
                    dispatch(retrieveTAndCDocumentsSuccess(resp));
                }
            });
    }
}


export const handleDocumentsForRelatedTableRecordByApprovedProfileFetch = (relatedTableRecordId: string, auctionId: string, currentDocumentType: any, profileId: string) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveAllDocumentTemplatesAndDocumentsForRelatedTableRecordByApprovedProfile, null, { relatedTableRecordId: relatedTableRecordId, auctionId: auctionId, documentType: currentDocumentType, profileId: profileId })
            .then(function (resp) {
                if (resp.success) {
                    dispatch(retrieveAllDocumentsSuccess(resp.response, relatedTableRecordId));
                }
                else {
                    dispatch(error(resp.error));
                }
            });
    }
}

export const retrieveProfileDocuments = (profileId: string, profileType: string, onCompletedCallback?: (res: any) => void) => {
    return (dispatch: any) => {
        return requestClient.get(apiPaths.document.retrieveProfileDocuments, { profileId, profileType }, null)
            .then(function (resp) {
                if (onCompletedCallback) onCompletedCallback(resp);
            });
    }
}

export const downloadFile = (url: string, onCompletedCallback?: (res: any) => void) => {
    return (dispatch: any) => {
        return requestClient.downloadFile(apiPaths.document.downloadSecureFile, { url: encodeURIComponent(url) }, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            }).catch(function (err) {
                if (onCompletedCallback) onCompletedCallback(null);
            })
    }
}