import React from 'react'
import ConfirmAccountCard from '@/components/ConfirmAccountCard/ConfirmAccountCard';

const ConfirmAccount: React.FC = () => {

    return (
        <ConfirmAccountCard/>
    )
}

export default ConfirmAccount;