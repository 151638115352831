import React from 'react'
import DeleteAccountCard from '@/components/DeleteAccount/DeleteAccountCard';

const DeleteAccount: React.FC = () => {

    return (
        <DeleteAccountCard/>
    )
}

export default DeleteAccount;