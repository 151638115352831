import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';


//Actions

const ACCOUNT_DATA_FETCH_SUCCESS = 'ACCOUNT_DATA_FETCH_SUCCESS';
const ACCOUNT_DATA_FETCH_ERROR = 'ACCOUNT_DATA_FETCH_ERROR';
const ACCOUNT_RESET_STATE = 'ACCOUNT_RESET_STATE'
const SAVE_UPDATE_STATE = 'SAVE_UPDATE_STATE';
const CLEAR_STATE = 'CLEAR_STATE';
const LOG_OUT = 'LOG_OUT';

const initialState = {
    error: false,
    errorText: null,
    success: false,
    userAccount: undefined,
    userId: '',

    userAccountUpdateState: {
        successText: undefined,
        errorText: undefined,
        success: false,
        error: false
    },

    logout: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {

        case ACCOUNT_DATA_FETCH_SUCCESS: return { ...state, error: false, errorText: null, success: true, userAccount: action.payload }
        case ACCOUNT_DATA_FETCH_ERROR: return { ...state, error: true, errorText: action.payload }
        case SAVE_UPDATE_STATE: return { ...state, userAccountUpdateState: { ...action.payload } }
        case ACCOUNT_RESET_STATE: return { ...state, success: false, error: false }
        case CLEAR_STATE: return { ...initialState }

        case LOG_OUT:
            if (!state.logout) {
                //window.alert("Your token has expired! Please log in again. You will automatically be redirected.");
                //window.location.href = "/";
            }
            return { ...initialState, logout: true }

        default: return state;
    }
}


//Action creator
export const userAccountFetchError = (errorText: string) => {
    return { type: ACCOUNT_DATA_FETCH_ERROR, payload: errorText }
}

export const accountGetSuccess = (userAccount: any) => {
    return { type: ACCOUNT_DATA_FETCH_SUCCESS, payload: userAccount }
}

export const accountResetState = () => {
    return { type: ACCOUNT_RESET_STATE, payload: ACCOUNT_RESET_STATE }
}

export const resetAccountState = () => {
    return { type: CLEAR_STATE }
}

export const saveUpdateUser = (state: any) => {
    return { type: SAVE_UPDATE_STATE, payload: state }
}

export const handleLogout = () => {
    return { type: LOG_OUT }
}

export const handleUserAccountFetch = () => {
    return function (dispatch: any) {
        return requestClient.get(apiPaths.account.retrieveAccount, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(accountGetSuccess(res.response))
                } else {
                    dispatch(userAccountFetchError(res.error))
                }
            })
    }
}

export const handleUserAccountUpdate = (user: any) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.updateAccount, user)
            .then(function (res) {
                if (res.success) {
                    dispatch(saveUpdateUser({
                        successText: "Account updated successfully",
                        success: true,
                        error: false,
                        errorText: undefined
                    }))
                } else {
                    dispatch(saveUpdateUser({
                        success: false,
                        successText: undefined,
                        error: true,
                        errorText: res.error
                    }))
                }
            })
    }
}

export const handleUpsertUserDevice = (token: any) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.upsertUserDevice + "?platform=clientportal", token)
            .then(function (res) {
                if (res.success) {

                } else {
                    //TODO: Show error!
                }
            })
    }
}

export const handleRemoveAccountDeletion = (email: string, onCompletedCallback?: (response: any) => void) => {
    let userInfo = {
        email: email,
    };

    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.removeAccountDeletion, userInfo)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleDeleteAccount = (onCompletedCallback?: (response: any) => void) => {
    return (dispatch: any) => {
        return requestClient.delete(apiPaths.account.deleteAccount, undefined, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleDeleteAccountConfirmation = (userId: any, tokenId: any, onCompletedCallback?: (response: any) => void) => {
    let userInfo = {
        userId: userId,
        tokenId: tokenId,
    };

    return function (dispatch: any) {
        //Hard delete the identity account
        return requestClient.post(apiPaths.account.deleteAccountConfirmation, userInfo)
            .then(function (res) {
                if (!res.success) {
                    if (onCompletedCallback) onCompletedCallback(res);
                } else {
                    //Soft delete the nidding profiles (This is to hod transactional data)
                    return requestClient.delete(apiPaths.profile.deleteAllBiddingProfiles, undefined, { userId })
                        .then(function (res) {
                            if (onCompletedCallback) onCompletedCallback(res);
                        })
                }
            })
    }
}