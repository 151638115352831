import React from 'react'
import ForgotPasswordConfirmationCard from '@/components/ForgotPasswordConfirmationCard/ForgotPasswordConfirmationCard';

const ForgotPasswordConfirmation: React.FC = () => {

    React.useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <ForgotPasswordConfirmationCard />
    )
}

export default ForgotPasswordConfirmation
