import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import * as React from "react";
import { useState } from "react";
import { connect } from 'react-redux';
import { updateState } from "../store/RegistrationModal";
import { Person as PersonIcon, Business as BusinessIcon } from '@mui/icons-material';
import CompanyProfileAddEdit from "../../../pages/BiddingProfile/CompanyProfileAddEdit";
import IndividualProfileAddEdit from "../../../pages/BiddingProfile/IndividualProfileAddEdit";
import FooterButtons from "./FooterButtons";

interface ProfileAddProps {
    onClose: () => void;
    onNextStep: () => void;
    onBackStep: () => void;
    settings: any,
    state: any,
    updateState: (data: any) => void
}

export const ProfileAdd: React.FC<ProfileAddProps> = props => {
    const { onClose, onNextStep, onBackStep, state, updateState } = props;

    const [profileType, setProfileType] = useState<any>('company');

    const handleOnClose = () => {
        //Custom logic
        onClose();
    }

    const handleBack = () => {
        //Custom logic
        onBackStep();
    }

    const handleSubmit = (data?: any) => {
        //Custom logic
        updateState({ setSelectedProfileId: data?.id, resetProfiles: true })
        onNextStep();
    }

    const renderFooter = (onClose?: () => void, onBack?: () => void, onSubmit?: () => void) => <FooterButtons onClose={onClose} onBack={onBack} onSubmit={onSubmit} />

    return <div style={{ width: profileType ? 800 : 500 }}>
        <h5 style={{ marginTop: 10 }}>{profileType === 'company' ? "Adding New Company Profile" : profileType === "individual" ? "Adding New Individual Profile" : "Add New Profile"}</h5>
        <Divider style={{ marginBottom: 10 }} />
        {!profileType ? <>
            <h5 id="simple-dialog-title">Profile Type</h5>
            <List>
                <ListItem button onClick={() => setProfileType("company")} key={"company"}>
                    <ListItemAvatar>
                        <Avatar>
                            <BusinessIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Company" />
                </ListItem>

                <ListItem button onClick={() => setProfileType("individual")} key={"individual"}>
                    <ListItemAvatar>
                        <Avatar>
                            <PersonIcon />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Individual" />
                </ListItem>
            </List>
        </> : profileType === 'company' ? <CompanyProfileAddEdit
            renderSpaceAtTop={false}
            overrideButtonSection={renderFooter}
            onCloseCallback={handleOnClose}
            onBackCallback={handleBack}
            onSubmitCallback={handleSubmit}
            scrollToTop={false}
            registrationProcess={true}
        /> : <IndividualProfileAddEdit
            renderSpaceAtTop={false}
            overrideButtonSection={renderFooter}
            onCloseCallback={handleOnClose}
            onBackCallback={handleBack}
            onSubmitCallback={handleSubmit}
            registrationProcess={true}
        />}
        {!profileType && <FooterButtons onClose={handleOnClose} onBack={handleBack} />}
    </div>
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    state: state.biddingRegistrationModal
})


const mapDispatchToProps = (dispatch: any) => {
    return {
        updateState: (data: any) => dispatch(updateState(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAdd)