import React, { useEffect, useState } from "react";
import { apiPaths } from "../../api/ApiPaths";
import requestClient from "../../api/RequestClient";
import { isMobile } from 'react-device-detect';

import './PrivacyPolicy.scss'


const PrivacyPolicy = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [html, setHtml] = useState<any>(null);

    useEffect(() => {
        refreshPrivacyPolicy();
    }, []);

    const refreshPrivacyPolicy = async () => {
        setLoading(true);
        try {
            var res = await requestClient.get(apiPaths.vendor.retrievePrivacyPolicy, null, null);
            if (res.success) {
                var htmlText = res.response.htmlText;
                if (htmlText) setHtml(htmlText);
                else setHtml("<p>Not configured. Please contact an administrator.</p>");
            } else {
                setHtml("<p>Failed to load this page. Please try again.</p>");
            }
        } catch (error) {
            setHtml("<p>Failed to load this page. Please try again.</p>");
        } finally {
            setLoading(false);
        }
    }

    return <div style={{ display: 'flex', justifyContent: 'center' }}>
        {html && <div dangerouslySetInnerHTML={{ __html: html }} />}
    </div>
}

export default PrivacyPolicy;