import React from 'react'
import ReactPlayer from 'react-player/lazy'

interface Props {
    videoUrl: string,
}

const VideoPlayer: React.FC<Props> = props => {

    const {
        videoUrl,
    } = props;

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;
    const dummyurl = 'https://www.youtube.com/watch?v=Thisisadummyurl'

    function youtube_parser(url) {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    const YoutubeSlide = ({ url }: { url: string }) => (
        <div className="carousel-wrapper">
            <ReactPlayer width="auto%" height="100%" url={url} controls={true} />
        </div>
    );

    return (
        <div>
            <YoutubeSlide url={videoUrl ? videoUrl : dummyurl} />
        </div>
    )
}

export default VideoPlayer