import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    mapContainer: {
        zIndex: 1,
    },
    divSpacing: {
        margin: theme.spacing(0, 0, 0, 0),
    },
    containerWidth: {
        maxWidth: '1280px'
    },
    imageCard: {
        boxShadow: '0 0 5px rgba(255, 255, 255, 0.5)',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        '&:hover': {
            boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
            transform: 'translateY(-5px)'
        }
    },
}))

export default useStyles;