import React from 'react'
import ConfirmAccountConfirmationCard from '@/components/ConfirmAccountConfirmationCard/ConfirmAccountConfirmationCard';

const ConfirmAccountConfirmation: React.FC = () => {

    React.useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <ConfirmAccountConfirmationCard />
    )
}

export default ConfirmAccountConfirmation
