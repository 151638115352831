import React from "react";
import { connect } from "react-redux";
import useStyles from './ForgotPasswordConfirmationCardStyles';
import {
    Button,
    Container,
} from '@mui/material';
import useHistory from "../../helpers/hooks/useHistory";

interface Props {
    settings: any,
}

const ForgotPasswordConfirmationCard: React.FC<Props> = props => {

    const { classes } = useStyles()
    const history = useHistory();

    const {
        settings,
    } = props;

    const navigateForgotPassword = () => {
        let path = '/forgotPassword';
        history.push(path);
    }

    const navigateLogin = () => {
        let path = '/home';
        history.push(path);
    }

    return (
        <Container component="main" maxWidth="xs" style={{ textAlign: "center", marginTop: 30 }}>
            <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
            <h3 className="text-black text-center mb-4">Check your email!</h3>

            <h5 className="mb-3">If you provided us with the correct email address used to create your account, please check your email and follow the provided link to reset your password.</h5>
            <h5 className="mb-3">If you do not receive an email, please check your <h5 className="text-info">SPAM folder.</h5></h5>
            <h5 className="mb-3">Please also ensure that you inserted the correct email associated with your account or click on the highlighted link and try a <a onClick={navigateForgotPassword} className={classes.link + " text-info"} style={{ textDecoration: "underline" }}>different email</a>.</h5>

            <Button onClick={navigateLogin} variant="contained" className="btn-primary p-3 text-white text-capitalize" style={{ marginTop: 20 }}>
                <span className="btn-wrapper--label">Go To Log In</span>
            </Button>
        </Container>
    )
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps)(ForgotPasswordConfirmationCard);