import React from "react";
import { connect } from "react-redux";
import useStyles from './ResetPasswordConfirmationCardStyles';
import {
    Button,
    Container,
} from '@mui/material';
import { MdDone } from "react-icons/md";
import useHistory from "../../helpers/hooks/useHistory";
interface Props {
    settings: any,
}

const ResetPasswordConfirmationCard: React.FC<Props> = props => {

    const { classes } = useStyles()
    const history = useHistory();

    const {
        settings,
    } = props;

    const navigateHome = () => {
        let path = `/home`;
        history.push(path);
    }

    return (
        <Container component="main" maxWidth="xs" style={{ textAlign: "center", marginTop: 30 }}>
            <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
            <h3 className="text-black text-center mb-4">Your password has been reset successfully!</h3>

            <h5 className="mb-3">Please proceed and log in with your newly created password.</h5>
            <Button onClick={navigateHome} variant="contained" className="btn-primary p-3 text-white text-capitalize" style={{ marginTop: 20 }}>
                <span className="btn-wrapper--label">Go To Log In</span>
            </Button>
        </Container>
    )
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps)(ResetPasswordConfirmationCard);