import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
export const CONFIRM_EMAIL_CHANGE_CONFIRMATION_SUCCESS = 'CONFIRM_EMAIL_CHANGE_CONFIRMATION_SUCCESS';
export const CONFIRM_EMAIL_CHANGE_CONFIRMATION_ERROR = 'CONFIRM_EMAIL_CHANGE_CONFIRMATION_ERROR';

const initialState = {
    success: false,
    successData: undefined,
    error: false,
    errorText: undefined
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case CONFIRM_EMAIL_CHANGE_CONFIRMATION_SUCCESS: return { ...state, success: true, successData: action.payload }
        case CONFIRM_EMAIL_CHANGE_CONFIRMATION_ERROR: return { ...state, error: true, errorText: action.payload }
        default: return state;
    }
}

//Action creator
export const confirmChangeEmailSuccess = (successData: any) => {
    return { type: CONFIRM_EMAIL_CHANGE_CONFIRMATION_SUCCESS, payload: successData }
}

export const confirmChangeEmailError = (errorText: string) => {
    return { type: CONFIRM_EMAIL_CHANGE_CONFIRMATION_ERROR, payload: errorText }
}


//Thunks
export const handleConfirmEmailChangeSubmit = (userId, tokenId) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.account.confirmEmailChange, {userId, tokenId})
            .then(function (res) {
                if (res.success) {
                    const jsonData = res.response
                    dispatch(confirmChangeEmailSuccess(jsonData))
                } else {
                    dispatch(confirmChangeEmailError(res.error))
                }
            })
    }
}
