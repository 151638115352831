import React from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import useStyles from './HeaderBlockStyles';
import { isMobile } from 'react-device-detect';

import {
    Container,
    Button,
    Grid,
    Card
} from '@mui/material';
import { zeroPad } from 'react-countdown';
import { Countdown } from "@/components/library/Countdown";
import useHistory from '../../helpers/hooks/useHistory';

interface Props {
    description?: string
    timerHeader?: string,
    timerDescription?: any,
    timerBtnText?: string,
    timerBtnSrc?: string,
    imageSrc: string
    settings: any,
}

const HeaderBlock: React.FC<Props> = (props) => {
    const {
        description,
        timerHeader,
        timerDescription,
        timerBtnText,
        timerBtnSrc,
        imageSrc,
        settings
    } = props;

    const { classes } = useStyles();
    const history = useHistory();
    const [countDownDate, setCountDownDate] = React.useState<any>(undefined);

    //React.useEffect(() => {
    //    if (overrideImgSrc === '' || overrideImgSrc === undefined) {
    //        setImgSrc(settings.VendorBannerUrl)
    //    } else {
    //        setImgSrc(overrideImgSrc)
    //    }
    //}, [settings])

    React.useEffect(() => {
        if (timerDescription) {
            var newCountDownDate = new Date(timerDescription);
            var newCountDownDateIsoString = newCountDownDate.toISOString();
            setCountDownDate(newCountDownDateIsoString);
        }
    }, [timerDescription])

    const timerBtnRoute = () => {
        if (timerBtnSrc) {
            history.push(timerBtnSrc)
        }
    }

    const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {

        if (completed) {
            return <React.Fragment><h4 className="text-center text-white font-weight-bold">Now Open</h4></React.Fragment>
        } else {
            return <React.Fragment>
                <h4 className="text-center text-white center  font-weight-bold">
                    <Grid
                        container
                        direction="row"
                        className="center text-center"
                        spacing={1}>
                        <Grid item xs={3}>
                            {zeroPad(days)}:
                        </Grid>
                        <Grid item xs={3}>
                            {zeroPad(hours)}:
                        </Grid>
                        <Grid item xs={3}>
                            {zeroPad(minutes)}:
                        </Grid>
                        <Grid item xs={2}>
                            {zeroPad(seconds)}
                        </Grid>
                    </Grid>
                </h4>

                <h6 className="text-center text-white center">
                    <Grid
                        container
                        direction="row"
                        className="center text-center"
                        spacing={1}>
                        <Grid item xs={3}>
                            Days
                        </Grid>
                        <Grid item xs={3}>
                            Hours
                        </Grid>
                        <Grid item xs={3}>
                            Minutes
                        </Grid>
                        <Grid item xs={2}>
                            Seconds
                        </Grid>
                    </Grid>
                </h6></React.Fragment>
        }
    };

    const iosApp = () => {
        window.open(settings.IOSAppUrl);
    }

    const androidApp = () => {
        window.open(settings.AndroidAppUrl);
    }

    const [greater, setGreater] = React.useState<boolean>(window.innerWidth > 1620);
    React.useEffect(() => {
        function handleResize() {
            if (window.innerWidth > 1620 && !greater)
                setGreater(true)
            else if (window.innerWidth <= 1620 && greater)
                setGreater(false)
        }

        window.addEventListener('resize', handleResize)
    })

    function Bottombar() {
        if (settings?.Styles?.OverrideBottomBarBackgroundColor) {
            return <div className='text-center' style={{ height: '50px', background: settings?.Styles?.OverrideBottomBarBackgroundColor }} />
        } else {
            return <div className='text-center bg-primary' style={{ height: '50px' }} />
        }
    }

    return <>
        <div style={{ position: 'relative', height: '100%', width: '100%', marginTop: '128px', backgroundImage: `url(${imageSrc})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center', aspectRatio: '4' }} >
            {!isMobile && <div style={{ display: 'flex', justifyContent: 'flex-end', position: 'absolute', right: 0, bottom: 0 }}>
                <div style={{ padding: 10, display: 'flex', flexDirection: greater ? 'column' : 'row-reverse' }}>
                    {countDownDate && <div className='d-flex justify-content-center align-items-center'>
                        <Card className='p-4 text-center' style={{ background: '#00000070', marginLeft: greater ? 0 : 10, marginBottom: greater ? 10 : 0, width: '100%', maxWidth: 500, }}>
                            <h4 className="text-center text-white font-weight-bold" >{timerHeader}</h4>
                            <h4 className="text-center text-white">{description}</h4>
                            <h4><Countdown date={countDownDate} renderer={CountdownRenderer} /></h4>
                            <Button onClick={timerBtnRoute} fullWidth variant="contained" className="btn-primary mt-3 p-3 text-black text-capitalize">{timerBtnText}</Button>
                        </Card>
                    </div>}
                    {(settings.AndroidAppUrl || settings.IOSAppUrl) && <div style={{ display: 'flex', height: '100%', alignItems: 'flex-end' }} >
                        <Card className='p-2 text-center' style={{ background: '#00000070', width: '100%' }}>
                            <h4 className="text-white text-center font-weight-bold">Download our</h4>
                            <h4 className="text-white text-center font-weight-bold">Mobile Applications</h4>
                            <img onClick={androidApp} src={"https://storage.googleapis.com/prembid/google.png"} className={classes.appDownloadImg} />
                            <img onClick={iosApp} src={"https://storage.googleapis.com/prembid/ios.png"} className={classes.appDownloadImg} />
                        </Card>
                    </div>}
                </div>
            </div>}
        </div>

        <Bottombar />
    </>
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderBlock);