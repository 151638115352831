import { makeStyles } from "tss-react/mui";

export const usePricingDisplayStyles = makeStyles()((theme) => ({
  pricingDisplayContainer: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(430)]: {
      marginRight: theme.spacing(0.5),
    },
    [theme.breakpoints.down(365)]: {
      marginRight: theme.spacing(0)
    },
    [theme.breakpoints.between(650, 1120)]: {
      marginRight: theme.spacing(0)
    },
  },
}));
