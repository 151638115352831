import * as React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Alert
} from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { FaTimes, FaSave } from "react-icons/fa";

export interface PasswordConfirmationOptions {
    title: string;
    description: string;
    okButtonText: string;
    cancelButtonText: string;
}

interface PasswordConfirmationDialogProps extends PasswordConfirmationOptions {
    open: boolean;
    onOk: (password: any) => void;
    onCancel: () => void;
}

export const PasswordConfirmationDialog: React.FC<PasswordConfirmationDialogProps> = props => {
    const {
        title,
        description,
        okButtonText,
        cancelButtonText,
        open,
        onCancel,
        onOk
    } = props

    const [password, setPassword] = React.useState<string>('')
    const [passwordError, setPasswordError] = React.useState<string>('')
    const [showPassword, setShowPassword] = React.useState(false);

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        setPassword(e.target.value)
    }

    const validate = (password) => {

        if (password === '') {
            setPasswordError('Password field cannot be blank');
            return false;
        }

        setPasswordError('');
        return true;
    }

    const onSubmit = () => {
        if (validate(password)) {
            onOk(password);
            setPassword('');
        }
    }

    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

    return (
        <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{description}</DialogContentText>
                <FormControl fullWidth margin="dense" variant="outlined">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        value={password}
                        onChange={onChange}
                        onKeyPress={onKeyPress}
                        required
                        id="password"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleTogglePassword}
                                >
                                    {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                {passwordError && (<Alert severity='error'>{passwordError}</Alert>)}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} variant="contained" className="btn-danger p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaTimes />
                    </span>
                    <span className="btn-wrapper--label">{cancelButtonText}</span>
                </Button>

                <Button onClick={onSubmit} variant="contained" className="btn-primary p-3 text-white mr-1 text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaSave />
                    </span>
                    <span className="btn-wrapper--label">{okButtonText}</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PasswordConfirmationDialog