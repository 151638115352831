export enum BiddingProfileEnums {
    'Company' = 0,
    'Individual' = 1,
    'Trust' = 2,
    'Sole Proprietor' = 3
}

// export const BiddingProfileList = (): any[] => {
//     const types = [] as any;
//     // for (var enumMember in BiddingProfileEnums) {
//     //     var isValueProperty = parseInt(enumMember, 10) >= 0
//     //     if (isValueProperty) {
//     //         types.push({
//     //             value: enumMember,
//     //             label: BiddingProfileEnums[enumMember]
//     //         });
//     //     }
//     // }
//     return types;
// }