import { ConnectionStatus } from "prembid-shared-library-npm/types";

const SIGNALR_INITIALIZATION_SUCCESS = "SIGNALR_INITIALIZATION_SUCCESS";
const SIGNALR_INITIALIZATION_ERROR = "SIGNALR_INITIALIZATION_ERROR";
const SIGNALR_INITIALIZATION_RECONNECTING = "SIGNALR_INITIALIZATION_RECONNECTING";
const SIGNALR_CONNECTION_STATUS_UPDATED = "SIGNALR_CONNECTION_STATUS_UPDATED";

const initialState = {
    connectionStatus: ConnectionStatus.Connecting
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SIGNALR_INITIALIZATION_SUCCESS:
            return { ...state, connectionStatus: ConnectionStatus.Connected };
        case SIGNALR_INITIALIZATION_ERROR:
            return { ...state, connectionStatus: ConnectionStatus.Error };
        case SIGNALR_INITIALIZATION_RECONNECTING:
            return { ...state, connectionStatus: ConnectionStatus.Connecting };
        case SIGNALR_CONNECTION_STATUS_UPDATED:
            return { ...state, connectionStatus: action.connectionStatus };
        default:
            return state;
    }
}

export const signalRInitializationSuccess = () => {
    return { type: SIGNALR_INITIALIZATION_SUCCESS }
}

export const signalRReconnecting = () => {
    return { type: SIGNALR_INITIALIZATION_RECONNECTING };
};

export const signalRInitializationError = () => {
    return { type: SIGNALR_INITIALIZATION_ERROR }
}

export const signalRUpdateConnectionStatus = (connectionStatus: ConnectionStatus) => {
    return { type: SIGNALR_CONNECTION_STATUS_UPDATED, connectionStatus }
}
