import React from "react";
import { connect } from "react-redux";
import useStyles from './RegisterConfirmationCardStyles';
import {
    Button,
    Container,
} from '@mui/material';
import useHistory from "../../helpers/hooks/useHistory";

interface Props {
    settings: any,
}

const RegisterConfirmationCard: React.FC<Props> = props => {

    const { classes } = useStyles()
    const history = useHistory();

    const {
        settings,
    } = props;

    const navigateHome = () => {
        let path = '/home';
        history.replace(path);
    }

    return (
        <Container component="main" maxWidth="xs" style={{ textAlign: "center", marginTop: 30 }}>
            <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
            <h3 className="text-black text-center mb-4">Thank you for creating an account with us!</h3>
            <h5 className="mb-3">Please check your email and confirm your account via the link provided within the confirm account email.</h5>
            <h5 className="mb-3">Please note, if you do not receive an email within a few minutes then proceed to check your <h5 className="text-info">SPAM FOLDER.</h5></h5>
            <Button onClick={navigateHome} variant="contained" className="btn-primary p-3 text-white text-capitalize" style={{ marginTop: 20 }}>
                <span className="btn-wrapper--label">Return to Home</span>
            </Button>
        </Container>
    )

}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

export default connect(mapStateToProps)(RegisterConfirmationCard);
