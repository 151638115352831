import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

export const determineAuctionForLot = (lotId: string, onCompletedCallback?: (response: any) => void) => {
    return function (dispatch: any) {

        return requestClient.get(apiPaths.lots.DetermineAuctionForLot, { lotId }, null)
            .then(function (res) {
                if (onCompletedCallback) onCompletedCallback(res)
            })

    }
}