import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const RESET_PASSWORD_RESET_STATE = 'RESET_PASSWORD_RESET_STATE';

const initialState = {
    error: false,
    errorText: '',
    token: '',
    success: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case RESET_PASSWORD_SUCCESS: return { ...state, success: true, error: false }
        case RESET_PASSWORD_ERROR: return { ...state, success: false, error: true, errorText: action.payload }
        case RESET_PASSWORD_RESET_STATE: return { ...state, success: false, error: false }
        default: return state;
    }
}

//Action creator

export const resetPasswordSuccess = (token: any) => {
    return { type: RESET_PASSWORD_SUCCESS, payload: token }
}

export const resetPasswordError = (errorText: string) => {
    return { type: RESET_PASSWORD_ERROR, payload: errorText }
}

export const resetPasswordResetState = () => {
    return { type: RESET_PASSWORD_RESET_STATE, payload: RESET_PASSWORD_RESET_STATE }
}

//Thunks
export const handleSubmit = (userId: string, tokenId: string, password: string) => {

    let userInfo = {
        userId: userId,
        tokenId: tokenId,
        password: password
    };

    return (dispatch: any) => {
        requestClient.post(apiPaths.account.resetPassword, userInfo)
            .then(function (resp) {
                if (resp.success) {
                    dispatch(resetPasswordSuccess(resp.response));
                }
                else {
                    dispatch(resetPasswordError(resp.error))
                }
            })
    }

}