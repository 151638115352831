import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage'

const auth = () => {
    const conditionals: boolean[] = [
        PrembidLocalStorage.currentUser?.jwtToken !== undefined,
        PrembidLocalStorage.currentUser?.jwtToken !== "",
        PrembidLocalStorage.currentUser?.jwtToken !== null,
    ]
    return conditionals.includes(false);
}

export default auth