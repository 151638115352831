import React, { useEffect, useMemo } from 'react';
import { Button, TextField, CircularProgress, InputAdornment, Grid, Tooltip, DialogContentText, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { clearApiResponses, submitMaximumBid } from '@/pages/Bids/store/Bidding';
import { connect } from 'react-redux';
import { RegisteredUserStatusEnum } from '@/helpers/RegisteredUserStatusEnum'
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage'
import Currency from "@/components/CurrencyComponent/CurrencyComponent";
import CurrencyInput from "@/components/CurrencyComponent/CurrencyInput";
import ns from '@/helpers/NotificationService';
import { ConfirmationDialog } from "@/components/Modal/confirmationModal";

import clsx from 'clsx';
import CurrencyLabel from '../CurrencyComponent/CurrencyLabel';
import { sortBy, sum } from 'lodash';

interface Props {
    onMaximumBidSubmitted: (amount: number) => void;
    submitMaximumBid_d: (auctionId: string, lotId: string, amount: number, automaticBidAmount: number, profileId: string, onCompletedCallback: (res: any) => void) => void,
    clearResponses_d: () => void,
    auctionId: string,
    lotId: string,
    validateResult: any,
    profileId: string,
    automaticBidAmount: number,
    loginSuccess: boolean,
    logoutSuccess: boolean,
    success: boolean,
    maximumBidError: boolean,
    maximumBidErrorText: string,
    maximumBidAmount: number,
    onMaxBidSuccessfullySubmitted: (amount: number) => void;
    biddingCalculation: any;
    commission: any;
    settings: any;
}

const MaximumBidButton: React.FC<Props> = props => {

    const {
        onMaximumBidSubmitted,
        submitMaximumBid_d,
        clearResponses_d,
        auctionId,
        lotId,
        validateResult,
        profileId,
        automaticBidAmount,
        loginSuccess,
        logoutSuccess,
        success,
        maximumBidError,
        maximumBidErrorText,
        maximumBidAmount,
        onMaxBidSuccessfullySubmitted,
        biddingCalculation,
        commission,
        settings
    } = props;

    const [isBusy, setIsBusy] = React.useState(false);
    const [vatPercentage, setVatPercentage] = React.useState<number>(15);
    const [maximumBid, setMaximumBid] = React.useState({
        status: RegisteredUserStatusEnum.NotRegistered,
        reason: ''
    });

    useEffect(() => {
        setCustomAmountField({ ...customAmountField, customAmount: maximumBidAmount })
    }, [maximumBidAmount])

    const [customAmountField, setCustomAmountField] = React.useState<any>({ customAmount: 0 });

    const [confirmationModal, setConfirmationModal] = React.useState<any>({
        open: false,
        id: ''
    })

    const onCustomAmountChange = (e: { target: { id: any; value: string } }) => {
        setCustomAmountField({ ...customAmountField, [e.target.id]: e.target.value })
    };

    const applyMaximumBid = () => {
        showConfirmationModal(true, '');
    }

    useEffect(() => {
        if (success) {
            clearResponses_d();
            setIsBusy(false);
            onMaximumBidSubmitted(customAmountField.customAmount)
        }
    }, [success]);

    useEffect(() => {
        if (maximumBidError) {
            clearResponses_d();
            setIsBusy(false);
        }
    }, [maximumBidError]);

    useEffect(() => {
        if (loginSuccess) {

        }
    }, [loginSuccess])

    useEffect(() => {
        if (validateResult && validateResult.length > 0) {
            const result = validateResult.filter(result => result.auctionId === auctionId)[0]
            if (result) {
                if (result.status === RegisteredUserStatusEnum.Approved && PrembidLocalStorage.currentUser) {
                    setMaximumBid({ ...maximumBid });
                    setCustomAmountField({ ...customAmountField, customAmount: maximumBidAmount })
                }
                else {
                    setMaximumBid({ ...maximumBid, status: result.status, reason: result.reason });
                }
            }
        }
    }, [validateResult])

    const onConfirmationModalClosed = () => {
        showConfirmationModal(false, '');
    }

    const onConfirmationModalOk = () => {
        showConfirmationModal(false, '');

        setIsBusy(true);
        submitMaximumBid_d(auctionId, lotId, Number(customAmountField.customAmount), Number(automaticBidAmount), profileId, (res) => {
            if (res?.success) {
                ns.success('', 'Maximum bid successfully submitted');
                onMaxBidSuccessfullySubmitted(Number(customAmountField.customAmount));
            }
        });
    }

    const showConfirmationModal = (open: boolean, id: string) => {
        setConfirmationModal({ ...confirmationModal, open: open, id: id });
    }

    const buyersCommissionAmount = useMemo(() => { return Number(customAmountField.customAmount) * commission / 100; }, [customAmountField.customAmount, commission]);

    const determineCalculationAmount = (basedOn: any, subTotal: number, custombidAmount: number, amount: number, type: any) => {
        let total = custombidAmount;
        if (basedOn === "cumulativeTotal") total = subTotal;

        switch (type) {
            case "percentage": return total * amount / 100;
            default: return amount;
        }
    }

    const additionCostAmounts = useMemo<number[]>(() => {
        if ((biddingCalculation?.calculations?.length ?? 0) === 0) return [];

        let orderdedList = sortBy(biddingCalculation?.calculations, ["order"]);

        let totals: number[] = [];

        let subTotal = Number(customAmountField.customAmount) + buyersCommissionAmount;

        for (let i = 0; i < orderdedList.length; i++) {
            let item = orderdedList[i];
            let calculationAmount = determineCalculationAmount(item.basedOn, subTotal, customAmountField.customAmount, item.amount, item.type);
            subTotal += calculationAmount;
            totals.push(calculationAmount);
        }

        return totals;
    }, [customAmountField.customAmount, buyersCommissionAmount, biddingCalculation?.calculations]);

    const totalExVatAmount = useMemo(() => sum(additionCostAmounts ?? [0]) + Number(customAmountField.customAmount) + buyersCommissionAmount, [customAmountField.customAmount, buyersCommissionAmount, additionCostAmounts]);

    const vatAmount = useMemo(() => {
        return totalExVatAmount * vatPercentage / 100;
    }, [totalExVatAmount])

    const total = useMemo(() => {
        return totalExVatAmount + (biddingCalculation?.applyVat ? vatAmount : 0);
    }, [totalExVatAmount, vatAmount])

    return (<React.Fragment>
        {isBusy ? <div className='d-flex flex-direction-column align-items-center justify-content-center'>
            <CircularProgress
                className="text-center mt-3"
                style={{
                    margin: "auto",
                    color: settings?.Styles?.OverrideLoadingSpinnerColor
                        ? settings?.Styles?.OverrideLoadingSpinnerColor
                        : settings?.Styles?.Primary,
                }}
                size={40}
            />
        </div> : <React.Fragment>
            <div className="divider m-2" />
            <Grid
                className="mt-2"
                container
                spacing={2}>
                <Grid item xs={6}>
                    <TextField margin="dense" value={customAmountField.customAmount} onChange={onCustomAmountChange} id="customAmount" fullWidth variant="outlined" className='m-1' label="Maximum Bid"
                        InputProps={{
                            inputComponent: CurrencyInput,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Currency priceWithSymbol={""} />
                                </InputAdornment>
                            )
                        }} />
                </Grid>
                <Grid item xs={6}>
                        <Button style={{ height: 56 }} onClick={applyMaximumBid} fullWidth variant="contained" className={clsx("p-3 mt-1 text-white text-capitalize btn-primary")}>
                        {"Submit"}
                    </Button>
                </Grid>

                <ConfirmationDialog
                    open={confirmationModal.open}
                    payload={confirmationModal.id}
                    okButtonText="Confirm"
                    cancelButtonText="Cancel"
                    title="Please note you are about to log a maximum bid"
                    content={<>
                        <DialogContentText>Our system will automatically bid on your behalf while comparing your bid against the reserve and other maximum bids logged.</DialogContentText>
                        <DialogContentText><strong style={{ opacity: 1, fontWeight: 'bold', color: settings.Styles.Primary }}>PLEASE NOTE: </strong> It is normal in certain instances for bids to increase by more than the increment as the system does all the related calculations. Please do not be alarmed.</DialogContentText>
                        <DialogContentText>This action cannot be undone, <strong style={{ color: settings.Styles.Primary }}>please confirm</strong> that you are about to make the below maximum bid before proceeding.</DialogContentText>

                        {
                            biddingCalculation &&
                            <>
                                <DialogContentText>
                                    The maxmimum amount you are liable to pay if your maximum bid is accepted.
                                </DialogContentText>
                                <DialogContentText>
                                    See breakdown below
                                </DialogContentText>

                                <Grid className="p-1">
                                    <TableContainer component={Paper}>
                                        <Table aria-label="simple table" style={{ width: "100%" }}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>Bid Amount</TableCell>
                                                    <TableCell align="right">
                                                        <CurrencyLabel value={customAmountField.customAmount} textColour={settings?.Styles?.Primary}
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow>
                                                    <TableCell>Buyers Commission</TableCell>
                                                    <TableCell align="right"><CurrencyLabel value={buyersCommissionAmount} /></TableCell>
                                                </TableRow>

                                                {biddingCalculation?.calculations?.map((calculation: any, index: number) => (
                                                    <TableRow>
                                                        <TableCell>{calculation.description}</TableCell>
                                                        <TableCell align="right"><CurrencyLabel value={additionCostAmounts[index]} /></TableCell>
                                                    </TableRow>))}

                                                {biddingCalculation?.applyVat && <TableRow>
                                                    <TableCell>VAT ({vatPercentage.toFixed(2)} %)</TableCell>
                                                    <TableCell align="right">
                                                        <CurrencyLabel value={vatAmount} />
                                                    </TableCell>
                                                </TableRow>}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </>
                        }

                        <DialogContentText
                            style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: 24,
                                paddingTop: 10
                            }}
                        >
                            Total Amount
                            <CurrencyLabel
                                textColour={settings?.Styles?.Primary}
                                value={biddingCalculation ? total : customAmountField.customAmount}
                            />
                        </DialogContentText>
                        <DialogContentText>Happy Bidding!</DialogContentText>
                    </>}
                    onCancel={onConfirmationModalClosed}
                    onOk={onConfirmationModalOk}
                />
            </Grid>

        </React.Fragment>}
    </React.Fragment>);
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        clearResponses_d: () => dispatch(clearApiResponses()),
        submitMaximumBid_d: (auctionId: string, lotId: string, amount: number, automaticBidAmount: number, profileId: string, onCompletedCallback: (res: any) => void) => dispatch(submitMaximumBid(auctionId, lotId, amount, automaticBidAmount, profileId, onCompletedCallback))
    }
}

const mapStateToProps = (state: any) => ({
    success: state.bidding.success,
    maximumBidError: state.bidding.error,
    maximumBidErrorText: state.bidding.errorText,
    validateResult: state.participateButton.validateResult,
    profileId: state.loginForm.changeProfileId,
    loginSuccess: state.loginForm.loginSuccess,
    logoutSuccess: state.loginForm.logoutSuccess,
    settings: state.settings.settings,
})

export default connect(mapStateToProps, mapDispatchToProps)(MaximumBidButton);