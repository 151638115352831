import React, { FC, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import * as serviceWorker from './serviceWorkerRegistration';
import { FaCheck, FaSync } from 'react-icons/fa';

const NewVersionModal: FC = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        serviceWorker.register({
            onSuccess: onSWUpdate,
            onUpdate: onSWUpdate,
        });
    }, []);

    const reloadPage = async (waitingWorker) => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload();
    };

    return (
        //Even though this used to be a snackbar, it has been updated to be a non closable modal
        <Dialog open={showReload} aria-labelledby="form-dialog-title">
            <DialogContent>
                <div className="text-center">
                    <FaSync className="mb-3 text-danger" size={75} />

                    <div className="text-left">
                        <DialogContentText>A new version is available. Click 'Reload' to update.</DialogContentText>
                    </div>

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => reloadPage(waitingWorker)} variant="contained" className="btn-primary p-3 text-white text-capitalize">
                    <span className="btn-wrapper--icon">
                        <FaCheck />
                    </span>
                    <span className="btn-wrapper--label">Reload</span>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default NewVersionModal;