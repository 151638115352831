//Action
export const SHOW_PROGRESS = 'SHOW_PROGRESS';
export const HIDE_PROGRESS = 'HIDE_PROGRESS';

export interface ProgressSpinnerExtraProperties {
    size?: number,
    thickness?: number,
    description?: string,
    keepOpen?: boolean
}

export interface ProgressSpinnerState extends ProgressSpinnerExtraProperties {
    loading: boolean
}

const initialState: ProgressSpinnerState = {
    loading: true,
    size: undefined,
    thickness: undefined,
    description: undefined,
    keepOpen: false
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case SHOW_PROGRESS:
            let newState = { ...state, loading: true, ...action.payload };

            if (action.keepOpen === true)
                newState.loading = true;

            return newState;
        case HIDE_PROGRESS:
            if (action.overrideKeepOpen || !state.keepOpen) {
                return { ...state, ...initialState, loading: false }
            }
            return state;
        default: return state;
    }
}

//Action creator
export const showLoading = (props?: ProgressSpinnerExtraProperties) => (dispatch: any) => dispatch({ type: SHOW_PROGRESS, payload: props });
export const hideLoading = (overrideKeepOpen: boolean = false) => (dispatch: any) => dispatch({ type: HIDE_PROGRESS, overrideKeepOpen });