import React, { FC, memo, CSSProperties } from "react";

import { useReserveMetStatusStyles } from "./ReserveMetStatus.styles";
import { Typography } from "@mui/material";

export interface ReserveMetStatusProps {
  containerStyle?: CSSProperties;
  showReserve: boolean;
  reserveMet: boolean;
  isActive: boolean;
  now: Date;
  end: Date;
  fontSize?: string | number;
}

const ReserveMetStatus: FC<ReserveMetStatusProps> = ({
  showReserve,
  reserveMet,
  isActive,
  now,
  end,
  fontSize = "0.75rem",
}) => {
    const { classes: { container } } = useReserveMetStatusStyles();

  const getReserveMetStatusMarkup = () => {
    if (!showReserve) return <></>;

    return isActive && now < end ? (
      <div className={container}>
        <Typography
          className="pr-1 text-black font-weight-bold"
          style={{ fontSize, textAlign: "center" }}
        >
          Reserve Met:
        </Typography>
        {reserveMet ? (
          <Typography
            className="font-weight-bold"
            style={{ color: "#1bc943", fontSize, textAlign: "center" }}
          >
            Yes
          </Typography>
        ) : (
          <Typography
            className="font-weight-bold"
            style={{ color: "#f83245", fontSize, textAlign: "center" }}
          >
            No
          </Typography>
        )}
      </div>
    ) : null;
  };

  return getReserveMetStatusMarkup();
};

export default memo(ReserveMetStatus);
