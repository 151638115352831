import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Tooltip
} from '@mui/material'
import { FaFile } from "react-icons/fa";
import {
    handleDocumentsForRelatedTableRecordByPublicAccessFetch,
    handleDocumentsForRelatedTableRecordByApprovedProfileFetch,
} from "@/pages/Documents/store/Documents";
import { DocumentTypeEnum } from '@/helpers/DocumentTypeEnum';
import { PrembidLocalStorage } from "@/helpers/PrembidLocalStorage";
import DocumentPopper from '../Popper/DocumentPopper';
import { downloadFile } from "@/pages/Documents/store/Documents";
import { sortBy } from 'lodash';

interface Props {
    auctionId: string,
    lotId: string,
    profileId?: string,
    documents: any,
    relatedId: any,
    documentCount: any,

    handleDocumentsForRelatedTableRecordByPublicAccessFetch_d: (relatedTableRecordId: string, currentDocumentType: any) => void,
    handleDocumentsForRelatedTableRecordByApprovedProfileFetch_d: (relatedTableRecordId: string, auctionId: string, currentDocumentType: any, profileId: string) => void,
    downloadFile_d: (url: string, onCompletedCallback?: (res: any) => void) => void,
}

const LotDetailCardDocumentSelector: React.FC<Props> = props => {
    const {
        auctionId,
        lotId,
        profileId,
        documents,
        documentCount,
        relatedId,

        handleDocumentsForRelatedTableRecordByPublicAccessFetch_d,
        handleDocumentsForRelatedTableRecordByApprovedProfileFetch_d,
        downloadFile_d,
    } = props;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [lotDocuments, setLotDocuments] = React.useState<any>([] as any);
    const [isBusy, setIsBusy] = React.useState(false);
    const [isDocumentOpen, setIsDocumentOpen] = React.useState(false);

    const handleDocumentClick = (event) => {
        if (!isDocumentOpen) {
            setIsDocumentOpen(true);
            setAnchorEl(anchorEl ? null : event.currentTarget);

            setIsBusy(true);
            if (profileId) {
                handleDocumentsForRelatedTableRecordByApprovedProfileFetch_d(lotId, auctionId, DocumentTypeEnum.Lot, profileId);
            } else {
                handleDocumentsForRelatedTableRecordByPublicAccessFetch_d(lotId, DocumentTypeEnum.Lot);
            }
        }
    };

    const handleDocumentClose = () => {
        if (!isBusy) {
            setIsDocumentOpen(false);
            setAnchorEl(null);
        }
    };

    React.useEffect(() => {
        if (documents !== undefined && relatedId == lotId) {
            const newDocumentsData: any = [];
            let newDocCount = 0;

            Object.entries<any>(documents).forEach(([key, value]) => {
                Object.entries<any>(value).forEach(([docTemplateResultKey, docTemplateResultValue]) => {
                    if (docTemplateResultValue.documentTemplate.dataCaptured.lot.isApplied && !docTemplateResultValue.documentTemplate.dataCaptured.lot.isUploadMandatory) {
                        if (docTemplateResultValue.documents.length > 0) {
                            Object.entries<any>(docTemplateResultValue.documents).forEach(([docResultKey, docResultValue]) => {
                                const newFields: any = {
                                    documentUrl: docResultValue.documentUrl,
                                    documentTemplateName: docTemplateResultValue.documentTemplate.name,
                                };

                                if (newFields.documentUrl !== undefined && newFields.documentUrl !== null) {
                                    newDocumentsData.push(newFields);
                                    newDocCount += 1;
                                }
                            });
                        }
                    }
                });
            });

            const sortedDocuments = sortBy(newDocumentsData, ['documentTemplateName', 'documentUrl']);

            setLotDocuments(sortedDocuments);
            setIsBusy(false);
        }
    }, [documents]);

    const handleViewDocument = (url: string, onCompletedCallback?: () => void ) => {
        downloadFile_d(url, (response: any) => {
            if (response) {
                response.blob().then(blob => {
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, '_blank', 'noreferrer');
                });
            }
            onCompletedCallback?.();
        })
    }

    return (<>
        <span style={{ marginRight: '5px', fontSize: '12px' }} className="text-black">Documents</span>
        <Tooltip arrow title="View Documents" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
            <Button aria-controls="simple-menu" aria-haspopup="true" size="large" className="btn-white d-30 btn-pill p-0 btn-icon btn-animated-icon align-items-right" onClick={handleDocumentClick}>
                <div style={{
                    marginTop: -5,
                    backgroundColor: 'red',
                    width: 15,
                    height: 15,
                    borderRadius: 100,
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 1,
                }}>
                    <div style={{
                        fontSize: 12,
                        marginTop: -7,
                        marginLeft: 1,
                        color: 'white',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        alignContent: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {documentCount}
                    </div>
                </div>
                <FaFile style={{ color: '#2b2b2b' }} color={'#2b2b2b'} />
            </Button>
        </Tooltip>
        {
            anchorEl && <DocumentPopper {...{ anchorEl, isBusy, documents: lotDocuments, handleDocumentClose, handleViewDocument }} />
        }
    </>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleDocumentsForRelatedTableRecordByPublicAccessFetch_d: (relatedTableRecordId: string, currentDocumentType: any) =>
            dispatch(handleDocumentsForRelatedTableRecordByPublicAccessFetch(relatedTableRecordId, currentDocumentType)),
        handleDocumentsForRelatedTableRecordByApprovedProfileFetch_d: (relatedTableRecordId: string, auctionId: string, currentDocumentType: any, profileId: string) =>
            dispatch(handleDocumentsForRelatedTableRecordByApprovedProfileFetch(relatedTableRecordId, auctionId, currentDocumentType, profileId)),
        downloadFile_d: (url: any, onCompletedCallback?: (res: any) => void) => dispatch(downloadFile(url, onCompletedCallback)),
    }
}

const mapStateToProps = (state: any) => ({
    documents: state.documents.documents,
    relatedId: state.documents.relatedId,
    profileId: state.loginForm.changeProfileId,
});

export default connect(mapStateToProps, mapDispatchToProps)(LotDetailCardDocumentSelector);
