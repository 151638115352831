const oldConsole = window.console;

export function initializeLogging() {
    if (window.localStorage.getItem("EnableDebugLogging") === "true")
        enableDebugLogging();
    else {
        fetch('/environment-settings.json').then(response => {
            if (response.ok) {
                response.json().then((settings: any) => {
                    if (settings.enableDebugLogging || window.localStorage.getItem("EnableDebugLogging") === "true")
                        enableDebugLogging();
                    else {
                        disableDebugLogging();
                    }
                });
            }
            else {
                disableDebugLogging();
            }
        }).catch(e => {
            disableDebugLogging();
        })
    }
}

function enableDebugLogging() {
    window.console = oldConsole
}

function disableDebugLogging() {
    var newLogging = (function (oldCons) {
        return {
            ...oldCons,
            log: function (...data: any[]) {
            },
            info: function (...data: any[]) {
            },
            warn: function (...data: any[]) {
            },
        }
    })(oldConsole);
    window.console = newLogging
}