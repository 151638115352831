import React from 'react'
import DeleteAccountConfirmedCard from '@/components/DeleteAccountConfirmed/DeleteAccountConfirmedCard';

const DeleteAccountConfirmed: React.FC = () => {

    return (
        <DeleteAccountConfirmedCard/>
    )
}

export default DeleteAccountConfirmed;