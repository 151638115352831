import { memo } from 'react';
import CustomBidButton from '@/components/Buttons/CustomBidButton';

type CustomBidButtonContainerProps = {
    commission: number | null | undefined;
    biddingCalculation: any;
    auctionId: string;
    lotId: string;
    onSuccess: (amount: number, paddleNumber: string) => void;
}

const CustomBidButtonContainer = (props: CustomBidButtonContainerProps) => {
    const {
        commission,
        biddingCalculation,
        auctionId,
        lotId,
        onSuccess
    } = props;

    return (<>
        <CustomBidButton
            onBidSuccessfullySubmitted={onSuccess}
            auctionId={auctionId}
            lotId={lotId}
            commission={commission}
            biddingCalculation={biddingCalculation}
        />
    </>)
}

export default memo(CustomBidButtonContainer);