import { CircularProgress } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { finishRegistration, retrieveRegistrationProcessStatus, updateState } from "../store/RegistrationModal";
import FooterButtons from "./FooterButtons";
interface RegistrationCompleteProps {
    onClose: () => void;
    onNextStep: () => void;
    onBackStep: () => void;
    settings: any,
    state: any,
    updateState: (data: any) => void,
    finishRegistration: (data: any, onCompletedCallback?: (res: any) => void) => void,
    retrieveRegistrationProcessStatus: (profileId: string, auctionId: string, profileType: string, onCompletedCallback: (res?: any) => void) => void,
    changeToDefault: (onCompletedCallback: () => void) => void;
}

export const RegistrationComplete: React.FC<RegistrationCompleteProps> = props => {
    const { onClose, onNextStep, onBackStep, state, settings, finishRegistration, retrieveRegistrationProcessStatus, changeToDefault } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [finalizing, setFinalizing] = useState<boolean>(false);

    useEffect(() => {
        retrieveRegistrationProcessStatus(state.selectedProfileId, state.auctionId, state.profileType, (res: any) => {
            setLoading(false);
        });
    }, [])

    const handleOnClose = () => {
        //Custom logic
        onClose();
    }

    const handleBack = () => {
        //Custom logic
        onBackStep();
    }

    const handleSubmit = () => {
        setFinalizing(true);
        finishRegistration({
            auctionId: state.auctionId,
            profileId: state.selectedProfileId,
            profileType: state.profileType
        }, () => {
            const profile = state.profiles.find(x => x.id === state?.selectedProfileId);
            if (profile && !profile.isDefaultProfile) {
                changeToDefault(onNextStep);
            }
            else {
                setFinalizing(false);
                onNextStep();
            }
        })
    }

    return <div style={{ width: 500 }}>
        <h5 style={{ marginTop: 10 }}>Registration Request Complete</h5>
        {loading && <><p>Validating registration...</p>
            <div className='d-flex flex-direction-column align-items-center justify-content-center' style={{ paddingBottom: 20 }}>
                <CircularProgress className="text-center" style={{ margin: 'auto', color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />
            </div>
        </>}
        {!loading && <>
            <p>Your registration request has been successful. Please note your submitted information will be reviewed and you will be notified as soon as we have reached an outcome.</p>
            {(!state.registrationProcessStatus.profile || !state.registrationProcessStatus.acceptanceDocuments || !state.registrationProcessStatus.auctionDocuments || !state.registrationProcessStatus.profileDocuments) && <>
                <p style={{ color: 'red', paddingTop: 15 }}><b>NB!</b> You have not completed the uploading of your required documents. Please complete the document uploads at your earliest convenience.</p>
            </>}
        </>}
        <FooterButtons onSubmit={loading ? undefined : handleSubmit} locales={{ next: 'Finish' }} loading={loading || finalizing} />
    </div>
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    state: state.biddingRegistrationModal
})


const mapDispatchToProps = (dispatch: any) => {
    return {
        retrieveRegistrationProcessStatus: (profileId: string, auctionId: string, profileType: string, onCompletedCallback: (res?: any) => void) => dispatch(retrieveRegistrationProcessStatus(profileId, auctionId, profileType, onCompletedCallback)),
        updateState: (data: any) => dispatch(updateState(data)),
        finishRegistration: (data: any, onCompletedCallback?: (res: any) => void) => dispatch(finishRegistration(data, onCompletedCallback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationComplete)