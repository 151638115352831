import { ConfirmationDialogActivatorActionTypes } from "./ConfirmationDialogActivator.action-types";
import type {
  ConfirmationDialogActivatorState,
  ConfirmationDialogActivatorPayload,
} from "./ConfirmationDialogActivator.types";

type ConfirmationDialogActivatorAction = {
  type: ConfirmationDialogActivatorActionTypes;
  payload?: ConfirmationDialogActivatorPayload;
};

export const confirmationDialogActivatorState: ConfirmationDialogActivatorState =
  {
    open: false,
    title: "",
    description: "",
    payload: {},
    okPressed:false,
    cancelPressed:false
  };

export const confirmationDialogActivatorReducer = (
  state: ConfirmationDialogActivatorState = confirmationDialogActivatorState,
  { type, payload = {} }: ConfirmationDialogActivatorAction
) => {
  switch (type) {
    case ConfirmationDialogActivatorActionTypes.UPDATE:
      return { ...state, ...payload };
    case ConfirmationDialogActivatorActionTypes.RESET:
      return { ...state, ...confirmationDialogActivatorState };
    default:
      return { ...state };
  }
};
