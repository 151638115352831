import { useNavigate, useLocation } from 'react-router-dom';
export default function useHistory() {
    const navigate = useNavigate();
    const location = useLocation();

    return {
        push: navigate,
        go: navigate,
        goBack: () => navigate(-1),
        goForward: () => navigate(1),
        replace: (path: string) => navigate(path, { replace: true }),
        //listen,
        location,
    };
}