import React, { useEffect, useState } from 'react';
import { connect, useSelector } from "react-redux";
import ReactReadMoreReadLess from "@/components/ReadMoreReadLess/ReadMoreReadLess";
import { Countdown } from "@/components/library/Countdown";
import { zeroPad } from 'react-countdown';
import ParticipateButton from "@/components/Buttons/ParticipateButton";
import {
    Button,
    Card,
    Grid,
    List,
    ListItem,
    Tooltip,
} from '@mui/material'
import CurrencyLabel from "../../components/CurrencyComponent/CurrencyLabel";
import AuctionDetailCardDocumentSelector from "@/components/Cards/AuctionDetailCardDocumentSelector";
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import PopperComponent from '../Popper/PopperComponent';
import { FaInfoCircle } from 'react-icons/fa';
import { AuctionLotStatusEnum } from '../../helpers/AuctionLotStatusEnum';
import { handleClearFilterObject, handleClearLotTypeView } from '../../pages/Lots/store/Lots';
import { formatDateTime, insertUrlParam } from '../../helpers/Utils';
import useHistory from '../../helpers/hooks/useHistory';
import { getNewDateWithOffset } from 'prembid-shared-library-npm/helpers';
import { RootState } from '../../store/Store';

interface Props {
    imgSrc: string,
    header: string,
    description?: string,
    auctionId: string,
    defaultCommission: string,

    startDateTimeAt?: any,
    endDateTimeAt?: any,
    auctionEndDateTimeAt?: any,

    auctionType: string,

    infoBadgeText?: string,
    dangerBadgeText?: string,

    hasExtensions: boolean,

    settings: any,
    onRefresh: () => void
    documentCount: number,
    handleClearLotTypeView_d: (viewType: any, onCompletedCallback: () => void) => void,
    handleClearFilterObject_d: (onCompletedCallback?: () => void) => void,
}

const AuctionDetailCard: React.FC<Props> = props => {
    const history = useHistory();

    const {
        imgSrc,
        header,
        description,
        auctionId,
        defaultCommission,

        startDateTimeAt,
        endDateTimeAt,
        auctionEndDateTimeAt,

        auctionType,

        infoBadgeText,
        dangerBadgeText,

        hasExtensions,

        settings,
        onRefresh,
        documentCount,
        handleClearLotTypeView_d,
        handleClearFilterObject_d
    } = props;

    const currentDateTimeOffset = useSelector((state: RootState) => state.settings.serverDateTimeDifference);

    const [countDownDate, setCountDownDate] = React.useState<any>(undefined);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [aspectRatio, setAspectRatio] = useState(0);

    const now = getNewDateWithOffset(currentDateTimeOffset);
    const start = new Date(startDateTimeAt);
    const end = new Date(endDateTimeAt);

    const startDateTimeAtText = formatDateTime(startDateTimeAt, "dd MMMM yyyy") + " " + formatDateTime(startDateTimeAt, "hh:mm a");
    const endDateTimeAtText = formatDateTime(auctionEndDateTimeAt, "dd MMMM yyyy") + " " + formatDateTime(auctionEndDateTimeAt, "hh:mm a");


    useEffect(() => {
        if (now < start) {
            setCountDownDate(start.toISOString());
        }
        else if (start < now && now < end) {
            setCountDownDate(end.toISOString());
        }
    }, [now, start, end]);

    const recordScrollPosition = () => {
        var top = window.scrollY
        insertUrlParam("top", top.toString())
    }

    const btnViewLotsRoute = () => {
        handleClearLotTypeView_d(settings.DefaultLotView, () => {
            handleClearFilterObject_d(function () {
                recordScrollPosition();
                history.push('/Lots/' + auctionId)
            })
        })
    }

    const btnCatalogueRoute = () => {
        const win: any = window.open('/Catalog/' + auctionId, "_blank");
        win.focus();
    }

    const CountdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
        return <div>
            <h5><span>{zeroPad(days)}</span><span className="text-white" style={{ fontSize: '10px' }}> dd:</span> <span> {zeroPad(hours)}</span><span className="text-white" style={{ fontSize: '10px' }}> hh:</span> <span> {zeroPad(minutes)}</span><span className="text-white" style={{ fontSize: '10px' }}> mm:</span> <span> {zeroPad(seconds)}</span><span className="text-white" style={{ fontSize: '10px' }}> ss</span></h5>
        </div>;
    };

    const CountdownTimerComponent = () => {
        const now = getNewDateWithOffset(currentDateTimeOffset);
        const start = new Date(startDateTimeAt)
        const end = new Date(endDateTimeAt)
        var displayStarting = false;

        if (now > end) {
            return <React.Fragment />
        } else {
            if (now < start) {
                setCountDownDate(start.toISOString());
                displayStarting = true;
            }

            if (start < now && now < end) {
                setCountDownDate(end.toISOString());
                displayStarting = false;
            }


            return <React.Fragment>
                <div className="p-1" style={{ position: 'absolute', background: '#00000070', left: '10px', marginTop: '10px', width: '200px', borderRadius: '5px' }}>
                    <div className="ml-1">
                        <small className="text-white">
                            {displayStarting ? 'Starting in ' : 'Closing in '}
                        </small>
                        <small className="text-white">
                            <Countdown
                                date={countDownDate}
                                renderer={CountdownRenderer}
                                now={() => getNewDateWithOffset(currentDateTimeOffset).getTime()}
                                onComplete={() => {
                                    if (onRefresh) onRefresh();
                                }} />
                        </small>
                    </div>
                </div>
            </React.Fragment>
        }
    }

    const handleInfoPopperClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleInfoPopperClose = () => {
        setAnchorEl(null);
    };

    const getInfoElement = () => {
        const placeholderText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tender" : "bid";
        const placeholderActioningText = Number(auctionType) === Number(AuctionTypeEnum.Tender) ? "tendering" : "bidding";

        let auctionStatus = AuctionLotStatusEnum.Awaiting;

        if (now < start) auctionStatus = AuctionLotStatusEnum.Awaiting;

        if (now > end) auctionStatus = AuctionLotStatusEnum.Completed;

        if (start < now && now < end) {
            if (hasExtensions) {
                auctionStatus = AuctionLotStatusEnum.Extended;
            } else {
                auctionStatus = AuctionLotStatusEnum.Open;
            }
        }

        return (<List>
            <ListItem>
                <h5 className="text-primary font-weight-bold">{AuctionLotStatusEnum[auctionStatus.toString()]} -{" "}
                    {
                        auctionStatus === AuctionLotStatusEnum.Awaiting &&
                        <span className="text-black font-weight-normal">{"This " + settings.AuctionName.toLowerCase() + " hasn't opened yet, but you need to register if you want to " + placeholderText + " when it does"}</span>
                    }

                    {
                        (auctionStatus === AuctionLotStatusEnum.Open || auctionStatus === AuctionLotStatusEnum.Extended) &&
                        <span className="text-black font-weight-normal">{"This " + settings.AuctionName.toLowerCase() + " is open for you to " + placeholderText + " on " + settings.LotName.toLowerCase() + "s if you have registered and been approved"}</span>
                    }

                    {/*{*/}
                    {/*    auctionStatus === AuctionLotStatusEnum.Extended &&*/}
                    {/*<span className="text-black font-weight-normal">{"This " + settings.AuctionName.toLowerCase() + " has been extended due to " + placeholderActioningText + " activity, " + placeholderActioningText + " is still open on certain " + settings.LotName.toLowerCase() + "s"}</span>*/}
                    {/*}*/}

                    {
                        auctionStatus === AuctionLotStatusEnum.Completed &&
                        <span className="text-black font-weight-normal">{"This " + settings.AuctionName.toLowerCase() + " has completed and " + placeholderActioningText + " is closed"}</span>
                    }
                </h5>
            </ListItem>
            <ListItem>
                <h5 className="text-primary font-weight-bold">{AuctionTypeEnum[auctionType.toString()]} -{" "}
                    {
                        Number(auctionType) === Number(AuctionTypeEnum.Timed) &&
                        <span className="text-black font-weight-normal">{"Start " + placeholderActioningText + " at the opening " + placeholderText + " in increments. The highest " + placeholderText + " is the winner at the end of the " + settings.AuctionName.toLowerCase() + ". Note: This is binding if your " + placeholderText + " is accepted"}</span>
                    }

                    {
                        Number(auctionType) === Number(AuctionTypeEnum.Streamed) &&
                        <span className="text-black font-weight-normal">{"Allowing you to watch a live stream of an onsite " + settings.AuctionName.toLowerCase()}</span>
                    }

                    {
                        Number(auctionType) === Number(AuctionTypeEnum.Tender) &&
                        <span className="text-black font-weight-normal">{"Submit a single confidential Tender. Note: This is binding if your tender is accepted"}</span>
                    }
                </h5>
            </ListItem>
            <ListItem>
                <h5 className="text-primary font-weight-bold">Registration Deposit -{" "}
                    <span className="text-black font-weight-normal">{"The amount held as a deposit to register to " + placeholderText + ". The deposit is "}<span className="text-danger font-weight-bold">FULLY REFUNDABLE </span>{"if you are unsuccessful"}</span>
                </h5>
            </ListItem>
        </List>)
    };


    useEffect(() => {
        function getImageDimensions(url) {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => {
                    resolve({ width: img.width, height: img.height });
                };
                img.onerror = (error) => {
                    reject(error);
                };
                img.src = url;
            });
        }

        getImageDimensions(imgSrc)
            .then((res: any) => {
                setAspectRatio(res.width / res.height);
            })

    }, [imgSrc])

    return (
        <Grid item sm={12}>
            <Card className="overflow-visible">
                <div className="d-flex flex-xl-row flex-column align-items-center">
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="flex-start"
                        spacing={0}>
                        <Grid item sm={4}>
                            <div style={{ maxHeight: '232px', aspectRatio: '1.78' }}>
                                <CountdownTimerComponent />
                                <img
                                    className="img-fit-container rounded"
                                    src={imgSrc}
                                    alt="Missing"
                                    style={{ height: "100%", width: "100%", cursor: "pointer", objectFit: 'scale-down', background: aspectRatio >= 1.77 ? '#FFFFFF' : '#000000' }}
                                    onClick={btnViewLotsRoute} />
                            </div>
                        </Grid>
                        <Grid item sm={8} className="pl-2 pr-2">
                            <div className="pl-0 pl-xl-2 py-0 py-xl-2 text-center text-md-left">
                                <div className="mb-4">
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="flex-start"
                                        spacing={0}>
                                        <Grid item sm={10}>
                                            {now < start && <div className="badge badge-pill badge-dark mt-2 text-capitalize" style={{ fontSize: "10px" }}>Awaiting</div>}
                                            {start < now && now < end && hasExtensions && <div className="badge badge-pill badge-success mt-2 text-capitalize " style={{ fontSize: "10px" }}>Open</div>}
                                            {start < now && now < end && !hasExtensions && <div className="badge badge-pill badge-success mt-2 text-capitalize " style={{ fontSize: "10px" }}>Open</div>}
                                            {now > end && <div className="badge badge-pill badge-danger mt-2 text-capitalize" style={{ fontSize: "10px" }}>Completed</div>}
                                            <div className="badge badge-pill badge-dark mt-2 ml-1 text-capitalize" style={{ background: '#2B2B2B', fontSize: "10px" }}>{AuctionTypeEnum[auctionType]}</div>
                                            <div className="badge badge-pill badge-info mt-2 ml-1 text-capitalize" style={{ fontSize: "10px" }}><CurrencyLabel value={infoBadgeText} prefixString='Registration Deposit ' textColour={"text-primary"} /></div>
                                            <Tooltip arrow title="Click for Info" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                                                <Button aria-controls="simple-menu" aria-haspopup="true" size="large" className=" d-30 btn-pill p-0 btn-icon  align-items-right" onClick={handleInfoPopperClick}>
                                                    {
                                                        anchorEl && <PopperComponent headerText={"Info"} anchorEl={anchorEl} element={getInfoElement()} handlePopperComponentClose={handleInfoPopperClose} />
                                                    }
                                                    <FaInfoCircle style={{ color: '#2b2b2b' }} color={'#2b2b2b'} />
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                        <Grid item sm={2}>
                                            <AuctionDetailCardDocumentSelector auctionId={auctionId} documentCount={documentCount} />
                                        </Grid>
                                    </Grid>

                                    <h5 className="mt-4 text-black font-weight-bold">
                                        {header}
                                    </h5>
                                    <h5 className="text-black-75 mt-4" style={{ color: "#5a5a5a" }}>
                                        {"Start: " + new Date(startDateTimeAtText).toDateString() + " " + formatDateTime(startDateTimeAtText, "hh:mm a")}
                                    </h5>
                                    <h5 className="text-black-75 mb-4" style={{ color: "#5a5a5a" }}>
                                        {"End: " + new Date(endDateTimeAtText).toDateString() + " " + formatDateTime(endDateTimeAtText, "hh:mm a")}
                                    </h5>
                                    <h6 className="text-black" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                        <ReactReadMoreReadLess
                                            charLimit={90}
                                            ellipsis={"..."}
                                            readMoreText={"read more"}
                                            readLessText={"read less"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                            readMoreStyle={{ textDecoration: 'underline' }}
                                            readLessStyle={{ textDecoration: 'underline' }}>
                                            {description as any}
                                        </ReactReadMoreReadLess>
                                    </h6>
                                </div>
                                <div className="mb-4 mb-xl-0">
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-end"
                                        spacing={1}>
                                        {now <= end && <Grid item sm={4}>
                                            <ParticipateButton auctionId={auctionId} auctionType={auctionType} />
                                        </Grid>}

                                        <Grid item sm={4}>
                                            <Button href="" fullWidth onClick={btnViewLotsRoute} className="btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize" style={{ background: '#2b2b2b' }}>
                                                <span className="btn-wrapper--label">
                                                    {dangerBadgeText}
                                                </span>
                                            </Button>
                                        </Grid>
                                        <Grid item sm={4}>
                                            <Button fullWidth onClick={btnCatalogueRoute} className="btn-primary  p-3 text-white d-flex d-sm-inline-flex text-capitalize" style={{ background: '#2b2b2b' }}>
                                                <span className="btn-wrapper--label">
                                                    Catalogue
                                                </span>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </Grid>
    )
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleClearLotTypeView_d: (viewType: any, onCompletedCallback: () => void) => dispatch(handleClearLotTypeView(viewType, onCompletedCallback)),
        handleClearFilterObject_d: (onCompletedCallback?: () => void) => dispatch(handleClearFilterObject(onCompletedCallback)),
    };
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});


export default connect(mapStateToProps, mapDispatchToProps)(AuctionDetailCard);
