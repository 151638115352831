import React, { useEffect, useState } from "react";
import { apiPaths } from "../../api/ApiPaths";
import { Button, FormControlLabel, Switch, Dialog, DialogProps, DialogContent, DialogTitle } from "@mui/material";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDF = apiPaths.account.termsandconditions;

type PrivacyPolicyModalProps = {
    isDialogOpened: boolean,
    handleCloseDialog: (open: boolean) => void,
    onSubmit: () => void,
    isViewOnlyMode: boolean,
    overrideUrl?: string,
    overrideTitle?: string
}

export default function PrivacyPolicyModal({
    isDialogOpened,
    handleCloseDialog,
    onSubmit,
    isViewOnlyMode,
    overrideUrl = undefined,
    overrideTitle = undefined
}: PrivacyPolicyModalProps) {
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
    const [numPages, setNumPages] = React.useState(null);
    const [bottom, setBottom] = React.useState(false);
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = React.useState(false);


    const [file, setFile] = useState<any>(undefined);
    const [fileLoaded, setFileLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!file) setFile((overrideUrl?.startsWith("http") ? overrideUrl : overrideUrl === undefined ? PDF : PDF + '?type=' + overrideUrl ) ?? PDF);
    }, [file])

    const handleClose = (event, reason) => {
        if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
            return;

        setBottom(false);
        handleCloseDialog(false);
    };

    const submit = () => {
        onSubmit();
        handleCloseDialog(false);
    };

    const close = () => {
        handleCloseDialog(false);
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setFileLoaded(true);
    }

    const reloadFile = () => {
        setFileLoaded(false);
        setFile(undefined);
    }

    //document.addEventListener("contextmenu", (event) => {
    //    event.preventDefault();
    //});

    const handleScroll = (e) => {
        const calc = Math.floor(e.target.scrollHeight - e.target.scrollTop)
        const topVariable = calc + 10;
        const lowerVariable = calc - 10;

        if (e.target.clientHeight <= topVariable && e.target.clientHeight >= lowerVariable) {
            setBottom(true);
        } else {
            setBottom(false);
        }
    };

    const onChangeCheckbox = (e: { target: { id: any; checked: boolean; }; }) => {
        if (e.target.checked) {
            setPrivacyPolicyAccepted(true);
        } else {
            setPrivacyPolicyAccepted(false);
        }
    }

    return (
        <React.Fragment>
            {file && <Dialog
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={isDialogOpened}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                disableScrollLock>

                <DialogTitle id="max-width-dialog-title">{overrideTitle ?? 'Privacy Policy'}</DialogTitle>
                <DialogContent onScroll={handleScroll}>
                    <div>
                        <center>
                            <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}
                                error={<>
                                    <div style={{ textAlign: 'left', maxWidth: 300 }}>
                                        <p>Sorry, the PDF file failed to load</p>
                                        <p>This may have been caused by
                                            <ul>
                                                <li>A dip in your internet connection</li>
                                                <li>Having a browser extension that automatically downloads files from web pages</li>
                                            </ul>
                                        </p>
                                        <p>Please try again after resolving, we are excited to have you sign up with us</p>
                                        <p>Thank you.</p>

                                    </div>
                                    <Button
                                        size="large"
                                        onClick={reloadFile}
                                        variant="contained"
                                        className="btn-secondary mb-3 p-3 text-white m-1 text-capitalize"
                                        style={{ width: "200px", backgroundColor: '#2B2B2B' }}>
                                        <span className="btn-wrapper--label">Retry</span>
                                    </Button>
                                </>}
                                loading={<div className = "mt-3">Loading...</div>}
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        scale={1.0}
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ))}
                            </Document>
                        </center>
                    </div>
                </DialogContent>

                <div className="divider mb-2" />

                {
                    isViewOnlyMode ? (
                        <div>
                            <center>
                                <Button
                                    size="large"
                                    onClick={close}
                                    variant="contained"
                                    className="btn-primary mb-3 p-3 text-white m-1 text-capitalize"
                                    style={{ width: "200px" }}>
                                    <span className="btn-wrapper--label">Close</span>
                                </Button>
                            </center>
                        </div>
                    ) : (
                        <div>
                            {fileLoaded && <center>
                                <FormControlLabel control={<Switch onChange={onChangeCheckbox} id="accepted" className="mt-3 m-2 p-2 switch-medium toggle-switch-success" />} labelPlacement="start" label="I Accept" id="accepted" />
                            </center>}
                            <center>
                                <Button
                                    size="large"
                                    onClick={submit}
                                    disabled={bottom === false || privacyPolicyAccepted === false}
                                    variant="contained"
                                    className="btn-primary mb-3 p-3 text-white m-1 text-capitalize"
                                    style={{ width: "200px" }}>
                                    <span className="btn-wrapper--label">Accept</span>
                                </Button>
                            </center>
                        </div>
                    )
                }
            </Dialog>}
        </React.Fragment>
    );
}
