import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    link: {
        cursor: 'pointer',
    },
    containerWidth: {
        maxWidth: '1280px'
    }
}));

export default useStyles;