import React from 'react'
import ResetPasswordConfirmationCard from '@/components/ResetPasswordConfirmationCard/ResetPasswordConfirmationCard';

const ResetPasswordConfirmation: React.FC = () => {

    React.useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    return (
        <ResetPasswordConfirmationCard />
    )
}

export default ResetPasswordConfirmation
