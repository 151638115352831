import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { ConfirmationDialogActivatorPayload } from "./ConfirmationDialogActivator.types";
import { RootState } from "../Store";
import * as actions from "./ConfirmationDialogActivator.actions";

export const useConfirmationDialogActivatorStore = () => {
  const state = useSelector(
    (state: RootState) => state.confirmationDialogActivatorReducer
  );
  const dispatch = useDispatch();

  const update = useCallback(
    (payload: ConfirmationDialogActivatorPayload) =>
      dispatch(actions.updateConfirmationDialogActivator(payload)),
    [dispatch]
  );

  const reset = useCallback(
    () => dispatch(actions.resetConfirmationDialogActivator()),
    [dispatch]
  );

  return { ...state, update, reset };
};
