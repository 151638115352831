import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',

    },
    paperMobileContainer: {
        padding: '24px !important',
        paddingTop: '8px !important'
    },
    paperMobile: {
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    formInputDiv: {
        //Using pxels for width right now as percentage does not change the width

    },
    formTextField: {
        width: '100%'
    },
    submit: {
        width: '90%',
        paddingTop: '10%',
        margin: theme.spacing(1, 0, 2),
    },
    link: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    button: {
        marginBottom: '5px'
    },
    imgLogo: {
        textAlign: 'center',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        maxHeight: '100px',
        maxWidth: '300px'
    }
}));

export default useStyles;