import { extend } from 'umi-request';
import { NotificationManager } from 'react-notifications';
import { PrembidLocalStorage } from '@/helpers/PrembidLocalStorage';
import { store } from '../index';
import { handleLogout } from '@/pages/Profile/store/Profile';

const codeMessage: any = {
    200: 'The server successfully returned the requested data. ',
    201: 'New or modified data was successful. ',
    202: 'A request has entered the background queue (asynchronous task). ',
    204: 'Delete data successfully. ',
    400: 'There was an error in the request sent, and the server did not create or modify data. ',
    401: 'The user does not have permission (token, username, password is wrong). ',
    403: 'The user is authorized, but access is prohibited. ',
    404: 'The request sent was for a non-existent record, and the server did not operate. ',
    406: 'The requested format is not available. ',
    410: 'The requested resource is permanently deleted and will no longer be obtained. ',
    422: 'When creating an object, a validation error occurred. ',
    500: 'An error occurred on the server, please check the server. ',
    502: 'Gateway error. ',
    503: 'Service is unavailable, the server is temporarily overloaded or maintained. ',
    504: 'The gateway timed out. ',
};

/**
 * Exception handler
 */
const errorHandler = (error: { response: Response }): Response => {
    const { response } = error;

    if (response.status === 401) {
        PrembidLocalStorage.clearCurrentUser();
        store.dispatch(handleLogout());
    }

    if (response && response.status) {
        const errorText = codeMessage[response.status] || response.statusText;
        const { status, url } = response;

        NotificationManager.error({
            message: `Request error ${status}: ${url}`,
            description: errorText,
        });
    } else if (!response) {
        NotificationManager.error({
            description: 'Your network is abnormal and cannot connect to the server',
            message: 'Network exception',
        });
    }
    return response;
};

const request = extend({
    errorHandler,
    credentials: 'include',
});

request.interceptors.request.use((url, options) => {
    return {
        url,
        options: { ...options },
    };
});

export default request;