import React, { useEffect } from 'react';
import { connect, } from 'react-redux';
import { useParams } from 'react-router-dom';
import { handleLotDetailsDataFetch, clearApiData, clearEnquiryData, handleSendEnquiry } from './store/LotDetails';
import { Container, Grid, Card, List, ListItem } from '@mui/material';
import HeaderBlock from '@/components/HeaderBlock/HeaderBlock';
import ImageCarousel from '@/components/ImageCarousel/ImageCarousel';
import VideoCarousel from '@/components/VideoCarousel/VideoCarousel';
import ContactCard from '@/components/ContactCard/ContactCard';
import { FaMapMarkerAlt } from "react-icons/fa";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import ReactReadMoreReadLess from "@/components/ReadMoreReadLess/ReadMoreReadLess";
import ParticipateButton from "@/components/Buttons/ParticipateButton";
import { handleAuctionByIdFetch } from '@/pages/Auctions/store/Auctions';
import { AuctionTypeEnum } from '@/helpers/AuctionTypeEnum';
import Enquiry from '@/components/Enquiry/Enquiry'
import { handleRegisteredUsersValidate } from '../../components/Buttons/store/Participate';
import { PrembidLocalStorage } from '../../helpers/PrembidLocalStorage';
import GoogleMapContainer from '../../components/GoogleMapContainer/GoogleMapContainer';
import useStyles from '../LotDetails/LotDetailsStyles';
interface Props {
    handleAuctionByIdFetch_d: (auctionId: string) => void,
    handleLotDetailsDataFetch_d: (lotId: string) => void,
    handleSendEnquiry_d: (toEmailAddress: string, auctionId: string, lotId: string, name: string, email: string, cellNumber: string, message: string) => void,
    handleRegisteredUsersValidate_d: (auctionId: string, profileId: string) => void,
    clearApiData_d: () => void,
    clearEnquiryData_d: () => void,
    lotDetailsData: any,
    enquirySent: boolean,
    loading: boolean,
    settings: any,
    contact: any,
    auctionData: any,
    profileId: any,
}

const LotDetails: React.FC<Props> = (props) => {

    //const { auctionId }: any = useParams();
    const { auctionId, lotId }: any = useParams();
    const {
        handleAuctionByIdFetch_d,
        handleLotDetailsDataFetch_d,
        clearApiData_d,
        clearEnquiryData_d,
        handleSendEnquiry_d,
        handleRegisteredUsersValidate_d,
        lotDetailsData,
        enquirySent,
        loading,
        settings,
        contact,
        auctionData,
        profileId
    } = props;
    const { classes } = useStyles();
    const [lotHeaderBlock, setLotHeaderBlock] = React.useState<any>(
        {
            header: '',
            description: '',
            timerHeader: '',
            timerDescription: '',
            heroImage: ''
        });
    const [activeTab, setActiveTab] = React.useState('0');
    const [lot, setLot] = React.useState<any>(
    {
        name: '',
        description: '',
        number: ''
    })
    const [lotImages, setLotImages] = React.useState<any>([])
    const [lotVideos, setLotVideos] = React.useState<any>([])
    const [lotFeatures, setLotFeatures] = React.useState<any>([])
    const [lotAddress, setLotAddress] = React.useState({
        longitude: 0,
        latitude: 0,
        name: ''
    });

    const [displayAddressData, setDisplayAddressData] = React.useState<boolean>(true);

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' })
    }, []);

    useEffect(() => {
        if (lotId) {
            showProgressSpinner({ description: "Retrieving " + settings.LotName + " Details..." });
            handleLotDetailsDataFetch_d(lotId);
            setActiveTab('0');
        }
    }, [lotId])

    useEffect(() => {
        if (auctionId) {
            handleAuctionByIdFetch_d(auctionId)
        }
    }, [auctionId])

    useEffect(() => {
        if (auctionId) {
            handleRegisteredUsersValidate_d(auctionId, PrembidLocalStorage.currentProfile.toString());
        }
    }, [auctionId, profileId])

    useEffect(() => {
        if (lotDetailsData) {
            hideProgressSpinner();
        }
    }, [lotDetailsData])

    useEffect(() => {
        if (lotDetailsData) {
            setLotHeaderBlock({
                heroImage: lotDetailsData.lotImages.filter(file => file.isDisplayImage)[0].imageUrl
            });

            setLot(
                {
                    name: lotDetailsData?.name,
                    description: lotDetailsData?.description,
                    number: lotDetailsData?.number
                }
            )

            if (lotDetailsData?.locationVisible) {
                if ((lotDetailsData?.locationSearch && lotDetailsData?.locationSearch !== '') || (lotDetailsData?.locationLongitude !== '0' && lotDetailsData?.locationLongitude !== '0') ) {
                    setLotAddress({
                        longitude: lotDetailsData?.locationLongitude ? lotDetailsData?.locationLongitude : 0,
                        latitude: lotDetailsData?.locationLatitude ? lotDetailsData?.locationLatitude : 0,
                        name: lotDetailsData?.locationSearch ? lotDetailsData?.locationSearch : ''
                    });

                    if (!lotDetailsData?.locationLongitude || !lotDetailsData?.locationLatitude) {
                        setDisplayAddressData(false);
                    } else {
                        setDisplayAddressData(true);
                    }
                } else {
                    if (auctionData?.venue) {
                        const addressArr: string[] = [];

                        if (auctionData?.venue?.address?.addressLine1)
                            addressArr.push(auctionData?.venue?.address?.addressLine1);

                        if (auctionData?.venue?.address?.country)
                            addressArr.push(auctionData?.venue?.address?.country);

                        if (auctionData?.venue?.address?.state)
                            addressArr.push(auctionData?.venue?.address?.state);

                        if (auctionData?.venue?.address?.city)
                            addressArr.push(auctionData?.venue?.address?.city);

                        if (auctionData?.venue?.address?.zip)
                            addressArr.push(auctionData?.venue?.address?.zip);

                        setLotAddress({
                            longitude: auctionData?.venue?.address?.longitude ? auctionData?.venue?.address?.longitude : 0,
                            latitude: auctionData?.venue?.address?.latitude ? auctionData?.venue?.address?.latitude : 0,
                            name: addressArr.length > 0 ? addressArr.toString() : auctionData?.venue?.name
                        });

                        if (!auctionData?.venue?.address?.longitude || !auctionData?.venue?.address?.latitude) {
                            setDisplayAddressData(false);
                        } else {
                            setDisplayAddressData(true);
                        }
                    }
                }
            } else {
                setDisplayAddressData(false);
            }

            if (lotDetailsData?.lotImages?.length > 0) {
                let imageUrls: string[] = [];
                lotDetailsData?.lotImages?.forEach((image) => {
                    imageUrls.push(image.imageUrl);
                });

                setLotImages(imageUrls);
            }

            if (lotDetailsData?.lotVideos?.length > 0) {

                let videoUrls: string[] = [];
                lotDetailsData?.lotVideos?.forEach((video) => {
                    videoUrls.push(video.videoUrl);
                });

                setLotVideos(videoUrls);
            }

            if (lotDetailsData?.dataCaptured?.additionalProperties) {
                let featuresArr: any[] = [];

                lotDetailsData?.dataCaptured?.additionalProperties.map(x => {
                    if (x.name !== '' && x.value !== '' && !x.hidden)
                        featuresArr.push({ ...x, key: x.name });
                });

                featuresArr.sort((a, b) => a.order - b.order);

                setLotFeatures(featuresArr);
            }

            setTimeout(function () { clearApiData_d() }, 1000);

        }
    }, [auctionData, lotDetailsData])

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function CarouselComponent() {
        if (lotVideos.length > 0) {
            return <>
                <List className="nav-tabs nav-tabs-primary d-flex ml-2 mr-2">
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "0"}
                        onClick={() => {
                            toggle("0");
                        }}>
                        {"Images"}
                    </ListItem>
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "1"}
                        onClick={() => {
                            toggle("1");
                        }}>
                        {"Videos"}
                    </ListItem>
                </List>

                <div className={"tab-item-wrapper " + (activeTab === '0' ? 'active' : '')} key={0}>
                    <ImageCarousel images={lotImages} />
                </div>

                <div className={"tab-item-wrapper " + (activeTab === '1' ? 'active' : '')} key={1}>
                    <VideoCarousel videos={lotVideos} />
                </div>
            </>
        } else {
            return <>
                <List className="nav-tabs nav-tabs-primary d-flex ml-2 mr-2">
                    <ListItem
                        button
                        disableRipple
                        selected={activeTab === "0"}
                        onClick={() => {
                            toggle("0");
                        }}>
                        {"Images"}
                    </ListItem>
                </List>

                <div className={"tab-item-wrapper " + (activeTab === '0' ? 'active' : '')} key={0}>
                    <ImageCarousel images={lotImages} />
                </div>
            </>
        }
    }

    return (<div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <div>
            <HeaderBlock imageSrc={lotHeaderBlock.heroImage} />

            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                className="mt-5">
                <Grid item md={12}>
                    {
                        lot &&
                        <>
                            <div className="text-center">
                                <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>
                                    <span
                                        className="text-black-75 font-weight-bold"
                                        style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#2b2b2b" }}
                                    >
                                        {lot?.number + " | "}
                                    </span>
                                    <span
                                        className="text-black font-weight-bold"
                                        style={{
                                            whiteSpace: "pre-wrap",
                                            wordWrap: "break-word",
                                            color: settings?.Styles?.OverridePageTextColor
                                                ? settings?.Styles?.OverridePageTextColor
                                                : "#000000",
                                        }}
                                    >
                                        {lot?.name}
                                    </span>
                                </h3>
                            </div>
                            <div className="text-center">
                                <h4 style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#000000" }}>{"See everything this " + settings.LotName.toLowerCase() + " has to offer!"}</h4>
                            </div>
                        </>
                    }
                </Grid>
            </Grid>

            {auctionData && <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={0}
                className="mb-5">
                <Grid item md={4} />
                <Grid item sm={2}>
                    <div className="ml-1 mr-1">
                        <ParticipateButton auctionId={auctionId} auctionType={auctionData?.auctionType} />
                    </div>
                </Grid>
                <Grid item md={4} />
            </Grid>}

            <Container maxWidth={false} className={`pb-5 mt-5 ${classes.containerWidth}`}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    spacing={3}>
                    <Grid item md={7}>
                        <Grid item md={12} className="mb-3">
                            <CarouselComponent />
                        </Grid>

                        <Grid item md={12}>
                            <div className="mb-2 p-3">
                                <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>Description</h3>
                                <h6 className="font-size-sm" style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word', color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                    <ReactReadMoreReadLess
                                        charLimit={500}
                                        ellipsis={"..."}
                                        readMoreText={"read more"}
                                        readLessText={"read less"}
                                        readMoreClassName="read-more-less--more"
                                        readLessClassName="read-more-less--less"
                                        readMoreStyle={{ textDecoration: 'underline' }}
                                        readLessStyle={{ textDecoration: 'underline' }}>
                                        {lot?.description}
                                    </ReactReadMoreReadLess>
                                </h6>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <div className="mb-2 p-3">
                                {
                                    lotFeatures && lotFeatures.length > 0 && (
                                        <h3 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>Features</h3>
                                    )
                                }
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start">
                                    {
                                        lotFeatures.map((lotFeature, idx) => {
                                            return (
                                                <>
                                                    <Grid item xs={6}>
                                                        <h6 className=" mb-2 text-black" style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}>
                                                            {lotFeature.key}
                                                        </h6>
                                                        <h6 className="text-black-75  mb-2" style={{ color: settings?.Styles?.OverridePageSubTextColor ? settings?.Styles?.OverridePageSubTextColor : "#5a5a5a" }}>
                                                            {lotFeature.value}
                                                        </h6>
                                                    </Grid>
                                                </>)
                                        })
                                    }
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item md={4}>
                        {displayAddressData && lotAddress.latitude !== 0 && lotAddress.longitude !== 0 &&  <>
                            {
                                lotAddress.name && lotAddress.name !== '' &&
                                <div>
                                    <h6 style={{ color: settings?.Styles?.OverridePageTextColor ? settings?.Styles?.OverridePageTextColor : "#000000" }}><FaMapMarkerAlt className="mr-1" />{lotAddress.name}</h6>
                                </div>
                            }
                            <Card className="mb-5">
                                <GoogleMapContainer
                                    center={{
                                        lat: Number(lotAddress.latitude),
                                        lng: Number(lotAddress.longitude)
                                    }}
                                    canEdit={false}
                                    showLabels={false}
                                    style={{ height: 400 }}
                                />
                            </Card>
                        </>}

                        <ContactCard contactId={lotDetailsData ? lotDetailsData?.contactId : undefined} />

                        {contact?.email && <Enquiry contactEmail={contact?.email} auctionId={auctionId} lotId={lotId} />}

                    </Grid>

                </Grid>
            </Container>
        </div>
    </div>)
}

const mapStateToProps = (state: any) => ({
    lotDetailsData: state.lotDetails.lotDetailsData,
    error: state.lotDetails.error,
    errorText: state.lotDetails.errorText,
    success: state.lotDetails.success,
    enquirySent: state.lotDetails.enquirySent,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
    contact: state.contacts.contact,
    auctionData: state.auctions.auction,
    profileId: state.loginForm.changeProfileId,
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleAuctionByIdFetch_d: (auctionId: string) => dispatch(handleAuctionByIdFetch(auctionId)),
        handleLotDetailsDataFetch_d: (lotId: string) => dispatch(handleLotDetailsDataFetch(lotId)),
        handleSendEnquiry_d: (toEmailAddress: string, auctionId: string, lotId: string, name: string, email: string, cellNumber: string, message: string) => dispatch(handleSendEnquiry(toEmailAddress, auctionId, lotId, name, email, cellNumber, message)),
        clearApiData_d: () => dispatch(clearApiData()),
        clearEnquiryData_d: () => dispatch(clearEnquiryData()),
        handleRegisteredUsersValidate_d: (auctionId: string, profileId: string, onCompletedCallback?: (result: any) => void) => dispatch(handleRegisteredUsersValidate([auctionId], profileId, onCompletedCallback)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LotDetails);
