import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
//TODO
const AUCTION_BYID_FETCH_SUCCESS = 'AUCTION_BYID_FETCH_SUCCESS';
const AUCTION_DATA_FETCH_SUCCESS = 'AUCTION_DATA_FETCH_SUCCESS';
const AUCTION_DATA_FETCH_ERROR = 'AUCTION_DATA_FETCH_ERROR';
const AUCTION_BYID_FETCH_ERROR = 'AUCTION_BYID_FETCH_ERROR';
const CLEAR_AUCTION = 'CLEAR_AUCTION';

const initialState = {
    error: false,
    errorText: null,
    success: false,
    reload: false,
    auction: undefined,
    auctionData: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case AUCTION_BYID_FETCH_SUCCESS: return { ...state, error: false, auction: action.payload }
        case AUCTION_DATA_FETCH_SUCCESS: return { ...state, error: false, errorText: null, success: true, reload: !state.reload, auctionData: action.payload }
        case AUCTION_DATA_FETCH_ERROR: return { ...state, error: true, errorText: action.payload }
        case AUCTION_BYID_FETCH_ERROR: return { ...state, error: true, errorText: action.payload }
        case CLEAR_AUCTION: return { ...initialState }
        default: return state
    }
}

//Action creator
export const auctionByIdFetchSuccess = (auction: any) => {
    return { type: AUCTION_BYID_FETCH_SUCCESS, payload: auction }
}

export const auctionByIdFetchError = (errorText: string) => {
    return { type: AUCTION_BYID_FETCH_ERROR, payload: errorText }
}

export const auctionDataFetchSuccess = (auctionData: any) => {
    return { type: AUCTION_DATA_FETCH_SUCCESS, payload: auctionData }
}

export const auctionDataFetchError = (errorText: string) => {
    return { type: AUCTION_DATA_FETCH_ERROR, payload: errorText }
}

export const clearAuctionData = () => {
    return { type: CLEAR_AUCTION }
}

//Thunks
export const handleAuctionDataFetch = (onCompletedCallback?: (result: any) => void) => {

    return function (dispatch: any) {
        return requestClient.get(apiPaths.auction.retrieveAuctionPageData, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(auctionDataFetchSuccess(res.response))
                } else {
                    dispatch(auctionDataFetchError(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res)
            })
    }
}

export const handleAuctionByIdFetch = (auctionId: string, onCompletedCallback?: (res: any) => void) => {

    return function (dispatch: any) {
        return requestClient.get(apiPaths.auction.getById, null, { auctionId: auctionId })
            .then(function (res) {
                if (res.success) {
                    dispatch(auctionByIdFetchSuccess(res.response))
                } else {
                    dispatch(auctionByIdFetchError(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}