import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
    },
    link: {
        cursor: 'pointer',
    },
    successIconDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#009933',
        height: '90px',
        width: '90px',
        borderRadius: '100%',
        margin: '1rem auto'
    },
    successIcon: {
        fontSize: '60px',
        color: '#fafafa'
    },
    errorIconDiv: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f53838',
        height: '90px',
        width: '90px',
        borderRadius: '100%',
        margin: '1rem auto'
    },
    errorIcon: {
        fontSize: '60px',
        color: '#fafafa',
        backgroundColor: '#f53838'
    },
}));

export default useStyles;