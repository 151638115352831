import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
//TODO
const ALL_MY_ACTIVITY_FETCH_SUCCESS = "ALL_MY_ACTIVITY_FETCH_SUCCESS";
const ALL_MY_BIDDING_ACTIVITY_FETCH_SUCCESS = "ALL_MY_BIDDING_ACTIVITY_FETCH_SUCCESS";
const MY_ACTIVITY_UPSERT_SUCCESS = "MY_ACTIVITY_UPSERT_SUCCESS";
const MY_ACTIVITY_FETCH_ERROR = "MY_ACTIVITY_FETCH_ERROR";
const CLEAR_API_DATA = "CLEAR_API_DATA";
const SET_WISHLIST_BIDDING_NOTIFICATION_DATA = "SET_WISHLIST_BIDDING_NOTIFICATION_DATA";
const CLEAR_WISHLIST_BIDDING_NOTIFICATION_DATA = "CLEAR_WISHLIST_BIDDING_NOTIFICATION_DATA";
const SET_MY_ACTIVITY_WISHLIST_STATUS = "SET_MY_ACTIVITY_WISHLIST_STATUS";
const CLEAR_MY_ACTIVITY_WISHLIST_STATUS = "CLEAR_MY_ACTIVITY_WISHLIST_STATUS";

const initialState = {
    error: false,
    errorText: null,
    success: false,
    reload: false,
    enquirySent: false,
    contact: undefined,
    lotWishlistData: undefined,
    myBiddingActivityData: undefined,
    lotWishlistPagination: null,
    myBiddingActivityPagination: null,
    wishlistNotificationFlagObject: {
        wishlistedActivity: false,
        biddingActivityFlag: false
    },
    isMyActivityWishlistObject: {
        lotId: ""
    },

}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        case ALL_MY_ACTIVITY_FETCH_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, lotWishlistData: action.payload.list, lotWishlistPagination: action.payload.pagination }
        case ALL_MY_BIDDING_ACTIVITY_FETCH_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, myBiddingActivityData: action.payload.list, myBiddingActivityPagination: action.payload.pagination }
        case MY_ACTIVITY_UPSERT_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, lotWishlistData: action.payload.list, pagination: action.payload.pagination }
        case MY_ACTIVITY_FETCH_ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_API_DATA: return { ...state, error: false, errorText: null, lotWishlistData: undefined, myBiddingActivityData: undefined, enquirySent: false }
        case SET_WISHLIST_BIDDING_NOTIFICATION_DATA: return {
            ...state, wishlistNotificationFlagObject: { wishlistedActivity: action.payload.wishlistedActivity, biddingActivityFlag: action.payload.biddingActivityFlag }
        }
        case CLEAR_WISHLIST_BIDDING_NOTIFICATION_DATA: return { ...state, wishlistNotificationFlagObject: initialState.wishlistNotificationFlagObject }
        case SET_MY_ACTIVITY_WISHLIST_STATUS: return {
            ...state, isMyActivityWishlistObject: { lotId: action.payload.lotId }
        }
        case CLEAR_MY_ACTIVITY_WISHLIST_STATUS: return { ...state, isMyActivityWishlistObject: initialState.isMyActivityWishlistObject }
        default: return state
    }
}

//Action creator
export const clearMyActivityWishlistStatus = () => {
    return { type: SET_MY_ACTIVITY_WISHLIST_STATUS, payload: initialState.isMyActivityWishlistObject }

}
export const setMyActivityWishListStatus = (payload: any) => {
    return { type: SET_MY_ACTIVITY_WISHLIST_STATUS, payload: payload }
}

export const setMyActvityWishlistData = (payload: any) => {
    return { type: SET_WISHLIST_BIDDING_NOTIFICATION_DATA, payload: payload }
}

export const clearMyActvityWishlistData = () => {
    return { type: CLEAR_WISHLIST_BIDDING_NOTIFICATION_DATA, payload: initialState.wishlistNotificationFlagObject }
}

export const lotWishlistDetailsFetchSuccess = (payload: any) => {
    return { type: ALL_MY_ACTIVITY_FETCH_SUCCESS, payload: payload }
}

export const myBiddingActivityDetailsFetchSuccess = (payload: any) => {
    return { type: ALL_MY_BIDDING_ACTIVITY_FETCH_SUCCESS, payload: payload }
}

export const lotWishlistUpsertSuccess = (payload: any) => {
    return { type: ALL_MY_ACTIVITY_FETCH_SUCCESS, payload: payload }
}

export const lotWishlistFetchError = (errorText: string) => {
    return { type: MY_ACTIVITY_FETCH_ERROR, payload: errorText }
}

export const clearApiData = () => {
    return { type: CLEAR_API_DATA, payload: CLEAR_API_DATA }
}

//Thunks
export const handleSetMyActivityWishListStatus = (payload: any, onCompletedCallback?: () => void) => {
    return function (dispatch: any) {
        dispatch(setMyActivityWishListStatus(payload));
        if (onCompletedCallback) onCompletedCallback();
    }
}

export const handleSetWishlistBiddingNotification = (payload: any, onCompletedCallback?: () => void) => {
    return function (dispatch: any) {
        dispatch(setMyActvityWishlistData(payload));
        if (onCompletedCallback) onCompletedCallback();
    }
}

export const handleClearWishlistBiddingNotification = (onCompletedCallback?: () => void) => {
    return function (dispatch: any) {
        dispatch(clearMyActvityWishlistData());
        if (onCompletedCallback) onCompletedCallback();
    }
}

export const handleRetrieveAllLotWishlist = (profileId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lotWishlist.retrieveAllLotWishlist, {
            profileId,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleRetrieveAllMyBiddingActivity = (profileId: string, filters: any, pagination: any, onCompletedCallback?: (res: any) => void) => {
    if (!pagination) pagination = { currentPage: 1, pageSize: 10 };
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lotWishlist.retrieveAllMyBiddingActivity, {
            profileId,
            pagination,
            ...(filters || {})
        }).then(function (res) {
            if (onCompletedCallback) onCompletedCallback(res);
        })
    }
}

export const handleLotWishlistDataUpsert = (lotWishlistData: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.post(apiPaths.lotWishlist.upsertLotWishlist, lotWishlistData)
            .then(function (res) {
                if (res.success) {
                    dispatch(lotWishlistDetailsFetchSuccess(res.response))
                } else {
                    dispatch(lotWishlistFetchError(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}

export const handleLotWishlistItemStatusCheck = (profileId: any, lotId: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.get(`${apiPaths.lotWishlist.lotWishlistItemStatusCheck}/${profileId}/${lotId}`, null, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(lotWishlistDetailsFetchSuccess(res.response))
                } else {
                    dispatch(lotWishlistFetchError(res.error))
                }
                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}


export const handleLotWishlistDataDelete = (lotWishlistData: any, onCompletedCallback?: (res: any) => void) => {
    return function (dispatch: any) {
        return requestClient.delete(apiPaths.lotWishlist.deleteLotWishlist, null, lotWishlistData)
            .then(function (res) {
                if (res.success) {
                    dispatch(lotWishlistDetailsFetchSuccess(res.response))
                } else {
                    dispatch(lotWishlistFetchError(res.error))
                }

                if (onCompletedCallback) onCompletedCallback(res);
            })
    }
}