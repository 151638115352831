import React, { FC, memo, CSSProperties } from "react";

import { useCardActionButtonsStyles } from "./CardActionButtons.styles";
import { AuctionTypeEnum } from "@/helpers/AuctionTypeEnum";
import { Button } from "@mui/material";

import { useNavigator } from "@/helpers/hooks/useNavigator";
import { LotStatus } from "../../../helpers/LotStatusEnum";

export interface CardActionButtonsProps {
  containerStyle?: CSSProperties;
  isActive: boolean;
  auctionType: AuctionTypeEnum;
  auctionId: string;
  lotId: string;
  overrideShowBidButtonForCompletedLots: boolean;
    status?: LotStatus;
}

const CardActionButtons: FC<CardActionButtonsProps> = ({
  containerStyle,
  auctionType,
  isActive,
  auctionId,
  lotId,
  overrideShowBidButtonForCompletedLots,
  status,
}) => {
  const styles = useCardActionButtonsStyles();

  const { btnBidRoute, btnViewLotDetailsRoute } = useNavigator({
    lotId,
    auctionId,
  });

  return (
    <div className={styles.classes.actionButtonsContainer} style={containerStyle}>
      {Number(auctionType) !== Number(AuctionTypeEnum.Streamed.toString()) &&
        isActive &&
              (status === LotStatus.STARTED ||
          (overrideShowBidButtonForCompletedLots &&
            Number(auctionType) ===
              Number(AuctionTypeEnum.Timed.toString()))) && (
          <Button
            href=""
            fullWidth
            onClick={btnBidRoute}
            className={`${styles.classes.actionButton} btn-primary p-2 text-white text-capitalize`}
          >
            <span className="btn-wrapper--label">
              {Number(auctionType) === Number(AuctionTypeEnum.Tender.toString())
                ? "Tender"
                : overrideShowBidButtonForCompletedLots
                ? "View Bid History"
                : "Bid"}
            </span>
          </Button>
        )}

      {isActive && (
        <Button
          href=""
          fullWidth
          onClick={btnViewLotDetailsRoute}
          className={`${styles.classes.actionButton} btn-primary p-2 text-white text-capitalize`}
          style={{ background: "#2b2b2b", marginLeft: 2 }}
        >
          <span className="btn-wrapper--label">Info</span>
        </Button>
      )}
    </div>
  );
};

export default memo(CardActionButtons);
