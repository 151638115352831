import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    biddingHistoryTable: {
        display: 'block',
        height: '200px',
        overflowY: 'scroll'
    },
    biddingCard: {
        background: '#000',
        boxShadow: '0 0 2px #FFFFFF',
    },
    containerWidth: {
        maxWidth: '1280px'
    }
}));

export default useStyles;