import { DialogConfig, SHOW_DIALOG } from '@/components/Dialog/store/Dialog';
import { VariantType } from 'notistack';
import { store } from '../index';
import { Button } from '@mui/material';
import React, { Fragment } from 'react';
import { enqueueSnackbar, closeSnackbar } from '@/components/NotificationServiceWrapper/store/NotificationServiceWrapper';


enum DefaultMessages {
    Error = 'Oops something went wrong. Please try again and if the problem persists contact support.',
    NetworkError = 'Oops something went wrong. Please ensure that you have a stable internet connection. If the problem persists contact support.',
}

const custom = (messageId: string, message: string, actions?: { buttonText: string, onClick: (key) => void }[], variant: VariantType = 'default',
    closable: boolean = true,
    horizontalLocation: 'left' | 'right' | 'center' = 'left',
    verticalLocation: 'top' | 'bottom' = 'top') => {

    if (message)
        return store.dispatch(enqueueSnackbar({
            messageId,
            message,
            options: {
                variant: variant,
                anchorOrigin: { horizontal: horizontalLocation, vertical: verticalLocation },
                action: key => <Fragment>
                    {actions?.map((a, index) => <Button color="secondary" className='text-white' size="small" onClick={() => a.onClick(key)} key={index}>
                        {a.buttonText}
                    </Button>)
                    }
                    {closable ? <Button color="secondary" size="small" className='text-white' onClick={() => store.dispatch(store.dispatch(closeSnackbar(key)))}>
                        Close
                    </Button> : undefined}
                </Fragment >
            }
        }));
    return undefined;
}

const information = (messageId: string, message: string,
    closable: boolean = true,
    horizontalLocation: 'left' | 'right' | 'center' = 'left',
    verticalLocation: 'top' | 'bottom' = 'top') => {

    if (message)
        return store.dispatch(enqueueSnackbar({
            messageId,
            message,
            options: {
                variant: 'info',
                anchorOrigin: { horizontal: horizontalLocation, vertical: verticalLocation },
                action: key => (
                    closable ? <Button color="secondary" size="small" className='text-white' onClick={() => store.dispatch(closeSnackbar(key))}>
                        Close
                    </Button> : undefined
                ),
            }
        }));
    return undefined;
}

const warning = (messageId: string, message: string,
    closable: boolean = true,
    horizontalLocation: 'left' | 'right' | 'center' = 'left',
    verticalLocation: 'top' | 'bottom' = 'top') => {

    if (message)
        return store.dispatch(enqueueSnackbar({
            messageId,
            message,
            options: {
                variant: 'warning',
                anchorOrigin: { horizontal: horizontalLocation, vertical: verticalLocation },
                action: key => (
                    closable ? <Button color="secondary" size="small" className='text-white' onClick={() => store.dispatch(closeSnackbar(key))}>
                        Close
                    </Button> : undefined
                ),
            }
        }));
    return undefined;
}

const success = (messageId: string, message: string,
    closable: boolean = true,
    horizontalLocation: 'left' | 'right' | 'center' = 'left',
    verticalLocation: 'top' | 'bottom' = 'top') => {

    if (message)
        return store.dispatch(enqueueSnackbar({
            messageId,
            message,
            options: {
                variant: 'success',
                anchorOrigin: { horizontal: horizontalLocation, vertical: verticalLocation },
                action: key => (
                    closable ? <Button color="secondary" size="small" className='text-white' onClick={() => store.dispatch(closeSnackbar(key))}>
                        Close
                    </Button> : undefined
                ),
            }
        }));
    return undefined;
}

const error = (messageId: string, message: string,
    closable: boolean = true,
    horizontalLocation: 'left' | 'right' | 'center' = 'left',
    verticalLocation: 'top' | 'bottom' = 'top') => {

    if (message)
        return store.dispatch(enqueueSnackbar({
            messageId,
            message,
            options: {
                variant: 'error',
                anchorOrigin: { horizontal: horizontalLocation, vertical: verticalLocation },
                action: key => (
                    closable ? <Button color="secondary" className='text-white' size="small" onClick={() => store.dispatch(closeSnackbar(key))}>
                        Close
                    </Button> : undefined
                ),
            }
        }));
    return undefined;
}

const showDialog = (config: DialogConfig) => {
    if (config) store.dispatch({ type: SHOW_DIALOG, config });
}

export default { success, error, information, warning, custom, showDialog, DefaultMessages };