import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    navBar: {
        margin: '1rem auto 0 auto',
        width: '100%',
    },
    link: {
        cursor: 'pointer',
    },
    linksDivider: {
        height: '2.5rem',
        width: '.5px',
        backgroundColor: '#ffffff'
    },
    linksButton: {
        margin: '1rem',
    },
    loginCard: {
        padding: '1rem',
    },
    logoImg: {
        minHeight: '100px',
        maxHeight: '100px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    header: {
        paddingRight: "118px",
        paddingLeft: "118px",
        "@media (max-width: 900px)": {
            paddingLeft: 0,
        },
    },
    permissionHeader: {
        zIndex: 999
    },
    menuButton: {
        fontWeight: 700,
        size: "18px",
        marginLeft: "38px",
    },
    toolbar: {
        display: "flex",
        justifyContent: "space-between",
    },
    toolbarWebsite: {
        display: "flex",
        justifyContent: "flex-end",
    },
    drawerContainer: {
        padding: "100px 40px 99px 80px",
    },
    largeIcon: {
        fontSize: "2em !important",
    },
    buttonPadding: {
        paddingRight: "30% !important"
    },
    paper: {
        right: "50px",
    },
}))

export default useStyles;