export enum AuctionTypeEnum {
    'Timed' = 0,
    'Streamed' = 1,
    'Tender' = 2
}

export const AuctionTypesList = (): any[] => {
    const types = [] as any;
    for (var enumMember in AuctionTypeEnum) {
        var isValueProperty = parseInt(enumMember, 10) >= 0
        if (isValueProperty) {
            types.push({
                value: enumMember,
                label: AuctionTypeEnum[enumMember]
            });
        }
    }
    return types;
}