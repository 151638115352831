import { makeStyles } from "tss-react/mui";

export const useCardActionButtonsStyles = makeStyles()((theme) => ({
  actionButtonsContainer: {
    width: "45%",
    [theme.breakpoints.down(365)]: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
    [theme.breakpoints.between(650, 1120)]: {
      marginTop: theme.spacing(1),
      width: "100%",
    },
  },
  actionButton: {
    height: "100%",
    fontSize: 12,
    minHeight: 45
  },
}));
