import React from 'react'
import ForgotPasswordCard from '@/components/ForgotPasswordCard/ForgotPasswordCard';

const ForgotPassword: React.FC = () => {

    return (
        <ForgotPasswordCard />
    )
}

export default ForgotPassword
