import React, { CSSProperties, useEffect, useState } from 'react'

type VersionProps = {
    style?: CSSProperties;
    shortened?: boolean;
}

export default function Version({ style = {}, shortened = false }: VersionProps) {
    useEffect(() => {
        checkVersion();
    }, []);

    const [version, setVersion] = useState('Loading...');

    const checkVersion = async () => {
        try {
            const response = await fetch('/version.txt');
            if (response.ok) {
                var version = await response.text();
                setVersion(version);
            }
        }
        catch (error) {
            setVersion("Version not found");
        }
    }

    return shortened ? <span style={{ opacity: 0.4, fontSize: '0.8em', ...style }}>{version}</span> : <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', opacity: 0.3, fontSize: '0.8em', ...style }}>
        <div>Version: {version}</div>
    </div>
}
