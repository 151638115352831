import { ConfirmationDialogActivatorActionTypes } from "./ConfirmationDialogActivator.action-types";
import type { ConfirmationDialogActivatorPayload } from "./ConfirmationDialogActivator.types";

export const updateConfirmationDialogActivator = (
  payload: ConfirmationDialogActivatorPayload
) => ({
  type: ConfirmationDialogActivatorActionTypes.UPDATE,
  payload,
});

export const resetConfirmationDialogActivator = () => ({
  type: ConfirmationDialogActivatorActionTypes.RESET,
});
