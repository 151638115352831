import { Button, CircularProgress, Divider, FormControl, FormControlLabel, Grid, MenuItem, Select, Switch, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { MdDomain, MdDomainAdd } from "react-icons/md";
import { connect } from 'react-redux';
import { handleChangeProfile } from "../../LoginForm/store/LoginForm";
import { retrieveProfiles, retrieveRegistrationProcessStatus, updateState } from "../store/RegistrationModal";
import FooterButtons from "./FooterButtons";

interface ProfileSelectorProps {
    onClose: () => void;
    onNextStep: () => void;
    onBackStep: () => void;
    settings: any,
    state: any,
    updateState: (data: any) => void,
    retrieveProfiles: (onCompletedCallback?: (res?: any) => void, setProfile?: boolean) => void,
    retrieveRegistrationProcessStatus: (profileId: string, auctionId: string, profileType: string, onCompletedCallback: (res?: any) => void) => void,
    skipProfileSelect?: boolean,
    handleChangeProfile_d: (profileId: string) => any,
    onSetAsDefault: (onCancel?: () => void) => void;
    isSettingProfileAsDefault: boolean;
}

export const ProfileSelector: React.FC<ProfileSelectorProps> = props => {
    const { onClose, onNextStep, onBackStep, state, settings, updateState, retrieveProfiles, retrieveRegistrationProcessStatus, skipProfileSelect = false, handleChangeProfile_d, onSetAsDefault, isSettingProfileAsDefault } = props;

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingRegistrationStatus, setLoadingRegistrationStatus] = useState<boolean>(false);

    useEffect(() => {
        reloadProfiles(!state.selectedProfileId);
    }, [isSettingProfileAsDefault]);

    useEffect(() => {
        if (!loading) {
            if (state.profiles?.length > 0 && state.setSelectedProfileId) {
                const profile = state.profiles.find(x => x.id === state.setSelectedProfileId);
                updateState({ selectedProfileId: state.setSelectedProfileId, setSelectedProfileId: null, profileType: profile?.type })
            }
            if (!loadingRegistrationStatus && !state.setSelectedProfileId && state.selectedProfileId && skipProfileSelect) {
                handleSubmit();
            }
            else {
                if (state.registrationProcessStatus) {
                    if (!state.registrationProcessStatus.profile) updateState({ step: 'ADDRESS_CAPTURE' });
                    else if (!state.registrationProcessStatus.acceptanceDocuments) updateState({ step: 'DOCUMENT_ACCEPTANCE' });
                    else if (!state.registrationProcessStatus.profileDocuments || !state.registrationProcessStatus.auctionDocuments) updateState({ step: 'DOCUMENT_UPLOADS' });
                    else updateState({ step: 'FINISH' });
                }
            }
        }
    }, [state, loading, loadingRegistrationStatus])

    const reloadProfiles = (setProfile: boolean) => {
        retrieveProfiles(() => { setLoading(false) }, setProfile);
    }

    const onAdd = () => {
        updateState({ step: "PROFILE_ADD" })
    }

    const handleSelect = (e: any) => {
        const profile = state.profiles.find(x => x.id === e.target.value);
        updateState({ selectedProfileId: e.target.value, profileType: profile?.type })
    }

    const handleOnClose = () => {
        //Custom logic
        onClose();
    }

    const handleSubmit = () => {
        setLoadingRegistrationStatus(true);
        retrieveRegistrationProcessStatus(state.selectedProfileId, state.auctionId, state.profileType, (res: any) => {
            setLoadingRegistrationStatus(false);
            handleChangeProfile_d(state.selectedProfileId);
        });
    }

    const [type, setType] = useState<"Individual" | "Company">();
    const [defaultProfile, setDefaultProfile] = useState<boolean>(false);

    useEffect(() => {
        if (type) {
            const profile = state.profiles.find(x => x.id === state?.selectedProfileId);
            if (profile) setDefaultProfile(profile.isDefaultProfile);
        }
    }, [type, state?.selectedProfileId])

    useEffect(() => {
        if (!skipProfileSelect && type) {
            let defaultProfile = state.profiles.filter(x => x.type === type).find(x => x.isDefaultProfile);
            if (!defaultProfile) {
                defaultProfile = state.profiles.filter(x => x.type === type)?.[0];
            }
            if (defaultProfile) {
                updateState({ selectedProfileId: defaultProfile.id, profileType: defaultProfile?.type });
            }
        }
    }, [type])

    return <div style={{ width: '100%', maxWidth: '650px' }}>
        <h5 style={{ marginTop: 10 }}>Profile Selection</h5>
        <Divider style={{ marginBottom: 10 }} />
        {(skipProfileSelect || loading) && <><p>Loading profiles...</p>
            <div className='d-flex flex-direction-column align-items-center justify-content-center' style={{ paddingBottom: 20 }}>
                <CircularProgress className="text-center" style={{ margin: 'auto', color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />
            </div>
        </>}
        {!skipProfileSelect && <>
            {loadingRegistrationStatus && <><p>Loading registration status...</p>
                <div className='d-flex flex-direction-column align-items-center justify-content-center' style={{ paddingBottom: 20 }}>
                    <CircularProgress className="text-center" style={{ margin: 'auto', color: settings?.Styles?.OverrideLoadingSpinnerColor ? settings?.Styles?.OverrideLoadingSpinnerColor : settings?.Styles?.Primary }} />
                </div>
            </>}
            {!loading && !loadingRegistrationStatus && <>
                <p>Please select the profile you want to register with.</p>
                <p style={{ color: 'red' }}><b>Note</b>, Bidders representing a company must create and register with a company profile for invoicing purposes.</p>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingBottom: 20, flexWrap: 'wrap' }}>
                    <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', gap: 20, marginTop: 10  }}>
                        <Button onClick={() => setType('Individual')} variant={type === 'Individual' ? "contained" : "outlined"}
                            className={`btn-${type === 'Individual' ? 'primary' : 'default'} p-3 text-${type === 'Individual' ? 'white' : 'black'} text-capitalize`}
                            style={{
                                borderColor: settings?.Styles?.Primary,
                                color: type === 'Individual' ? 1 : settings?.Styles?.Primary,
                            }}>
                            <span className="btn-wrapper--icon">
                                <FaUser size={16} />
                            </span>
                            <span className="btn-wrapper--label">Individual</span>
                        </Button>
                        <Button onClick={() => setType('Company')} variant={type === 'Company' ? "contained" : "outlined"}
                            className={`btn-${type === 'Company' ? 'primary' : 'default'} p-3 text-${type === 'Company' ? 'white' : 'black'} text-capitalize`}
                            style={{
                                borderColor: settings?.Styles?.Primary,
                                color: type === 'Company' ? 1 : settings?.Styles?.Primary,
                            }}>
                            <MdDomain size={20} />
                            <span className="btn-wrapper--label">Company</span>
                        </Button>
                    </div>
                    {type === 'Company' && <Button onClick={onAdd} className={`btn-primary p-3 text-white text-capitalize`} style={{ marginTop: 10 }} >
                        <MdDomainAdd size={20} />
                        <span className="btn-wrapper--label">Create Company</span>
                    </Button>}
                </div>
                {type && <>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        spacing={0} >
                        <Grid item xs={12}>
                            <FormControl style={{ width: '100%' }} variant="outlined">
                                <Select
                                    margin='dense'
                                    onChange={handleSelect}
                                    value={state?.selectedProfileId}
                                    name="profileId"
                                    fullWidth
                                    required
                                    SelectDisplayProps={{
                                        style: { display: 'flex', flexDirection: 'row' }
                                    }}
                                    MenuProps={{
                                        MenuListProps: {
                                            style: { display: 'flex', flexDirection: 'column', maxHeight: 300 }
                                        },
                                    }}
                                    disabled={state?.profiles?.filter(x => x.type === type).length <= 1}
                                >
                                    {state?.profiles?.filter(x => x.type === type).map(profile => <MenuItem key={profile.id} value={profile.id}>
                                        <>
                                            <div style={{ flex: 5, marginRight: '5px' }}>
                                                <div style={{
                                                    width: '100%',
                                                    whiteSpace: 'normal',
                                                    overflowWrap: 'break-word',
                                                    wordWrap: 'break-word',
                                                    wordBreak: 'break-all',
                                                    hyphens: 'auto'
                                                }}>{profile.name}</div>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                                {profile.type}
                                            </div>
                                        </>
                                    </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id='isDefaultProfile'
                                        className="m-2 p-2 switch-medium toggle-switch-success"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                setDefaultProfile(e.target.checked);
                                                onSetAsDefault(() => setDefaultProfile(false));
                                            }
                                        }}
                                        checked={defaultProfile}
                                    />
                                }
                                label="Is Default Profile"
                            />
                        </Grid>
                    </Grid>
                </>}
            </>}
        </>
        }
        <FooterButtons onClose={handleOnClose} onSubmit={(skipProfileSelect || loading || !type) ? undefined : handleSubmit} loading={loading || loadingRegistrationStatus} />
    </div >
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
    state: state.biddingRegistrationModal
})

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateState: (data: any) => dispatch(updateState(data)),
        retrieveProfiles: (onCompletedCallback?: (res?: any) => void, setProfile?: boolean) => dispatch(retrieveProfiles(onCompletedCallback, setProfile)),
        retrieveRegistrationProcessStatus: (profileId: string, auctionId: string, profileType: string, onCompletedCallback: (res?: any) => void) => dispatch(retrieveRegistrationProcessStatus(profileId, auctionId, profileType, onCompletedCallback)),
        handleChangeProfile_d: (profileId: string) => dispatch(handleChangeProfile(profileId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSelector)