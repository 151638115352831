import React from 'react';
import { connect } from "react-redux";

interface Props {
    priceWithSymbol: any,
    prefixString?: string,
    suffixString?: string,
    noWrap?: boolean
    settings: any,
    textColour?: string,
    style?: any;
    disabled?: boolean;
}

const CurrencyComponent: React.FC<Props> = (props) => {
    const { priceWithSymbol, prefixString, suffixString, settings, textColour, style, disabled } = props;

    const [currency] = React.useState({
        firstCurrency: settings.CurrencySymbol,
    });

    return (
        <div style={{
            whiteSpace: "nowrap",
            wordWrap: "initial",
            color: textColour ? textColour : "#000000",
            ...style,
            opacity: disabled ? 0.5 : 1
        }}>{prefixString} {currency.firstCurrency} {priceWithSymbol} {suffixString}
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
    };
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});


export default connect(mapStateToProps, mapDispatchToProps)(CurrencyComponent);