import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    loginCard: {
        
    },
    logoImg: {
        maxHeight: '70px',
        maxWidth: '70px',
        marginLeft: '.5rem',
    },

    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto',
        padding: '20px'
      },
}))

export default useStyles;