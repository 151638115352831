import request from "./RequestClientConfig";
import { environment } from "../enviroment/enviroment";
import { PrembidLocalStorage } from '../helpers/PrembidLocalStorage'

async function get(path: string, pathParams: any, queryParams: any, headers?: any, responseType?: any) {
    return await getUrl(environment.baseUrl, path, pathParams, queryParams, headers, responseType);
};

async function post(path: string, payload: any) {
    return await postUrl(environment.baseUrl, path, payload);
};

async function del(path: string, payload: any, pathParams: any) {
    return await deleteUrl(environment.baseUrl, path, payload, pathParams);
};

async function downloadFile(path: string, pathParams: any, queryParams: any, headers?: any) {
    return await downloadFileFromUrl(environment.baseUrl, path, pathParams, queryParams, headers);
};

async function getUserId() {
    const user = PrembidLocalStorage.currentUser;
    if (user === null)
        return '';

    if (user.userId)
        return user.userId;
    else
        return '';
};

async function getToken() {
    const user = PrembidLocalStorage.currentUser;
    if (user === null)
        return '';

    if (user.jwtToken)
        return user.jwtToken;
    else
        return '';
};

async function getUrl(url: string, path: string, pathParams: any, queryParams: any, headers?: any, responseType?: any) {

    const token = await getToken();
    const userId = await getUserId();
    // path params
    for (let key in pathParams) {
        path = path.replace(":" + key, pathParams[key]);
    }

    const resType = responseType ? responseType : 'json';
    const allHeaders = headers ? headers : {};
    allHeaders['Authorization'] = `Bearer ${token}`;
    allHeaders['UserId'] = userId;

    return request(`${url + path}`, {
        method: 'get',
        params: queryParams, //url query params
        headers: allHeaders,
        responseType: resType
    });
};

async function postUrl(url: string, path: string, payload: any) {

    const token = await getToken();
    const userId = await getUserId();

    return request(`${url + path}`, {
        method: 'post',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'UserId': userId
        }
    });
};

async function deleteUrl(url: string, path: string, payload: any, pathParams: any) {
    const token = await getToken();
    const userId = await getUserId();

    // path params
    for (let key in pathParams) {
        path = path.replace(":" + key, pathParams[key]);
    }


    return request(`${url + path}`, {
        method: 'delete',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'UserId': userId
        }
    });
};

async function downloadFileFromUrl(url: string, path: string, pathParams: any, queryParams: any, headers?: any) {
    const token = await getToken();
    const userId = await getUserId();

    // path params
    for (let key in pathParams) {
        path = path.replace(":" + key, pathParams[key]);
    }

    const allHeaders = headers ? headers : {};
    allHeaders['Authorization'] = `Bearer ${token}`;
    allHeaders['UserId'] = userId;

    return request(`${url + path}`, {
        method: 'get',
        params: queryParams, //url query params
        headers: allHeaders,
        responseType: 'arrayBuffer',
        parseResponse: false
    });
};

const requestClient = {
    get,
    post,
    delete: del,
    getUrl,
    postUrl,
    deleteUrl,
    downloadFile
};

export default requestClient;