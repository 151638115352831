import React from 'react';
import {
    TableRow, TableCell, TableHead, Button, Tooltip, TableSortLabel
} from '@mui/material';
import PropTypes from 'prop-types'
import { FaPlus } from 'react-icons/fa'

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, onAdd, actions  /*GL: Should this be removed? If so, should it be removed from prop types? , dataModel */ } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {props.dataModel.headers.map((header, index) => (
                    <TableCell
                        key={header.title}
                        align={header.align}
                        sortDirection={orderBy === header.title ? order : false}>
                        <TableSortLabel
                            active={orderBy === header.title}
                            direction={orderBy === header.title ? order : 'asc'}
                            onClick={createSortHandler(header.title)}>
                            <strong>{header.title}</strong>
                            {orderBy === header.title ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell align="right">
                    {actions && actions?.map((btn, index) => (
                        <React.Fragment key={index}>{btn}</React.Fragment>
                    ))}
                    {
                        onAdd && <Tooltip arrow title="Add" placement="bottom" PopperProps={{ style: { zIndex: 0 } }}>
                            <Button color='primary' className="btn-primary mx-1 rounded-sm shadow-none hover-scale-sm d-40 border-0 p-0 d-inline-flex align-items-center justify-content-center text-capitalize text-white text-center" onClick={onAdd} disableFocusRipple={true}>
                                <FaPlus />
                            </Button>
                        </Tooltip>
                    }
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default EnhancedTableHead;

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onAdd: PropTypes.func,
    dataModel: PropTypes.object.isRequired,
    actions: PropTypes.any,
};