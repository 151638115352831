import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/Index';

const preloadedState = {

}

export default function configureAppStore() {
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    // Ignore these action types
                    ignoredActions: [],
                    // Ignore these field paths in all actions
                    ignoredActionPaths: ['notification', 'config'],
                    // Ignore these paths in the state
                    ignoredPaths: ['notificationServiceWrapper', 'dialog'],
                },
            }),
        preloadedState
    })

    return store;
}

const store = configureAppStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
