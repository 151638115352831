import { memo } from 'react';
import TenderButton from '@/components/Buttons/TenderButton';

type TenderBidButtonContainerProps = {
    auctionId: string;
    lotId: string;
    onSuccess: (amount: number) => void;
    tenderAmount: number;
}

const TenderBidButtonContainer = (props: TenderBidButtonContainerProps) => {
    const {
        auctionId,
        lotId,
        onSuccess,
        tenderAmount
    } = props;

    return (<>
        <TenderButton
            onTenderSuccessfullySubmitted={onSuccess}
            auctionId={auctionId}
            lotId={lotId}
            tenderAmount={tenderAmount}
        />
    </>)
}

export default memo(TenderBidButtonContainer);