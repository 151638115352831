import React from "react";
import { connect } from "react-redux";
import useStyles from './DeleteAccountConfirmationCardStyles';
import {
    Container,
    Button
} from '@mui/material';
import { MdDone } from "react-icons/md";
import { useParams } from 'react-router-dom';
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import { handleDeleteAccountConfirmation } from './../../pages/Profile/store/Profile';
import ns from '@/helpers/NotificationService';
import useHistory from "../../helpers/hooks/useHistory";

interface Props {
    settings: any,
    handleDeleteAccountConfirmation_d: (userId: string, tokenId: string, onCompletedCallback: (response: any) => void) => void,
}

const DeleteAccountConfirmationCard: React.FC<Props> = props => {

    const { classes } = useStyles()
    const history = useHistory();

    const { userId }: any = useParams();
    const { tokenId }: any = useParams();

    const {
        settings,
        handleDeleteAccountConfirmation_d
    } = props;

    const navigateForward = () => {
        let path = '/deleteAccountConfirmed';
        history.push(path);
    }

    const navigateBack = () => {
        let path = '/home';
        history.push(path);
    }

    const onSubmit = () => {
        showProgressSpinner({ description: 'Deleting Account...' });
        handleDeleteAccountConfirmation_d(userId, tokenId, (res) => {
            if (res.success) {
                navigateForward();
            }
            else {
                ns.error("", res.error)
            }
            hideProgressSpinner();
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper} style={{ marginLeft: "29" }}>
                <img className={classes.imgLogo + ' mb-4 center'} src={settings.VendorLogoUrl} />
                <h3 className="text-black text-center mb-4">Are you sure you want to delete your account?</h3>
            </div>

            <h5 className="mb-1">Take note that this cannot be undone and once you have submitted this form your account will be permanently deleted.</h5>

            <div className={classes.paper} style={{ marginLeft: "29" }}>
                <Button
                    className="btn-primary p-3 text-white text-capitalize"
                    type="submit"
                    onClick={onSubmit}>
                    Submit
                </Button>
                <Button
                    variant="contained" className="btn-primary p-3 mt-2 text-white text-capitalize"
                    style={{ background: '#2b2b2b' }}
                    type="submit"
                    onClick={navigateBack}>
                    Cancel
                </Button>
            </div>
        </Container>
    )

}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleDeleteAccountConfirmation_d: (userId: string, tokenId: string, onCompletedCallback: (response: any) => void) => dispatch(handleDeleteAccountConfirmation(userId, tokenId, onCompletedCallback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteAccountConfirmationCard);
