import React from 'react'
import ResetPasswordCard from '@/components/ResetPasswordCard/ResetPasswordCard';

const ResetPassword: React.FC = () => {

    return (
        <ResetPasswordCard />
    )
}

export default ResetPassword
