import React, { useReducer } from 'react'
import {
    CircularProgress,
    List,
    ListItem,
    Divider,
} from '@mui/material';

export default function DocumentsInnerComponent(props) {
    const [loading, updateLoading] = useReducer((state, action) => {
        if (action.type === 'add') {
            return [...state, action.id]
        } else if (action.type === 'remove') {
            return state.filter((id) => id !== action.id)
        }

        return state;
    }, [])

    const generateDocumentsList = (docs: any[], loading: any[]) => {
        let elements: any[] = [];

        docs.forEach((document, index, list) => {
            elements.push(<ListItem key={"documentId" + index }>
                <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
                    <div style={{ flex: 1, paddingRight: 15, wordBreak: 'break-word' }}>{document.documentTemplateName}</div>
                    {loading.includes(document.documentUrl) ? <CircularProgress size={20} style={{ marginRight: 10 }} className="text-primary text-center center" /> : <div><a className="text-info" href={undefined} onClick={() => {
                        updateLoading({ type: 'add', id: document.documentUrl })
                        setTimeout(() => {
                            props.handleViewDocument(document.documentUrl, () => {
                                updateLoading({ type: 'remove', id: document.documentUrl })
                            })
                        }, 50)
                    }} style={{ textDecoration: 'underline', cursor: 'pointer' }}>View</a></div>}
                </div>
            </ListItem>);

            if (index !== list.length - 1)
                elements.push(<Divider />);
        })

        return elements;
    }

    return (<>
        {props.isBusy ?
            <CircularProgress className=" m-4 text-primary text-center center" size={40} />
            : <React.Fragment>
                <List
                    component="div"
                    className="nav-neutral-primary text-left d-block px-3 pb-3">
                    {props.lotDocuments instanceof Array && props.lotDocuments.length > 0 ?
                        generateDocumentsList(props.lotDocuments, loading)
                        : <ListItem>No Documents</ListItem>
                    }
                </List>
            </React.Fragment>}
    </>);
}