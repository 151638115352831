import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { handleSubmit, resetPasswordResetState } from './store/ResetPasswordCard';
import useStyles from './ResetPasswordCardStyles';
import ns from '@/helpers/NotificationService';
import {
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    CssBaseline,
    Container,
    FormControl,
    InputLabel,
    Tooltip,
    Grid
} from '@mui/material';
import { passwordStrongRegex, passwordValidationError } from "@/helpers/Regex"
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { hideProgressSpinner, showProgressSpinner } from '@/helpers/ProgressSpinnerService';
import PasswordChecklist from "react-password-checklist"
import { FaCheckCircle } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import useHistory from '../../helpers/hooks/useHistory';

interface Props {
    handleSubmit_d: (userId: string, tokenId: string, password: string) => void,
    resetPasswordResetState_d: () => void,
    error: boolean,
    errorText: string,
    success: boolean,
    settings: any,
    loading: boolean
}

const ResetPasswordCard: React.FC<Props> = props => {
    const history = useHistory();
    const { userId }: any = useParams();
    const { tokenId }: any = useParams();

    const {
        handleSubmit_d,
        resetPasswordResetState_d,
        error,
        errorText,
        success,
        settings,
        loading
    } = props;

    const { classes } = useStyles()

    const intialValues = {
        password: "",
        confirmPassword: "",
        errorPasswordMessage: "",
        errorEmailMessage: "",
        passwordLength: [] as any,
        passwordLengthInfo: [] as any
    };

    const [formFields, setFormFields] = useState(intialValues);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [imgSrc, setImgSrc] = React.useState<any>(undefined);

    const onChange = (e: { target: { id: any; value: string; }; }) => {
        let passwordError = "";

        if (e.target.id === "password" && formFields.password.length < 7) {
            passwordError = "Password is too short";
        }

        setFormFields({
            ...formFields,
            [e.target.id]: e.target.value,
            errorPasswordMessage: passwordError
        });
    }

    const isPasswordValid = (password: any, passwordConfirmation: any) => {
        if (!password || !passwordConfirmation) return false;
        return password === passwordConfirmation;
    };


    const passLengthValidation = (value: any) => {
        const re = passwordStrongRegex;
        return re.test(value);
    };

    const onSubmit = () => {
        setFormFields({
            ...formFields,
            errorPasswordMessage: isPasswordValid(formFields.password, formFields.confirmPassword)
                ? ''
                : ' Password did not match',
            passwordLength: passLengthValidation(formFields.password) ? '' : passwordValidationError
        });

        if (
            !isPasswordValid(formFields.password, formFields.confirmPassword) ||
            !passLengthValidation(formFields.password)
        ) {
            return;
        }

        showProgressSpinner({ description: 'Resetting Password...' });
        handleSubmit_d(userId, tokenId, formFields.password)
    }

    useEffect(() => {
        hideProgressSpinner();

        if (success) {
            redirect();
        }
        resetPasswordResetState_d();

    }, [success]);

    useEffect(() => {
        hideProgressSpinner();

        if (error) {
            ns.error('', errorText);
        }
        resetPasswordResetState_d();

    }, [error]);

    React.useEffect(() => {
        setImgSrc(settings.VendorLogoUrl)
    }, [settings])

    const redirect = () => {
        let path = '/ResetPasswordConfirmation';
        history.push(path);
    };

    const navigateBack = () => {
        let path = '/home';
        history.push(path);
    };

    const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
    const handleToggleConfirmPassword = () => setShowConfirmPassword(showConfirmPassword => !showConfirmPassword);
    const onKeyPress = e => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (<>
        {!loading && <>
            <Container component="main" maxWidth="xs">
                <div className={classes.paper} style={{ marginLeft: "29" }}>
                    <img className={classes.imgLogo + ' mb-4 center'} src={imgSrc} />
                    <h3 className="text-black text-center">Reset Password</h3>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    value={formFields.password}
                                    onChange={onChange}
                                    onKeyPress={onKeyPress}
                                    fullWidth
                                    margin="dense"
                                    required
                                    id="password"
                                    type={showPassword ? "text" : "password"}
                                    label="Password"
                                    placeholder="Password"
                                    autoFocus={true}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleTogglePassword}
                                            >
                                                {showPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>

                            {/*{formFields.passwordLength.length > 0 && (<Alert severity="error">*/}
                            {/*    <div className="d-flex align-items-center align-content-center">*/}
                            {/*        <span>*/}
                            {/*            {formFields.passwordLength[0]} <br />*/}
                            {/*            {formFields.passwordLength[1]} <br />*/}
                            {/*            {formFields.passwordLength[2]} <br />*/}
                            {/*            {formFields.passwordLength[3]} <br />*/}
                            {/*            {formFields.passwordLength[4]}*/}
                            {/*        </span>*/}
                            {/*    </div>*/}
                            {/*</Alert>)}*/}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={0}>
                        <Grid item xs={12}>
                            <FormControl fullWidth margin="dense" variant="outlined">
                                <InputLabel htmlFor="password">Confirm Password</InputLabel>
                                <OutlinedInput
                                    onChange={onChange}
                                    onKeyPress={onKeyPress}
                                    fullWidth
                                    margin="dense"
                                    required
                                    id="confirmPassword"
                                    label='Confirm Password'
                                    type={showConfirmPassword ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleToggleConfirmPassword}
                                            >
                                                {showConfirmPassword ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {/*{formFields.errorPasswordMessage && (<Alert severity='error'>{formFields.errorPasswordMessage}</Alert>)}*/}
                        </Grid>
                    </Grid>

                    <PasswordChecklist
                        className="text-black font-size-sm mt-1"
                        rules={["minLength", "capital", "lowercase", "number", "specialChar", "match"]}
                        minLength={6}
                        value={formFields.password}
                        valueAgain={formFields.confirmPassword}
                        messages={{
                            minLength: "Min 6 Characters",
                            capital: "One Uppercase Letter",
                            lowercase: "One Lowercase Letter",
                            number: "One Number",
                            specialChar: "One Special Character",
                            match: "Passwords Must Match",
                        }}
                        iconComponents={{
                            ValidIcon: <FaCheckCircle className="text-success mr-2" size={22} />,
                            InvalidIcon: <MdCancel className="text-danger mr-2" size={22} />,
                        }}
                    />

                    <Button
                        className="btn-primary p-3 mt-4 text-white text-capitalize"
                        type="submit"
                        onClick={onSubmit}>
                        Submit
                    </Button>
                    <Button
                        variant="contained" className="btn-danger p-3 mt-2 text-white text-capitalize"
                        style={{ background: '#2b2b2b' }}
                        type="submit"
                        onClick={navigateBack}>
                        Cancel
                    </Button>
                </div>
            </Container>
        </>}
    </>)
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleSubmit_d: (userId: string, tokenId: string, password: string) => dispatch(handleSubmit(userId, tokenId, password)),
        resetPasswordResetState_d: () => dispatch(resetPasswordResetState())
    }
}

const mapStateToProps = (state: any) => ({
    error: state.resetPasswordCard.error,
    errorText: state.resetPasswordCard.errorText,
    success: state.resetPasswordCard.success,
    token: state.resetPasswordCard.token,
    settings: state.settings.settings,
    loading: state.progressSpinner.loading
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordCard)
