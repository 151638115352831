import { apiPaths } from '@/api/ApiPaths';
import requestClient from '@/api/RequestClient';

//Action
//TODO
const LOT_DETAILS_FETCH_SUCCESS = "LOT_DETAILS_FETCH_SUCCESS";
const LOT_DETAILS_FETCH_ERROR = "LOT_DETAILS_FETCH_ERROR";
const CLEAR_API_DATA = "CLEAR_API_DATA";
const CLEAR_LOT_ENQUIRY_SENT = "CLEAR_LOT_ENQUIRY_SENT";
const SEND_LOT_ENQUIRY = "SEND_LOT_ENQUIRY";
const RETRIEVED_CONTACT_SUCCESS = "RETRIEVED_CONTACT_SUCCESS";

const initialState = {
    error: false,
    errorText: null,
    success: false,
    reload: false,
    lotDetailsData: undefined,
    enquirySent: false,
    contact: undefined,
}

export default function reducer(state = initialState, action: any) {
    switch (action.type) {
        //TODO
        case LOT_DETAILS_FETCH_SUCCESS: return { ...state, error: false, errorText: null, reload: !state.reload, lotDetailsData: action.payload }
        case LOT_DETAILS_FETCH_ERROR: return { ...state, error: true, errorText: action.payload, success: false }
        case CLEAR_API_DATA: return { ...state, error: false, errorText: null, lotDetailsData: undefined, enquirySent: false }
        case CLEAR_LOT_ENQUIRY_SENT: return { ...state, enquirySent: false }
        case SEND_LOT_ENQUIRY: return { ...state, enquirySent: true }
        case RETRIEVED_CONTACT_SUCCESS: return { ...state, contact: action.payload }
        default: return state
    }
}

//Action creator
export const lotDetailsFetchSuccess = (lotDetailsData: any) => {
    return { type: LOT_DETAILS_FETCH_SUCCESS, payload: lotDetailsData }
}

export const lotDetailsFetchError = (errorText: string) => {
    return { type: LOT_DETAILS_FETCH_ERROR, payload: errorText }
}

export const clearApiData = () => {
    return { type: CLEAR_API_DATA, payload: CLEAR_API_DATA }
}

export const clearEnquiryData = () => {
    return { type: CLEAR_LOT_ENQUIRY_SENT, payload: CLEAR_LOT_ENQUIRY_SENT }
}

export const sendEnquirySuccess = () => {
    return { type: SEND_LOT_ENQUIRY, payload: SEND_LOT_ENQUIRY }
}

export const retrievedContact = (contact: any) => {
    return { type: RETRIEVED_CONTACT_SUCCESS, payload: contact }
}

//Thunks
export const handleLotDetailsDataFetch = (lotId: any) => {

    let requestLotDetailsPageData = {
        lotId: lotId
    }

    return function (dispatch: any) {
        return requestClient.get(apiPaths.lots.retrieveLotDetailsPageData, null, requestLotDetailsPageData)
            .then(function (res) {
                if (res.success) {
                    let lotDetailsData = res.response;
                    dispatch(lotDetailsFetchSuccess(lotDetailsData))
                } else {
                    dispatch(lotDetailsFetchError(res.error))
                }
            })
    }
}

export const handleSendEnquiry = (toEmailAddress: string, auctionId: string, lotId: string, name: string, email: string, cellNumber: string, message: string) => {

    let enquiry = {
        toEmailAddress: toEmailAddress,
        auctionId: auctionId,
        lotId: lotId,
        name: name,
        email: email,
        cellNumber: cellNumber,
        message: message
    }

    return function (dispatch: any) {
        return requestClient.post(apiPaths.lots.sendEnquiry, enquiry)
            .then(function (res) {
                if (res.success) {
                    dispatch(sendEnquirySuccess())
                } else {
                    dispatch(lotDetailsFetchError(res.error))
                }
            })
    }
}

export const handleContactFetch = (contactId: string) => {
    return function (dispatch: any) {
        
        return requestClient.get(apiPaths.contact.retrieveContact, { contactId }, null)
            .then(function (res) {
                if (res.success) {
                    dispatch(retrievedContact(res.response))
                } else {
                    //TODO: Error handling here
                    
                }
            })

    }
}