import React, { useEffect } from "react";
import { connect } from "react-redux";
import { handleUserAccountFetch, handleUserAccountUpdate, resetAccountState, handleDeleteAccount } from "./store/Profile";
import { Button, Grid, Card, CardContent, TextField, Container } from "@mui/material";
import ns from '@/helpers/NotificationService';
import PasswordConfirmationDialog from '@/components/Modal/PasswordConfirmationDialog'
import { hideProgressSpinner, showProgressSpinner } from "@/helpers/ProgressSpinnerService";
import { FaSave, FaTimes, FaTrash } from 'react-icons/fa'
import { ConfirmationDialog } from "@/components/Modal/confirmationModal";
import { handleLogout } from "@/components/LoginForm/store/LoginForm";
import useHistory from "../../helpers/hooks/useHistory";
import useStyles from '../Profile/ProfileStyles';

interface Props {
    handleLogout_d: () => void,
    handleDeleteAccount_d: (onCompletedCallback: (response: any) => void) => void,
    handleUserAccountFetch_d: () => void,
    handleUserAccountUpdate_d: (user: any) => void,
    resetAccountState_d: () => void,
    userAccount: any,
    userAccountUpdateState: any,
    loading: boolean,
    settings: any,
}

const ProfileEdit: React.FC<Props> = (props) => {
    const { handleLogout_d,
        handleDeleteAccount_d,
        handleUserAccountFetch_d,
        handleUserAccountUpdate_d,
        resetAccountState_d,
        userAccount,
        userAccountUpdateState,
        loading,
        settings,
    } = props;

    const history = useHistory();
    const { classes } = useStyles();
    const [confirmationState, setConfirmationState] = React.useState({
        open: false
    });

    const [deletionState, setDeletionState] = React.useState<any>({
        open: false,
        id: ''
    });

    const [formFields, setFormFields] = React.useState({
        email: '',
        phoneNumber: '',
        userId: '',
    });

    const [formFieldsValidationShowErrors, setFormFieldsValidationShowErrors] = React.useState<any>({
        email: false,
        phoneNumber: false,
    });

    const navigateLogout = () => {
        handleLogout_d();

        let path = `/deleteAccount`;
        history.push(path);
    };

    useEffect(() => {
        showProgressSpinner({ description: "Retrieving Account..." });
        handleUserAccountFetch_d();

        return () => {
            resetAccountState_d();
        }
    }, []);

    useEffect(() => {
        if (userAccount) {
            setFormFields({ email: userAccount.email, phoneNumber: userAccount.phoneNumber, userId: userAccount.id });
            hideProgressSpinner();
        }
    }, [userAccount]);

    useEffect(() => {
        setConfirmationState({ ...confirmationState, open: false })
        hideProgressSpinner();

        if (!(userAccountUpdateState.errorText === undefined || userAccountUpdateState.errorText === null))
            ns.error('', userAccountUpdateState.errorText);

        else if (!(userAccountUpdateState.successText === undefined || userAccountUpdateState.successText === null))
            ns.success('', userAccountUpdateState.successText);

    }, [userAccountUpdateState]);

    const onGoBack = () => {
        history.push("/home");
    };

    const onChangeUppercase = (e: any) => {
        const props = e.target.id.split(".");
        setFormFields({ ...formFields, [props[0]]: e.target.value.toUpperCase() });
    }

    const onChange = (e: any) => {
        const props = e.target.id.split(".");
        setFormFields({ ...formFields, [props[0]]: e.target.value });
    }

    const validate = () => {
        let valid = true;

        let newState = {
            email: false,
            phoneNumber: false,
        }

        for (const [key, value] of Object.entries(formFieldsValidationShowErrors)) {
            const formFieldsvalue = formFields[key];
            if (formFieldsvalue === '') {
                newState[key] = true;
                valid = false;
                break;
            }
            else {
                newState[key] = false;
            }
        }

        setFormFieldsValidationShowErrors(newState);

        return valid;
    }

    const validateField = (e: any) => {
        let showError = false;
        if (e.target.value === '')
            showError = true;

        if (e.target.id !== undefined)
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.id]: showError })
        else
            setFormFieldsValidationShowErrors({ ...formFieldsValidationShowErrors, [e.target.name]: showError })
    }


    const handleSave = () => {
        const isValid = validate();
        if (isValid) {
            setConfirmationState({ open: true });
        } else {
            ns.error('', "Please fill in all required fields");
        }
    };

    const handleConfirmationOk = (password) => {
        showProgressSpinner({ description: "Saving Account..." });
        handleUserAccountUpdate_d({ ...formFields, password })
    }

    const handleDelete = () => {
        setDeletionState({ open: true });
    };

    const handleDeletionOk = () => {
        handleDeletionCancel();

        showProgressSpinner({ description: "Sending Account Deletion Email..." });
        handleDeleteAccount_d((res) => {
            if (res.success) {
                navigateLogout();
            }
            else {
                ns.error("", "Failed to send account deletion email")
            }
            hideProgressSpinner();
        });
    }

    const handleDeletionCancel = () => {
        setDeletionState({ open: false });
    }

    return (<div style={{ background: settings?.Styles?.OverridePageBackgroundColor ? settings?.Styles?.OverridePageBackgroundColor : "#ffffff" }}>
        <PasswordConfirmationDialog
            cancelButtonText='Cancel'
            okButtonText='Submit'
            title='Confirm Password'
            onOk={handleConfirmationOk}
            onCancel={() => setConfirmationState({ ...confirmationState, open: false })}
            open={confirmationState.open}
            description='Please enter your current password'
        />

        <ConfirmationDialog
            open={deletionState.open}
            payload={deletionState.id}
            okButtonText="Ok"
            cancelButtonText="Cancel"
            description={"Please note, if you have registered for an " + settings.AuctionName + " or bid on any " + settings.LotName + "s, we reserve the right to retain your transactional data as we are required to do so."}
            title="Delete Account"
            onCancel={handleDeletionCancel}
            onOk={handleDeletionOk}
        />

        <div className="mb-5 pt-5 pl-5 pr-5 pb-4 mt-5" />
        <Container className={`pb-5 ${classes.containerWidth}`}>
            {userAccount && <>
                <div className={"d-flex align-items-center mb-3 mt-4"}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="baseline"
                        spacing={3}>
                        <Card className="shadow-xxl pb-4 pt-4 justify-content-center w-100">
                            <CardContent>
                                <div>
                                    <h3 className="text-black" >My Account</h3>
                                    <div className="card-header dpy-3">
                                        <div className="card-header--title font-size-lg">Log In Account Information</div>
                                    </div>
                                    <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <div className="p-3">
                                                <TextField
                                                    margin="dense"
                                                    className='m-1'
                                                    id="email"
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Email"
                                                    type="text"
                                                    value={formFields.email}
                                                    onChange={onChangeUppercase}
                                                    required
                                                    inputProps={{ readOnly: true }}
                                                    onBlur={validateField}
                                                    error={formFieldsValidationShowErrors.email}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <div className="p-3">
                                                <TextField
                                                    margin="dense"
                                                    className='m-1'
                                                    defaultValue={userAccount.phoneNumber}
                                                    id="phoneNumber"
                                                    fullWidth
                                                    variant="outlined"
                                                    label="Phone Number"
                                                    type="number"
                                                    value={formFields.phoneNumber}
                                                    onChange={onChange}
                                                    inputProps={{ readOnly: true }}
                                                    onKeyDown={(e) =>
                                                        (e.keyCode === 69 || e.keyCode === 109 || e.keyCode === 107 || e.keyCode === 110 || e.keyCode === 190 || e.keyCode === 188 || e.keyCode === 189 || e.keyCode === 187) && e.preventDefault()
                                                    }
                                                    required
                                                    onBlur={validateField}
                                                    error={formFieldsValidationShowErrors.phoneNumber}
                                                />
                                            </div>
                                        </Grid>

                                    </Grid>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </div>
                <div className="text-right mt-2 mr-2 ml-2">
                    <Button
                        variant="contained"
                        onClick={handleDelete}
                        className="btn-primary p-3 text-white d-flex d-sm-inline-flex text-capitalize"
                        style={{ background: '#2b2b2b' }}>
                        <span className="btn-wrapper--icon">
                            <FaTrash />
                        </span>
                        <span className="btn-wrapper--label">Delete Account</span>
                    </Button>
                    {/*<Button
                        onClick={onGoBack}
                        variant="contained"
                        className="btn-danger p-3 text-white m-1 text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaTimes />
                        </span>
                        <span className="btn-wrapper--label">Back</span>
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        className="btn-primary p-3 text-white m-1 text-capitalize">
                        <span className="btn-wrapper--icon">
                            <FaSave />
                        </span>
                        <span className="btn-wrapper--label">Save</span>
                    </Button>*/}
                </div>
            </>}
        </Container>
    </div>);
};

const mapStateToProps = (state: any) => ({
    userAccount: state.account.userAccount,
    userAccountUpdateState: state.account.userAccountUpdateState,
    loading: state.progressSpinner.loading,
    settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        handleUserAccountFetch_d: () => dispatch(handleUserAccountFetch()),
        handleUserAccountUpdate_d: (user) => dispatch(handleUserAccountUpdate(user)),
        resetAccountState_d: () => dispatch(resetAccountState()),
        handleDeleteAccount_d: (onCompletedCallback: (response: any) => void) => dispatch(handleDeleteAccount(onCompletedCallback)),
        handleLogout_d: () => dispatch(handleLogout()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileEdit);
