import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    mapContainer: {
        zIndex: 1,
    },
    divSpacing: {
        margin: theme.spacing(0, 0, 0, 0),
    },
    containerWidth: {
        maxWidth: '1280px'
    }
}))

export default useStyles;