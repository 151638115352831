import { makeStyles } from "tss-react/mui";

export const useCardBadgeRowStyles = makeStyles()((theme) => ({
  cardBadgeRow: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    gap: 1.5,
    [theme.breakpoints.down(590)]: {
      gap: 2,
    },
    width: "100%",
    [theme.breakpoints.down(430)]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.between(590, 1120)]: {
      paddingTop: theme.spacing(1),
    },
  },
  badgeSection: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: 3,
    [theme.breakpoints.down(720)]: {
      gap: 2,
    },
    [theme.breakpoints.down(590)]: {
      gap: 4,
    },
  },
  controlsSection: {
    display: "flex",
    gap: 2,
    marginLeft: 6,
    [theme.breakpoints.down(720)]: {
      marginLeft: 4,
    },
    [theme.breakpoints.down(590)]: {
      marginLeft: 8,
    },
  },
  badgeItem: {
    fontSize: 8.5,
  },
}));
