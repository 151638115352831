import React from 'react';
import { Card } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import ReactPlayer from 'react-player/lazy';
import useStyles from './VideoCarouselStyles';

require("@/assets/css/carousel.css");

interface Props {
    videos: Array<any>,
}

const VideoCarousel: React.FC<Props> = props => {

    const {
        videos,
    } = props;

    const { classes } = useStyles();

    const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

    const getVideoThumb = (videoId) => `https://img.youtube.com/vi/${videoId}/default.jpg`;

    function youtube_parser(url){
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : false;
    }

    const customRenderThumb = (children) =>
        children.map((item) => {
            const videoId = youtube_parser(item.props.url);
            return <img src={getVideoThumb(videoId)} />;
        });

    const YoutubeSlide = ({ url }: { url: string}) => (
         <div className="carousel-wrapper">
            <ReactPlayer width="auto%" height="100%" url={url} controls={true} />
        </div>
    );
    return (
        <Card className={classes.detailCard}>
            {
                <Carousel renderItem={customRenderItem} renderThumbs={customRenderThumb}>
                    {
                        videos.map((videoUrl, idx) => (
                            <YoutubeSlide key={idx} url={videoUrl}  />
                        ))
                    }
                </Carousel>
            }
            </Card>
    )
}

export default VideoCarousel