import React from 'react';
import { Card } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import useStyles from './ImageCarouselStyles';

require("@/assets/css/carousel.css");

interface Props {
    images: Array<any>,
}

const ImageCarousel: React.FC<Props> = props => {

    const {
        images,
    } = props;

    const { classes } = useStyles();

    return (
        <Card  >
                <Carousel 
                    stopOnHover={true}
                    infiniteLoop={true}
                    autoPlay={false}
                    emulateTouch={true}
                    showStatus={true}
                    useKeyboardArrows={true}
                    showThumbs={true}
                    dynamicHeight={false}>

                {

                    images.map((imageUrl) => (
                        <div className={`carousel-wrapper ${classes.detailCard}`}>
                            <img src={imageUrl} style={{ height: '100%', objectFit: 'contain' }} />
                        </div>
                      
                    ))
                }
               
                </Carousel>
            </Card>
    )
}

export default ImageCarousel
