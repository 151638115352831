import React, { useEffect, useState } from 'react';
import { TablePagination } from '@mui/material';
import { connect } from 'react-redux';
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()({
    label: {
        fontSize: 12,
        margin: 0,
    },
});

interface Props {
    onHandleChangePage?: (payload: any) => void;
    pagination: any;
    rowsPerPageOptions?: number[];
    userFilters?: any;
    loading?: boolean;
    settings: any,
}

const Pagination: React.FC<Props> = (props: Props) => {
    const { classes } = useStyles();

    const { pagination, onHandleChangePage, rowsPerPageOptions = [10, 15, 20, 25, 35, 50], loading = false, settings } = props;
    const lotName = settings?.LotName + "s:";

    const [pageSize, setPageSize] = useState<number>(pagination?.pageSize || 10);
    const [currentPage, setCurrentPage] = useState<number>(pagination?.currentPage || 1);

    const handleChangePage = (event: any, newPage: any) => {
        let newCurrentPage = newPage || 0;
        newCurrentPage++;

        if (onHandleChangePage) onHandleChangePage({ currentPage: newCurrentPage, pageSize });
    };

    useEffect(() => {
        setPageSize(pagination?.pageSize || 10)
        setCurrentPage(pagination?.currentPage || 1)
    }, [pagination])

    const handleChangeRowsPerPage = (event: any) => {
        let newPageSize = event?.target?.value || 10;

        setPageSize(newPageSize);
        if (onHandleChangePage) onHandleChangePage({ currentPage, pageSize: newPageSize });
    };

    return <TablePagination
        classes={{
            root: classes.label,
            menuItem: classes.label,
            actions: classes.label,
            input: classes.label,
            selectRoot: classes.label,
            toolbar: classes.label,
            spacer: classes.label,
            select: classes.label,
        }}
        style={{ color: settings?.Styles?.OverridePagingTextColor ? settings?.Styles?.OverridePagingTextColor : "#000000" }}
        slotProps={{
            select: {
                disabled: loading,
                style: { color: "#000000", background: "#ffffff", borderRadius: "10px", marginRight: 10 }
            },
            actions: {
                previousButton: {
                    disabled: loading,
                    style: { margin: 0, marginLeft: 10, padding: 0.  }
                },
                nextButton: {
                    disabled: loading,
                    style: { margin: 0, padding: 0 }
                }
            }
        }}
        component='div'
        count={pagination?.total || 0}
        page={currentPage - 1}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
        labelRowsPerPage={" "}
        sx={{
            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel, .MuiTablePagination-select": {
                "margin-top": "1em",
                "margin-bottom": "1em",
                "font-size": "12px",
               
            }, ".MuiTablePagination-actions": {
                "margin-left": "0px"
            }
        }}
    />
}

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

export default connect(mapStateToProps)(Pagination);