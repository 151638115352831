import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  root: {
    minWidth: "100%",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  headerBackground: {
    backgroundColor: "grey",
  },
  detailsHeader: {
    fontWeight: 600,
  },
  detailsSpan: {
    fontWeight: 300,
  },
  paper: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  paperButtons: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  },
  favourites: {
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "none",
    marginLeft: "10%",
  },

  boxShadow: {
    boxShadow: "none",
  },

  margin: {
    margin: theme.spacing(1),
    paddingRight: "78px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 151,
    backgroundColor: "white",
  },
  searchCard: {
    marginTop: "25px",
  },
  mainDivSpacing: {
    margin: theme.spacing(0, 0, 15, 0),
  },

  compactLotCardsContainer: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: 8,
    [theme.breakpoints.between(700, 1120)]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    [theme.breakpoints.down(590)]: {
      gridTemplateColumns: "repeat(1, 1fr)",
    },
    },
    containerWidth: {
        maxWidth: '1280px'
    }
}));

export default useStyles;
