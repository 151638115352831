import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
    },
    imgLogo: {
        textAlign: 'center',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
        maxHeight: '100px',
        maxWidth: '300px'
    }
}));

export default useStyles;