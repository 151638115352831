import { makeStyles } from "tss-react/mui";

export const useReserveMetStatusStyles = makeStyles()((theme) => ({
  container: {
    display: "flex",
    [theme.breakpoints.down(430)]: {
      paddingTop: theme.spacing(0.5),
    },
  },
}));
