import { makeStyles } from "tss-react/mui";

export const useLotFeaturesStyles = makeStyles()((theme) => ({
  lotFeaturesContainer: {
    width: "100%",
    height: 28,
    display: "flex",
    justifyContent: "space-evenly",
    marginBottom: theme.spacing(1.2),
    marginTop: theme.spacing(1.2),
    [theme.breakpoints.between(960, 1120)]: {
      height: 20,
    },
    [theme.breakpoints.between(850, 960)]: {
      height: 25,
    },
    [theme.breakpoints.between(760, 850)]: {
      height: 20,
    },
    [theme.breakpoints.between(700, 760)]: {
      height: 19
    },
    [theme.breakpoints.between(590, 700)]: {
      height: 25,
    },

    [theme.breakpoints.down(350)]: {
      height: 22,
    },
  },
  lotFeatureContainer: {
    display: "flex",
    flexDirection: "column",
  },
  lotFeatureText: {
    fontSize: "0.65rem",
    textAlign: "center",
    [theme.breakpoints.between(960, 1120)]: {
      fontSize: "0.47rem",
    },
    [theme.breakpoints.between(850, 960)]: {
      fontSize: "0.55rem",
    },
    [theme.breakpoints.between(760, 850)]: {
      fontSize: "0.47rem",
    },
    [theme.breakpoints.between(700, 760)]: {
      fontSize: "0.45rem",
    },
    [theme.breakpoints.between(590, 700)]: {
      fontSize: "0.55rem",
    },

    [theme.breakpoints.down(350)]: {
      fontSize: "0.5rem",
    },
  },
}));
