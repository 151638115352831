import * as React from "react";
import {
    Button,
    CircularProgress,
    Divider
} from "@mui/material";
import { FaTimes } from "react-icons/fa";
import { connect } from "react-redux";

interface FooterButtonsProps {
    loading?: boolean;
    onClose?: () => void;
    onBack?: () => void;
    onSubmit?: () => void;
    locales?: {
        cancel?: string;
        back?: string;
        next?: string;
    },
    settings?: any
}

export const FooterButtons: React.FC<FooterButtonsProps> = props => {
    const {
        onClose,
        onBack,
        onSubmit,
        locales,
        loading,
        settings
    } = props;

    return <>
        <Divider style={{ marginBottom: 10 }} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 10 }}>
            {onClose && <Button onClick={() => onClose()} variant="contained" className="btn-danger p-3 text-white text-capitalize ">
                <span className="btn-wrapper--icon">
                    <FaTimes />
                </span>
                <span className="btn-wrapper--label">{locales?.cancel ? locales.cancel : 'Cancel'}</span>
            </Button>}

            {onBack && <Button onClick={() => onBack()} variant="contained" className="btn-primary p-3 text-white text-capitalize" style={{ background: '#2b2b2b' }}>
                <span className="btn-wrapper--label">{locales?.back ? locales.back : 'Back'}</span>
            </Button>}

            {onSubmit && <Button onClick={() => onSubmit()} variant="contained" className="btn-primary p-3 text-white text-capitalize" disabled={loading}>
                <span className="btn-wrapper--label">{locales?.next ? locales.next : 'Next'}</span>
            </Button>}
        </div>
    </>
};

const mapStateToProps = (state: any) => ({
    settings: state.settings.settings,
})

export default connect(mapStateToProps, null)(React.memo(FooterButtons))